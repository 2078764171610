import React, { Component } from "react";
import { Box, Grid } from "@amzn/awsui-components-react-v3";
import styled from "styled-components";

const StyledFooter = styled("footer")`
    position: sticky;
    bottom: 0;
    z-index: 2000;
    color: #ffffff !important;
`;

const FooterText = styled.div`
    color: #ffffff;
`;

const StyleLink = styled.a`
    color: #ffffff !important;
`;

export interface FooterProps {
    footerText: string;
    teamName: string;
    teamHref: string;
    faqHref: string;
}

export default class Footer extends Component<FooterProps> {
    public render(): JSX.Element {
        return (
            <StyledFooter id="footer" className="footer">
                <div>
                    <Grid gridDefinition={[{ colspan: { default: 9 } }, { colspan: { default: 3 } }]} id={"footer-row"}>
                        <Box variant="p" id={"team-link"}>
                            <FooterText>{this.props.footerText}</FooterText>
                            <StyleLink href={this.props.teamHref} rel="noopener noreferrer" target="_blank">
                                {this.props.teamName}
                            </StyleLink>
                        </Box>
                        <Box variant="p" id={"sim-link"} textAlign={"right"}>
                            <FooterText>Need Help?</FooterText>
                            <StyleLink href={this.props.faqHref} rel="noopener noreferrer" target="_blank">
                                Kale FAQs
                            </StyleLink>
                        </Box>
                    </Grid>
                </div>
            </StyledFooter>
        );
    }
}
