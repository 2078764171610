import { Flashbar } from "@amzn/awsui-components-react-v3";
import { FlashbarProps } from "@amzn/awsui-components-react-v3/polaris/flashbar";
import React, { useState } from "react";
import KaleRoutes, { MessageType } from "src/components/survey/KaleRoutes";
import ScrollToTop from "src/components/survey/ScrollToTop";
import "./Survey.scss";
import styled from "styled-components";
import { WebSocketConnection } from "src/websocket/WebSocketConnection";
import { MessageBannerActionsProvider } from "src/components/MessageBannerActions";
import { applyTemplateToText } from "src/components/fields/textbox/TextBoxHelper";

export const TEST_IDS = {
    FLASH_BAR: "survey-flash-bar",
};

const StyledFlashBar = styled.div`
    width: 100%;
    z-index: 100000;
    position: fixed;
    top: 0px;
`;

const FlashBarMessage = styled.span`
    // Recognize and support newline characters in message text
    white-space: pre-wrap;
`;

interface Props {
    flashBarItems?: FlashbarProps.MessageDefinition[];
}

const Kale = (props: Props): JSX.Element => {
    const { flashBarItems } = props;
    const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>(flashBarItems ?? []);

    const displayMessage = (type: MessageType, message: React.ReactNode): void => {
        // Error messages (string) from backend can have newline or HTML tags which needs to be parsed before rendering
        if (typeof message === "string") {
            message = applyTemplateToText(message);
        }
        setItems([
            {
                type: type,
                content: <FlashBarMessage>{message}</FlashBarMessage>,
                dismissible: true,
                onDismiss: (): void => {
                    setItems([]);
                },
            },
        ]);
    };

    return (
        <div id={"legalSurvey"}>
            <StyledFlashBar>
                <Flashbar data-testid={TEST_IDS.FLASH_BAR} items={items} />
            </StyledFlashBar>
            <ScrollToTop />
            <WebSocketConnection>
                <MessageBannerActionsProvider value={{ displayMessage }}>
                    <KaleRoutes displayMessage={displayMessage} />
                </MessageBannerActionsProvider>
            </WebSocketConnection>
            <br />
        </div>
    );
};

export default Kale;
