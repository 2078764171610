import {
    AppLayout,
    BreadcrumbGroupProps,
    Container,
    FormField,
    Header,
    Input,
    Textarea,
} from "@amzn/awsui-components-react-v3/polaris";
import { InputProps } from "@amzn/awsui-components-react-v3/polaris/input/interfaces";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { TextareaProps } from "@amzn/awsui-components-react-v3/polaris/textarea/interfaces";
import React, { useCallback, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ImportForm, { ImporterRouteParams } from "src/components/schema_table/importers/ImportForm";
import { IntelligentImporter } from "src/components/schema_table/importers/schema_importer/IntelligentImporter";
import SchemaPreview from "src/components/schema_table/importers/SchemaPreview";
import { TableDefinition } from "src/components/schema_table/SchemaTable";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { KaleRoutesProps } from "src/components/survey/KaleRoutes";
import { makeDefaultDataStoreResponse } from "src/components/survey/LegalSurveyPage";
import { TableSources } from "src/services/KaleTablesService";
import { BreadcrumbItems } from "src/util/Breadcrumbs";
import KaleBreadcrumbGroup from "src/components/KaleBreadcrumbGroup";

const DEFAULT_LINES = 10;
const MAX_LINES = 50;

const SchemaImporter = (props: KaleRoutesProps): JSX.Element => {
    const { displayMessage } = props;
    const location = useLocation<DataStoreResponse>();
    const params = useParams<ImporterRouteParams>();
    const dataStore = location.state || makeDefaultDataStoreResponse();
    const [tableDefinitions, setTableDefinitions] = useState<TableDefinition[]>([]);
    const [schemaInput, setSchemaInput] = useState<string>("");
    const [importError, setImportError] = useState<string>("");
    const [numRows, setNumRows] = useState<number>(DEFAULT_LINES);

    const isSubmitEnabled = (tableDefinitions: TableDefinition[]): boolean => {
        return (
            tableDefinitions.length > 0 &&
            tableDefinitions.every((td): boolean => Boolean(td && td.name && td.name.length > 0))
        );
    };
    const onSchemaNameChange = useCallback(
        (event: NonCancelableCustomEvent<InputProps.ChangeDetail>): void => {
            const tableDefinitionsCopy = [...tableDefinitions];
            tableDefinitionsCopy[0].name = event.detail.value.trim();
            setTableDefinitions(tableDefinitionsCopy);
        },
        [tableDefinitions]
    );
    const onSchemaChange = useCallback((event: NonCancelableCustomEvent<TextareaProps.ChangeDetail>): void => {
        const importer = new IntelligentImporter();
        const rawSchema = event.detail.value.trim();
        setSchemaInput(rawSchema);
        const internalSchemas = importer.import(rawSchema);
        let lines = importer.getNumLines();
        if (lines < DEFAULT_LINES) {
            lines = DEFAULT_LINES;
        }
        setTableDefinitions(
            internalSchemas.map((td): TableDefinition => {
                return {
                    name: td.name,
                    source: td.source || "UNKNOWN",
                    rawSchema: rawSchema,
                    schema: td.schema,
                };
            })
        );
        setImportError(!Boolean(internalSchemas.length) ? "Input cannot be parsed by any built-in importer" : "");
        setNumRows(lines > MAX_LINES ? MAX_LINES : lines);
    }, []);
    const renderBreadcrumbs = (): JSX.Element => {
        const dsName =
            Boolean(dataStore?.name) && Boolean(dataStore?.technology)
                ? `${dataStore.name} (${dataStore.technology})`
                : "";
        const items: BreadcrumbGroupProps.Item[] = [
            BreadcrumbItems.KALE_LANDING_PAGE,
            BreadcrumbItems.editKaleRecord(params.applicationName, params.reviewId),
            BreadcrumbItems.kaleDataStore(params.applicationName, params.reviewId, dsName),
            BreadcrumbItems.kaleSchemasImporter(params.applicationName, params.reviewId, Number(params.dataStoreId)),
        ];
        return <KaleBreadcrumbGroup items={items} />;
    };

    const renderContent = (): JSX.Element => {
        return (
            <ImportForm
                isDisabled={!isSubmitEnabled(tableDefinitions)}
                tableDefinitions={tableDefinitions}
                displayMessage={displayMessage}
                dataStore={dataStore}
                source={TableSources.SchemaImport}
                isMultiTable={true}
            >
                <Container
                    header={
                        <Header
                            variant={"h2"}
                            description={
                                <React.Fragment>
                                    If you need help with the schema format, please see:&nbsp;
                                    <a target="_blank" rel={"noreferrer"} href={`/help/schema-importer-help`}>
                                        Schema import format (opens in new tab)
                                    </a>
                                </React.Fragment>
                            }
                        >
                            Manual Schema Import
                        </Header>
                    }
                >
                    <FormField
                        label="Import Instructions"
                        description="Copy and paste your schema from a supported database below:"
                        stretch={true}
                        errorText={importError}
                    >
                        <Textarea
                            value={schemaInput}
                            placeholder="Raw Schema Text"
                            rows={numRows}
                            onChange={onSchemaChange}
                        />
                    </FormField>
                    {Boolean(tableDefinitions.length) && (
                        <FormField
                            label="Schema Name"
                            errorText={tableDefinitions[0].name ? "" : "Schema name is required to proceed"}
                        >
                            <Input name="schemaName" value={tableDefinitions[0].name} onChange={onSchemaNameChange} />
                        </FormField>
                    )}
                </Container>
                <SchemaPreview tableDefinitions={tableDefinitions} />
            </ImportForm>
        );
    };

    return (
        <React.Fragment>
            <AppLayout
                breadcrumbs={renderBreadcrumbs()}
                navigationHide={true}
                toolsHide={true}
                content={renderContent()}
            />
        </React.Fragment>
    );
};

export default SchemaImporter;
