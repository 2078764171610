import { AppInfoResponse, HasPersonalDataResponse } from "src/components/survey/SurveyFormModel";
import { Alert, Container, Header, SelectProps, SpaceBetween } from "@amzn/awsui-components-react-v3";
import React, { useEffect, useRef, useState } from "react";
import { OnChangeCb } from "src/components/survey/ApplicationInfo/ApplicationInfo";
import usePrevious from "@rooks/use-previous";
import {
    useLineageStep,
    PDWStepToIndexMap,
    usePersonalDataClassifierStep,
    usePersonalDataExplanationStep,
    usePersonalDataQuestionStep,
    useSummaryStep,
} from "src/components/survey/PersonalDataWizard/steps";
import { uniqBy } from "lodash";
import InlineWizard from "src/components/InlineWizard";
import { TEST_IDS } from "shared/survey/personalDataWizard";

interface PersonalDataWizardProps {
    response: AppInfoResponse;
    controlBindle: SelectProps.Option | null;
    relatedBindles: SelectProps.Option[];
    /**
     * Determines whether to show "Confirm and Continue" button or not
     */
    pdwSubmitted: boolean;
    setPdwSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    onClearDataStore: () => void;
    onChangeCallback: OnChangeCb;
    skipValidation: boolean;
    /**
     * This flag is set to true when user has clicked "Confirm and Continue" button.
     * After this action wizard will always show summary step during page load
     */
    pdwSkipToReview: boolean;
    bindlesError?: React.ReactNode;
}

export const PersonalDataWizard = ({
    skipValidation,
    onClearDataStore,
    onChangeCallback,
    response,
    relatedBindles,
    controlBindle,
    pdwSubmitted,
    setPdwSubmitted,
    pdwSkipToReview,
    bindlesError,
}: PersonalDataWizardProps): JSX.Element => {
    const [requestBindles, setRequestBindles] = useState<SelectProps.Option[]>([]);
    const [pdwActiveStepIndex, setPdwActiveStepIndex] = useState<number>(0);
    const prevHasPersonalData = usePrevious(response.review.hasPersonalData);

    const { step: explanationStep } = usePersonalDataExplanationStep();
    const {
        step: lineageStep,
        lineageFindings,
        error: lineageError,
    } = useLineageStep({
        requestBindles,
    });
    const {
        step: pdcStep,
        pdcFindings,
        error: pdcError,
    } = usePersonalDataClassifierStep({
        requestBindles,
    });
    const { step: summaryStep } = useSummaryStep({
        response,
        pdwSubmitted,
        lineageFindings,
        pdcFindings,
        setPdwActiveStepIndex,
    });
    const { step: questionStep } = usePersonalDataQuestionStep({
        response,
        hasPersonalData: response.review.hasPersonalData,
        skipValidation,
        onClearDataStore,
        onChangeCallback,
    });

    const deps = { setPdwSubmitted };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    useEffect((): void => {
        if (bindlesError) {
            const { setPdwSubmitted } = dependenciesRef.current;
            setPdwSubmitted(false);
        }
    }, [bindlesError]);

    useEffect((): void => {
        // Ensure that the control bindle exists because, depending on user access, some applications
        // may not have carried it across.
        const uniqBindles = uniqBy(relatedBindles.concat(controlBindle ? [controlBindle] : []), "value");
        setRequestBindles(uniqBindles);
    }, [relatedBindles, controlBindle, response]);

    useEffect((): void => {
        const { setPdwSubmitted } = dependenciesRef.current;
        if (requestBindles.length > 0 && pdwSkipToReview) {
            setPdwSubmitted(true);
            setPdwActiveStepIndex(PDWStepToIndexMap.summary);
        }
    }, [pdwSkipToReview, requestBindles]);

    useEffect((): void => {
        if (prevHasPersonalData !== response.review.hasPersonalData) {
            dependenciesRef.current.setPdwSubmitted(false);
        }
    }, [response.review.hasPersonalData, prevHasPersonalData]);

    const steps = [explanationStep, lineageStep, pdcStep, questionStep, summaryStep];

    // We're using this number to block users onNavigate to the step after questionStep
    // ...if they haven't answered the required questionStep
    const stepIndexAfterQuestionStep = steps.indexOf(questionStep) + 1;

    return (
        <SpaceBetween size={"xxl"}>
            {Boolean(bindlesError) && <Alert type={"error"}>{bindlesError}</Alert>}
            {lineageError !== "" && <Alert type={"error"}>{lineageError}</Alert>}
            {pdcError !== "" && <Alert type={"error"}>{pdcError}</Alert>}
            {requestBindles.length > 0 && (
                <Container header={<Header variant={"h3"}>Personal Data</Header>}>
                    <InlineWizard
                        data-testid={TEST_IDS.WIZARD.ROOT}
                        i18nStrings={{
                            stepNumberLabel: (stepNumber): string => `Step ${stepNumber}`,
                            previousButton: "Previous",
                            nextButton: "Next",
                            submitButton: "Confirm and continue",
                            optional: "optional",
                        }}
                        hideSubmitButton={pdwSubmitted}
                        onSubmit={(): void => {
                            if (response.review.hasPersonalData !== HasPersonalDataResponse.Empty) {
                                setPdwSubmitted(true);
                            }
                        }}
                        onNavigate={(event): void => {
                            if (
                                event.detail.requestedStepIndex === stepIndexAfterQuestionStep &&
                                response.review.hasPersonalData === HasPersonalDataResponse.Empty
                            ) {
                                return;
                            }
                            setPdwActiveStepIndex(event.detail.requestedStepIndex);
                        }}
                        activeStepIndex={pdwActiveStepIndex}
                        steps={steps}
                    />
                </Container>
            )}
        </SpaceBetween>
    );
};
