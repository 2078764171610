const KALE_BASE_URL = "/";
const CREATE_KALE_RECORD_URL = `${KALE_BASE_URL}new`;
const editKaleRecordUrl = (kaleRecordName: string, reviewId: string): string =>
    `${KALE_BASE_URL}${kaleRecordName}/reviews/${reviewId}/edit`;
const editKaleTafRecordUrl = (kaleRecordName: string, reviewId: string): string =>
    `${KALE_BASE_URL}${kaleRecordName}/reviews/${reviewId}/edit/TAF`;
const editKaleCampaignRecordUrl = (kaleRecordName: string, reviewId: string, campaignType: string): string =>
    `${KALE_BASE_URL}${kaleRecordName}/reviews/${reviewId}/edit/campaign/${campaignType}`;
const editKaleTableUrl = (kaleRecordName: string, reviewId: string, dataStoreID: number, tableID: string): string =>
    `${KALE_BASE_URL}${kaleRecordName}/reviews/${reviewId}/edit/datastore/${dataStoreID}/table/${tableID}`;
const editSchemasImporterUrl = (kaleRecordName: string, reviewId: string, dataStoreID: number): string =>
    `\`${KALE_BASE_URL}${kaleRecordName}/reviews/${reviewId}/edit/datastore/${dataStoreID}/import/schemas`;
const editAndesImporterUrl = (kaleRecordName: string, reviewId: string, dataStoreID: number): string =>
    `\`${KALE_BASE_URL}${kaleRecordName}/reviews/${reviewId}/edit/datastore/${dataStoreID}/import/andes`;
const editBumblebeeImporterUrl = (kaleRecordName: string, reviewId: string, dataStoreID: number): string =>
    `\`${KALE_BASE_URL}${kaleRecordName}/reviews/${reviewId}/edit/datastore/${dataStoreID}/import/bumblebee`;
const editImportBySourceURL = (kaleRecordName: string, reviewId: string, dataStoreID: number, source: string): string =>
    `${KALE_BASE_URL}${kaleRecordName}/reviews/${reviewId}/edit/datastore/${dataStoreID}/import/${source}`;
const csvJobsStatusUrl = (kaleRecordName: string, reviewId: string): string =>
    `${KALE_BASE_URL}${kaleRecordName}/reviews/${reviewId}/edit/csv/jobs_activity`;
const auditHistoryUrl = (kaleRecordName: string, reviewId: string): string =>
    `${KALE_BASE_URL}${kaleRecordName}/reviews/${reviewId}/edit/audit-history`;
const deprecatedEditUrl = (kaleRecordName: string): string => `${KALE_BASE_URL}edit/${kaleRecordName}`;

export const kaleUrls = {
    KALE_BASE_URL,
    CREATE_KALE_RECORD_URL,
    editKaleRecordUrl,
    editKaleTafRecordUrl,
    editKaleCampaignRecordUrl,
    editKaleTableUrl,
    editSchemasImporterUrl,
    editAndesImporterUrl,
    editBumblebeeImporterUrl,
    editImportBySourceURL,
    importExportStatusUrl: csvJobsStatusUrl,
    csvJobsStatusUrl,
    auditHistoryUrl,
    deprecatedEditUrl,
};

// TODO: Introduce survey types Legal and TAF to handlers
export const hasTAFUrl = (): boolean => {
    const [, , , , , tafPath = ""] = location.pathname.split("/") ?? [];
    const hasTAF = tafPath.toLocaleUpperCase() === "TAF";
    return Boolean(hasTAF);
};
