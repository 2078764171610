import { TEST_IDS } from "./test-ids";
import { attrSelector, compoundSelector, tidSelector } from "../../util/selectors";

const { WIZARD } = TEST_IDS;
const { PDC_STEP } = WIZARD;
const { PDC_TABLE } = PDC_STEP;

export const SELECTORS = {
    WIZARD: {
        ROOT: tidSelector(WIZARD.ROOT),
        NEXT_BUTTON: tidSelector(WIZARD.NEXT_BUTTON),
        CONFIRM_AND_SUBMIT_BUTTON: tidSelector(WIZARD.CONFIRM_AND_SUBMIT_BUTTON),
        LINEAGE_STEP: {
            LINEAGE_TABLE: tidSelector(WIZARD.LINEAGE_STEP.LINEAGE_TABLE),
        },
        PDC_STEP: {
            PDC_TABLE: {
                ROOT: tidSelector(PDC_TABLE.ROOT),
                ENTITY_TYPE: (function IIFE() {
                    const ROOT = tidSelector(PDC_TABLE.ENTITY_TYPE.ROOT);
                    const entityTypeByDERName = (derName: string) =>
                        compoundSelector(ROOT, attrSelector("data-entity-dername", derName));
                    return {
                        ROOT,
                        LABEL: tidSelector(PDC_TABLE.ENTITY_TYPE.LABEL),
                        entityTypeByDERName,
                    };
                })(),
            },
        },
        PERSONAL_DATA_STEP: {
            CONTENT_ROOT: tidSelector(WIZARD.PERSONAL_DATA_STEP.CONTENT_ROOT),
            STATEMENT_FORM_FIELD: tidSelector(WIZARD.PERSONAL_DATA_STEP.STATEMENT_FORM_FIELD),
        },
        SUMMARY_STEP: {
            RESULT: tidSelector(WIZARD.SUMMARY_STEP.RESULT),
            PERSONAL_DATA_QUESTION_EDIT_BUTTON: tidSelector(WIZARD.SUMMARY_STEP.PERSONAL_DATA_QUESTION_EDIT_BUTTON),
        },
    },
};
