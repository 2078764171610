import { BreadcrumbGroupProps } from "@amzn/awsui-components-react-v3/polaris";

import { kaleUrls } from "src/util/Urls";

const {
    KALE_BASE_URL,
    CREATE_KALE_RECORD_URL,
    editKaleRecordUrl,
    editKaleTafRecordUrl,
    editKaleCampaignRecordUrl,
    editKaleTableUrl,
    editSchemasImporterUrl,
    editAndesImporterUrl,
    editBumblebeeImporterUrl,
    csvJobsStatusUrl,
} = kaleUrls;

const KaleBreadcrumbItems = {
    KALE_LANDING_PAGE: { text: "Kale", href: KALE_BASE_URL },
    CREATE_KALE_RECORD: { text: "Create attestation", href: CREATE_KALE_RECORD_URL },
    editKaleRecord: (kaleRecordName: string, reviewId: string): BreadcrumbGroupProps.Item => ({
        text: kaleRecordName,
        href: editKaleRecordUrl(kaleRecordName, reviewId),
    }),
    editKaleTafRecord: (kaleRecordName: string, reviewId: string): BreadcrumbGroupProps.Item => ({
        text: "Tax, Accounting, & Fraud",
        href: editKaleTafRecordUrl(kaleRecordName, reviewId),
    }),
    editKaleCampaignRecord: (appName: string, reviewId: string, campaignType: string): BreadcrumbGroupProps.Item => ({
        text: campaignType,
        href: editKaleCampaignRecordUrl(appName, reviewId, campaignType),
    }),
    kaleDataStore: (
        kaleRecordName: string,
        reviewId: string,
        dataStoreNameWithTech: string
    ): BreadcrumbGroupProps.Item => ({
        text: dataStoreNameWithTech,
        href: editKaleRecordUrl(kaleRecordName, reviewId),
    }),
    kaleTable: (
        kaleRecordName: string,
        reviewId: string,
        dataStoreID: number,
        tableID: string,
        tableName: string
    ): BreadcrumbGroupProps.Item => ({
        text: tableName,
        href: editKaleTableUrl(kaleRecordName, reviewId, dataStoreID, tableID),
    }),
    kaleSchemasImporter: (
        kaleRecordName: string,
        reviewId: string,
        dataStoreID: number
    ): BreadcrumbGroupProps.Item => ({
        text: "Schemas Importer",
        href: editSchemasImporterUrl(kaleRecordName, reviewId, dataStoreID),
    }),
    kaleAndesImporter: (kaleRecordName: string, reviewId: string, dataStoreID: number): BreadcrumbGroupProps.Item => ({
        text: "Andes Importer",
        href: editAndesImporterUrl(kaleRecordName, reviewId, dataStoreID),
    }),
    kaleBumblebeeImporter: (
        kaleRecordName: string,
        reviewId: string,
        dataStoreID: number
    ): BreadcrumbGroupProps.Item => ({
        text: "Bumblebee Importer",
        href: editBumblebeeImporterUrl(kaleRecordName, reviewId, dataStoreID),
    }),
    csvJobStatus: (kaleRecordName: string, reviewId: string): BreadcrumbGroupProps.Item => ({
        text: "CSV Job Statuses",
        href: csvJobsStatusUrl(kaleRecordName, reviewId),
    }),
};

export const BreadcrumbItems = KaleBreadcrumbItems;
