import { SurveyResponse } from "src/components/survey/SurveyFormModel";
import React, { useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { useHistory, useLocation } from "react-router-dom";
import { AnvilProps } from "src/components/survey/LegalSurveyPage";
import { ResetAnswersActionCallback } from "src/answers_legacy";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { useAppParamsFromRoute } from "src/components/survey/hooks/useAppParamsFromRoute";
import ReactDOM from "react-dom";
import UnRetrievableErrorMessage from "src/components/survey/UnRetrievableErrorMessage";

const injectDefaultUserIdIntoResponse = (response: SurveyResponse, defaultUserId: string): SurveyResponse => {
    const defaultContactList = [defaultUserId];
    const contactList = response.appInfo.review.contactList;
    return {
        ...response,
        appInfo: {
            ...response.appInfo,
            applicationName:
                // Prevent update error in new app flow if response's App Name is different from Anvil's App Name
                response.appInfo.applicationName,
            // Prevent update error in new app flow if response's Control Bindle is different from Anvil's Bindle
            controlBindle: response.appInfo.controlBindle,
            review: {
                ...response.appInfo.review,
                respondentAlias: response.appInfo.review.respondentAlias || defaultUserId,
                createdBy: response.appInfo.review.createdBy || defaultUserId,
                contactList: (contactList?.length && contactList) || defaultContactList,
            },
        },
    };
};

export type ApplicationFromServer = SurveyResponse | null;
export interface FetchApplicationResultState {
    application: ApplicationFromServer;
    applicationError: React.ReactNode;
    isLoadingApplication: boolean;
    originalDataStores: DataStoreResponse[];
    setApplication: (application: ApplicationFromServer) => void;
}

/**
 * Fetches the application data when a record of the application exists, else returns a new default application object.
 */
export const useFetchApplicationOnMount = (): FetchApplicationResultState => {
    const { fetchAppByReviewId } = useContext(KaleContext).service.kaleAppService;
    const { userId } = useContext(KaleContext).user;

    const { pathname } = useLocation<AnvilProps>();
    const { applicationName: appNameFromURL, reviewId } = useAppParamsFromRoute();
    const history = useHistory();

    const [application, setApplication] = useState<ApplicationFromServer>(null);
    const [isLoadingApplication, setIsLoadingApplication] = useState<boolean>(false);
    const [originalDataStores, setOriginalDataStores] = useState<DataStoreResponse[]>([]);
    const [applicationError, setApplicationError] = useState<React.ReactNode>(null);

    const deps = {
        appNameFromURL,
        reviewId,
        pathname,
        history,
        setApplication,
        setApplicationError,
        userId,
        fetchAppByReviewId,
    };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    useEffect((): ResetAnswersActionCallback | void => {
        const {
            appNameFromURL,
            reviewId,
            pathname,
            history,
            setApplication,
            setApplicationError,
            userId,
            fetchAppByReviewId,
        } = dependenciesRef.current;

        // Fetch the Application data
        setIsLoadingApplication(true);
        fetchAppByReviewId(appNameFromURL, reviewId)
            .then((response): void => {
                response = injectDefaultUserIdIntoResponse(response, userId);
                ReactDOM.unstable_batchedUpdates((): void => {
                    setApplication(response);
                    setIsLoadingApplication(false);
                    setOriginalDataStores(response.appInfo.review.dataStores);
                });
            })
            .catch((err: Error): void => {
                if (err.name === "UnauthorizedError") {
                    // An "UnauthorizedError" error message from the server can actually can mean many different
                    // things today and not all of them are related to Authorization
                    setApplicationError(<UnRetrievableErrorMessage pathname={pathname} />);
                    history.push("/");
                } else {
                    setApplicationError(err.message);
                }
                setIsLoadingApplication(false);
            });
    }, []);
    return {
        application,
        applicationError,
        isLoadingApplication,
        originalDataStores,
        setApplication,
    };
};
