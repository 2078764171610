export interface FieldChoice {
    isExclusive: boolean;
    id: string;
    label?: string;
    description?: string;
}

export interface DisplayOptions {
    choices: FieldChoice[];
}

export interface Field {
    key: string;
    displayType: DisplayType | string;
    label?: string;
    description?: string;
    displayOptions: DisplayOptions;
}

// Deserialized ValueDictionary types
export type ValueKey = string;
type Value = SingleInputValue | MultiInputValue;
type SingleInputValue = string;
type MultiInputValue = string[];
export type ValueDictionary = Record<ValueKey, Value>;

// Serialized ValueDictionary types
type SerializedValueKey = ValueKey;
type SerializedValue = SerializedSingleInputValue | SerializedMultiInputValue | SerializedEmptyValue;
type SerializedSingleInputValue = SingleInputValue;
/* Non-empty MultiInputValues are serialized into stringified arrays */
type SerializedMultiInputValue = string;
/* Empty SingleInput ("") and MultiInput ([]) values are both serialized into null */
type SerializedEmptyValue = null;
// SerializedValueDictionary is the ValueDictionary where the values are all transformed into strings
export type SerializedValueDictionary = Record<SerializedValueKey, SerializedValue>;

export interface IncrementalSaveResponse {
    valueDictionary: ValueDictionary;
    fields: Field[];
}

export interface IncrementalSaveInput {
    appName?: string;
    reviewID?: string;
    valueDictionary: ValueDictionary;
    abortController?: AbortController;
}

export interface SerializedIncrementalSaveRequest {
    appName: string;
    reviewID: string;
    answers: SerializedAnswerViewModel[];
}

export interface SerializedAnswerViewModel {
    key: string;
    entityId: string;
    value: string | null;
}

export interface SerializedIncrementalSaveField {
    key: string;
    displayType: DisplayType | string;
    label?: string;
    description?: string;
    displayOptions: string;
}

export interface SerializedIncrementalSaveResponse {
    /* object name "valueDictionary" is misnamed due to needing to match the json naming convention from the backend
    / despite it being a SerializedValueDictionary type
     */
    valueDictionary: SerializedValueDictionary;
    fields: SerializedIncrementalSaveField[];
}

export enum DisplayType {
    singleSelect = "singleSelect",
    multiselect = "multiSelect",
    radiogroup = "radio",
    text = "text",
    textTags = "textTags",
    textArea = "textArea",
    checkbox = "checkbox",
    checkboxGroup = "checkboxGroup",
    date = "date",
    autoSuggest = "autoSuggest",
    retention = "retention",
}
