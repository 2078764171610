import { int } from "aws-sdk/clients/datapipeline";
import { ApplicationStatus } from "src/components/survey/SurveyFormModel";
import { FeatureToggleConfig, KaleConfig } from "src/Config";
import { TokenGetter } from "src/services/CognitoService";
import AbstractKaleService from "src/services/AbstractKaleService";
import { AndesMetadata } from "src/components/schema_table/importers/andes_importer/AndesImporter";
import { BumblebeeMetadata } from "src/components/schema_table/importers/bumblebee_importer/BumblebeeImporter";
import { appendRequestID } from "src/services/AppendRequestID";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import {
    AnswerContentKey,
    getFieldQuestionSetType,
    getTableQuestionSetType,
    QuestionTag,
    QuestionBase,
} from "src/services/dynamic-questions";

export {
    QuestionTag,
    QuestionType,
    AnswerContentKey,
    mapQuestionTypeToAnswerContentKey,
} from "src/services/dynamic-questions";
export type { QuestionChoice, QuestionColorOverrides } from "src/services/dynamic-questions";

interface AnswerBase {
    questionId: number;
    questionShortId: string;
    [AnswerContentKey.textContent]?: string;
    [AnswerContentKey.arrayContent]?: string[];
    [AnswerContentKey.dateContent]?: string;
    complianceType: string;
}

export interface TableAnswer extends AnswerBase {
    tableAnswerId?: number;
    tableId: number;
}

export interface FieldAnswer extends AnswerBase {
    fieldAnswerId?: number;
    fieldId: number;
}

export interface BulkEditAnswer extends AnswerBase {
    tableId?: number;
    fieldId?: number;
    groupType: string;
}

export interface FieldBase {
    derUuid?: string;
    name: string;
    description: string;
    type: string;
    sampleValuesContentType?: string;
    sampleValues?: string;
    createdAt: string;
    updatedAt: string;
}

export interface FieldRecord extends FieldBase {
    id: number;
    tableId: number;
    answers: FieldAnswer[];
}

export interface NewField extends FieldBase {
    tableId: number;
}

export enum TableStatus {
    IdentifyDataset = "Identify Dataset",
    DocumentAndAnalyze = "Document and Analyze",
    ReadyForPreReview = "Ready for Pre-Review",
    PreReviewStarted = "Pre-Review Started",
    PreReviewComplete = "Pre-Review Complete",
    ApprovalStarted = "Approval Started",
    ApprovalCompleteWithActions = "Pending Actions",
    ApprovalComplete = "Approval Complete",
    PostApprovalChanges = "Post Approval Changes",
}

export enum TableSources {
    Andes = "Andes",
    BumbleBee = "BumbleBee",
    Manual = "Manual", // create table button
    SchemaImport = "Schema Import", // schema import
    KaleImport = "Kale Import", // Kale import of ActionPlans
    KaleDataElementImport = "Kale Data Element import",
}

type SourceMetadata = BumblebeeMetadata | AndesMetadata;

interface TableBase {
    dataStoreId: number;
    dataStoreName: string;
    dataStoreTech: string;
    name: string;
    fieldCount: number;
    status: TableStatus | "";
    source: TableSources;
    sourceMetadata?: SourceMetadata;
    description: string;
    hasPersonalData: string;
}

// BackendTableRecord is for network calls at service layer. Use TableRecord while using tables in non-service code
export interface BackendTableRecord extends TableBase {
    id: number;
    createdAt: string;
    updatedAt: string;
    answers: TableAnswer[];
}

// Read comment for BackendUpdateTableRequestBody to understand why there's two types for TableRecord
export interface TableRecord extends BackendTableRecord {
    metadataAnswers: MetadataIdToFieldIdMap;
}

export interface CreateTableRequest {
    table: TableBase;
    fields: FieldBase[];
}

export interface CreateTableResponseBody {
    table: TableRecord;
    fields: FieldRecord[];
}

export interface UpdateTableRequestBody {
    table: TableRecord;
    fields: (FieldRecord | NewField)[];
}

// We have a BackendUpdateTableRequestBody because the metadata answers logically belong to the Table. But due to the
// way they are stored in the backend, they are being asked for in a separate field. So, we are taking an approach where
// we work with that API contract at the service level. But, the non-service-level frontend code, will see the metadata
// answers as part of the TableRecord itself, so that we have it as a logical place and don't have to pass it standalone
export interface BackendUpdateTableRequestBody {
    table: BackendTableRecord;
    fields: (FieldRecord | NewField)[];
    tableMetadata: MetadataIdToFieldIdMap;
}

export interface UpdateTableResponseBody extends CreateTableResponseBody {
    tableMetadataElements: TableMetadata;
}

export interface TransferTableRequest {
    tableIds: int[];
    applicationName: string;
    dataStoreId: int;
}

interface ExportTablesRequest {
    tableIDs: int[];
    exportName: string;
    dataStoreUUID: string;
    dataStoreName: string;
}

export interface TableQuestion extends QuestionBase {
    complianceTypeId: number;
    complianceType: string;
    groupType?: string;
}
export type FieldQuestion = TableQuestion;
type GenericQuestion = TableQuestion;

export interface TableIdentifiers {
    applicationName: string;
    reviewId: string;
    dataStoreId: string;
    tableId: string;
}

export interface TableLink {
    id: number;
    name: string;
    source: string;
    sourceMetadata?: SourceMetadata;
}

export type MetadataIdToFieldIdMap = Record<string, number>;
export type MetadataIdToBusUseCasesMap = Record<string, string[]>;

export interface TableMetadata {
    requirements: MetadataIdToBusUseCasesMap;
    fieldId: MetadataIdToFieldIdMap;
}

interface TableDetailsBase {
    fields: FieldRecord[];
    tableQuestions: TableQuestion[];
    fieldQuestions: FieldQuestion[];
    linkedTables: TableLink[];
    applicationStatus: ApplicationStatus;
}

// BackendTableDetails is for network calls at service layer. Use TableDetails while in non-service code
export interface BackendTableDetails extends TableDetailsBase {
    tableMetadataElements: TableMetadata;
    table: BackendTableRecord;
}

export interface TableDetails extends TableDetailsBase {
    metadataRequirements: MetadataIdToBusUseCasesMap;
    table: TableRecord;
}

export interface BulkEditQuestions {
    table: TableQuestion[];
    field: FieldQuestion[];
}

export interface BulkEditResponse {
    tableIds: number[];
    answers: BulkEditAnswer[];
    tableStatus: string;
}

export interface SignedS3URLResponse {
    s3URL: string;
}

export const DEFAULT_TABLE_FIELD_QUESTION: BulkEditQuestions = { table: [], field: [] };

export const getTableUrl = ({ applicationName, dataStoreId, tableId }: TableIdentifiers): string =>
    `/applications/${applicationName}/datastore/${dataStoreId}/table/${tableId}`;

export class KaleTablesService extends AbstractKaleService {
    public constructor(kaleConfig: KaleConfig & FeatureToggleConfig, accessTokenGetter: TokenGetter) {
        super(kaleConfig, accessTokenGetter);

        this.fetchTable = this.fetchTable.bind(this);
        this.createTable = this.createTable.bind(this);
        this.createTables = this.createTables.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.deleteTable = this.deleteTable.bind(this);
        this.transferTables = this.transferTables.bind(this);
        this.exportTablesToCSV = this.exportTablesToCSV.bind(this);
        this.generateTablesCSVTemplate = this.generateTablesCSVTemplate.bind(this);
        this.uploadTablesCSV = this.uploadTablesCSV.bind(this);
        this.fetchBulkEditQuestions = this.fetchBulkEditQuestions.bind(this);
        this.saveBulkEditAnswers = this.saveBulkEditAnswers.bind(this);
        this.fetchSignedS3URLTableCSVExport = this.fetchSignedS3URLTableCSVExport.bind(this);
    }

    public async fetchTable(tableIdentifiers: TableIdentifiers): Promise<TableDetails> {
        const tableUrl = getTableUrl(tableIdentifiers);
        const apiEndpoint = `${this.baseApiEndpoint}${tableUrl}`;
        const fetchMessagePrefix = `Fetching Table ${tableUrl}`;

        try {
            console.info(fetchMessagePrefix);
            const resp = await this.signedFetch("GET", apiEndpoint);
            if (resp.ok) {
                console.info(`${fetchMessagePrefix}: Success`);
                const rawTableDetails = (await resp.json()) as unknown;
                const backendTableDetails = rawTableDetails as BackendTableDetails;
                const frontendTableDetails = rawTableDetails as TableDetails;
                frontendTableDetails.table.metadataAnswers = backendTableDetails.tableMetadataElements.fieldId ?? {};
                frontendTableDetails.metadataRequirements =
                    backendTableDetails.tableMetadataElements.requirements ?? {};
                return frontendTableDetails;
            } else {
                // Throw error with requestId error message if we got a bad response.
                const errorMessage = appendRequestID(resp.statusText, resp);
                throw Error(errorMessage);
            }
        } catch (err) {
            // log native and custom errors alike, then forward on to the caller.
            console.error(`${fetchMessagePrefix}: Failed. `, err);
            throw err;
        }
    }

    public async fetchBulkEditQuestions(isAndes: boolean): Promise<BulkEditQuestions> {
        const apiEndpoint = `${this.baseApiEndpoint}/table-and-field-questions?isAndes=${isAndes ? "true" : "false"}`;
        try {
            const resp = await this.signedFetch("GET", apiEndpoint);
            if (resp.ok) {
                const commonQuestions = (await resp.json()) as GenericQuestion[];
                return {
                    table: commonQuestions
                        .filter((question): boolean => question?.groupType === getTableQuestionSetType(isAndes))
                        .filter((question): boolean => question.tags.includes(QuestionTag.bulkEditable)),
                    field: commonQuestions
                        .filter((question): boolean => question?.groupType === getFieldQuestionSetType(isAndes))
                        .filter((question): boolean => question.tags.includes(QuestionTag.bulkEditable)),
                };
            } else {
                const errorMessage = appendRequestID(resp.statusText, resp);
                throw Error(errorMessage);
            }
        } catch (err) {
            console.error(`Failed. `, err);
            throw err;
        }
    }

    public async saveBulkEditAnswers(appName: string, response: BulkEditResponse): Promise<string> {
        const apiEndpoint = `${this.baseApiEndpoint}/applications/${appName}/bulkEdit`;

        try {
            const resp = await this.signedFetch("POST", apiEndpoint, JSON.stringify(response));
            // We expect the server should only ever send a 202 status code for success because it is a background job
            if (resp.ok && resp.status === 202) {
                return Promise.resolve(resp.headers.get("x-amzn-requestid") ?? "");
            } else {
                const errorMessage = appendRequestID(resp.statusText, resp);
                throw Error(errorMessage);
            }
        } catch (err) {
            console.error(`Failed. `, err);
            throw err;
        }
    }

    public requestBodyFieldCountReducer(requestBody: BackendUpdateTableRequestBody): BackendUpdateTableRequestBody {
        const fieldCount = requestBody.fields.length;
        return {
            ...requestBody,
            table: {
                ...requestBody.table,
                fieldCount,
            },
        };
    }

    public async updateTable(
        tableIdentifiers: TableIdentifiers,
        requestBody: UpdateTableRequestBody
    ): Promise<UpdateTableResponseBody> {
        const tableUrl = getTableUrl(tableIdentifiers);
        const apiEndpoint = `${this.baseApiEndpoint}${tableUrl}`;

        const updateMessagePrefix = `Updating Table ${tableUrl}`;
        try {
            console.info(updateMessagePrefix);
            const backendBody: BackendUpdateTableRequestBody = {
                fields: requestBody.fields,
                // Backend wants the metadata questions as a separate field, so we place the metadata answers there.
                // We also have to do the "reverse" of this process, on the response object (seen later in this method)
                tableMetadata: requestBody.table.metadataAnswers ?? {},
                table: requestBody.table as BackendTableRecord,
            };
            const body = this.requestBodyFieldCountReducer(backendBody);
            const resp = await this.signedFetch("PUT", apiEndpoint, JSON.stringify(body));
            if (resp.ok) {
                const jsonResp = (await resp.json()) as unknown as UpdateTableResponseBody;
                // Place the metadata back to where the lower level frontend code can access it
                jsonResp.table.metadataAnswers = jsonResp.tableMetadataElements.fieldId;
                return jsonResp;
            } else {
                const errorMessage = appendRequestID(resp.statusText, resp);
                throw Error(errorMessage);
            }
        } catch (err) {
            // log native and custom errors alike, then forward on to the caller.
            console.error(`${updateMessagePrefix}: Failed. `, err);
            throw err;
        }
    }

    public createTable(
        appName: string,
        dataStoreId: number,
        request: CreateTableRequest
    ): Promise<CreateTableResponseBody> {
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/applications/${encodeURIComponent(appName)}/datastore/${dataStoreId}/table`,
            JSON.stringify(request)
        ).then((response: Response): Promise<CreateTableResponseBody> => {
            const msgPrefix = `Create schema table: appName: ` + `${appName}: DataStore ID: ${dataStoreId}`;
            return this.handleKaleResponse<CreateTableResponseBody>(response, 201, msgPrefix);
        }, this.buildDefaultRejection());
    }

    public createTables(
        appName: string,
        dataStoreId: number,
        request: CreateTableRequest[]
    ): Promise<CreateTableResponseBody> {
        const createTablesRequest = { tables: request };
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/applications/${encodeURIComponent(appName)}/datastore/${dataStoreId}/tables`,
            JSON.stringify(createTablesRequest)
        ).then((response: Response): Promise<CreateTableResponseBody> => {
            const msgPrefix = `Create schema tables: appName: ` + `${appName}: DataStore ID: ${dataStoreId}`;
            return this.handleKaleResponse<CreateTableResponseBody>(response, 201, msgPrefix);
        }, this.buildDefaultRejection());
    }

    public async deleteTable(appName: string, dataStoreId: number, tableId: number): Promise<void> {
        return this.signedFetch(
            "DELETE",
            `${this.baseApiEndpoint}/applications/${encodeURIComponent(
                appName
            )}/datastore/${dataStoreId}/table/${tableId}`
        ).then((response: Response): Promise<void> => {
            const msgPrefix =
                `Delete schema table: appName: ` + `${appName}: DataStore ID: ${dataStoreId} Table ID: ${tableId}`;
            if (response.status === 204) {
                console.info(`${msgPrefix}: Success`);
                return Promise.resolve();
            } else {
                const errorMsg = appendRequestID(`${msgPrefix}: Error: ${response.statusText}`, response);
                console.error(errorMsg);
                throw Error(errorMsg);
            }
        });
    }

    public async transferTables(appName: string, dataStoreId: number, request: TransferTableRequest): Promise<void> {
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/applications/${encodeURIComponent(
                appName
            )}/datastore/${dataStoreId}/transfer-tables`,
            JSON.stringify(request)
        ).then((response: Response): Promise<void> => {
            const msgPrefix = `Transfer tables: appName: ` + `${appName}: DataStore ID: ${dataStoreId}`;
            if (response.status === 200) {
                return Promise.resolve();
            } else {
                const errorMsg = appendRequestID(`${msgPrefix}: Error: ${response.statusText}`, response);
                console.error(errorMsg);
                throw Error(errorMsg);
            }
        }, this.buildDefaultRejection());
    }

    public exportTablesToCSV(tableIDs: number[], exportFileName: string, dataStore: DataStoreResponse): Promise<void> {
        const exportTableRequest: ExportTablesRequest = {
            exportName: exportFileName,
            tableIDs: tableIDs,
            dataStoreUUID: dataStore.uuid ?? "",
            dataStoreName: dataStore.name,
        };
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/applications/export/tables`,
            JSON.stringify(exportTableRequest)
        ).then((response: Response): void => {
            const msgPrefix = `Export tables with IDs [${tableIDs}] as file named ${exportFileName}`;
            if (!response.ok) {
                const errorMsg = appendRequestID(`${msgPrefix}: Error: ${response.statusText}`, response);
                console.error(errorMsg);
                throw Error(errorMsg);
            }
        });
    }

    public generateTablesCSVTemplate(exportFileName: string): Promise<void> {
        return this.signedFetch("GET", `${this.baseApiEndpoint}/applications/export/tables/template`).then(
            (response: Response): void => {
                const msgPrefix = `Generate tables CSV template`;
                if (response.ok) {
                    response.blob().then((blob): void => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        // eslint-disable-next-line
                        a.href = url;
                        a.download = exportFileName;
                        a.click();
                    });
                } else {
                    const errorMsg = appendRequestID(`${msgPrefix}: Error: ${response.statusText}`, response);
                    console.error(errorMsg);
                    throw Error(errorMsg);
                }
            }
        );
    }

    public uploadTablesCSV(file: File, appName: string, dataStore: DataStoreResponse): Promise<void> {
        const formData = new FormData();
        const dataStoreID = dataStore.id ?? 0;
        formData.append("csvFile", file);
        formData.append("appName", appName);
        formData.append("dataStoreID", dataStoreID.toString());
        formData.append("dataStoreName", dataStore.name);
        formData.append("dataStoreTech", dataStore.technology);
        formData.append("dataStoreUUID", dataStore.uuid ?? "0");

        return this.signedFetchFileUpload("POST", `${this.baseApiEndpoint}/applications/import/tables`, formData).then(
            async (response: Response): Promise<void> => {
                const msgPrefix = `Importing tables in ${file.name}`;

                if (!response.ok) {
                    const errorMsg = appendRequestID(`${msgPrefix}: Error: ${response.statusText}`, response);
                    console.error(errorMsg);
                    const jsonResponse = await response.json();
                    throw Error(jsonResponse.errorMsg);
                }
            }
        );
    }

    public fetchSignedS3URLTableCSVExport(jobID: number): Promise<SignedS3URLResponse> {
        return this.signedFetch("GET", `${this.baseApiEndpoint}/applications/export/tables/s3url/${jobID}`).then(
            (res: Response): any => {
                if (res.status == 200) {
                    return res.json();
                } else {
                    throw Error(appendRequestID(`Failed to fetch s3 url for export job ${jobID}`, res));
                }
            },
            this.buildDefaultRejection
        );
    }
}
