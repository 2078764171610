import { Box, Icon, Link } from "@amzn/awsui-components-react-v3";
import React from "react";

export const ORGANIZATION_GUIDANCE_INSTRUCTION = (
    <React.Fragment>
        Please ensure you have reviewed the{" "}
        <Link target={"_blank"} href={"https://w.amazon.com/bin/view/Kale/OrgSpecificGuidance/"} external={true}>
            organization-specific guidance
        </Link>{" "}
        before submitting.
    </React.Fragment>
);

export const SUBMIT_NOTICE = (
    <div>
        <Box variant="p">
            Your attestation will be submitted for Privacy and Legal Review. {ORGANIZATION_GUIDANCE_INSTRUCTION} If your
            attestation is ready for privacy and legal review, you may submit now. If you want to make more updates
            prior to submitting, select cancel to return to your attestation.
        </Box>
        <Box variant="p">
            <b>
                <Icon name="status-warning" size="normal" variant="error" /> IMPORTANT:
            </b>{" "}
            You have completed the privacy portion of your Kale attestation. By submitting this attestation, we will
            automatically send the responses you have provided so far to your organization&lsquo;s Reviewer Group for a
            privacy assessment.
        </Box>
        <Box variant="p">
            Next, you may need to go through the Accounting and Consumer Trust &amp; Partner Support (CTPS) review
            process. If, based on your responses, we detect that your application may have data relevant to these teams,
            you will be asked to answer additional questions on the next page. Please click on &quot;Submit&quot; to
            continue.
        </Box>
    </div>
);

export const APPROVE_NOTICE = (
    <div>
        <Box variant="p">
            Before submitting your approval, please make sure you have checked off all the steps for conducting privacy
            reviews on{" "}
            <Link
                target={"_blank"}
                href={"https://w.amazon.com/bin/view/CDOPrivacy/Privacy%20Reviews/Guidance%20for%20Reviewers"}
                external={true}
            >
                this page
            </Link>{" "}
            , and that the attestation reflects your final advice and feedback. Specifically with respect to data
            deletion and DSAR requirements, you must make sure to record outcome of your review in this Kale attestation
            as follows:
        </Box>
        <ol>
            <li>For each of the datastores, determine if it needs to be onboarded to AEDU or Sandfire.</li>
            <React.Fragment>
                <li>
                    Ask the application owner to go back and update the Kale attestation to reflect your decision(s)
                    (i.e. update responses to “DSAR onboarding” and “Data deletion onboarding” questions to YES or NO
                    for each datastore as appropriate). In addition, the application owner should capture any important
                    comments, action items, or decisions in the “Comments” section of the attestation.
                </li>
                <li>
                    If you determine that a datastore does not need to onboard to AEDU and/or Sandfire, capture your
                    explanation for the decision in the “Comments” Section which can be opened via the “Comments” toggle
                    button in the Header (noting specifically the Datastore Name to which the explanation relates).
                </li>
            </React.Fragment>
        </ol>
        <Box variant="p">
            If changes are needed, please reject and send the attestation back to the application owner, noting the
            required changes in the “Comments” section.
        </Box>
    </div>
);
