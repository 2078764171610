import { PersonalDataWizardStep } from "src/components/survey/PersonalDataWizard/steps/index";
import { WizardProps } from "src/components/InlineWizard/interfaces";
import {
    WHAT_IS_PERSONAL_DATA_TITLE,
    renderDataRegistryExampleElements,
} from "src/components/survey/PersonalDataWizard/content";
import React from "react";
import { Box, Container, Header } from "@amzn/awsui-components-react-v3";

export const usePersonalDataExplanationStep = (): PersonalDataWizardStep => {
    const step: WizardProps.Step = {
        title: WHAT_IS_PERSONAL_DATA_TITLE,
        description: "Please review this definition of Personal Data and the examples of Personal Data Identifiers.",
        content: (
            <React.Fragment>
                <Box>
                    <ol>
                        <li>
                            &quot;<b>Personal data</b>&quot; is any information relating to an &quot;identified or
                            identifiable natural person&quot; (referred to as a &quot;data subject&quot;). An
                            &quot;identifiable natural person&quot; in turn is defined as &quot;one who can be
                            identified, directly or indirectly, in particular by reference to an identifier such as a
                            name, an identification number, location data, an online identifier or to one or more
                            factors specific to the physical, physiological, genetic, mental, economic, cultural or
                            social identity of that natural person.&quot;
                        </li>
                        <li>
                            Furthermore, &quot;Amazon treats any information linked to a customer account (CID) or
                            similar identifier as personal data.&quot;
                        </li>
                    </ol>
                </Box>
                <Container disableHeaderPaddings={true}>
                    <Header
                        variant="h3"
                        description={
                            <span>
                                The following are examples of potential personal data identifiers. For a full list of
                                personal data identifiers, refer to the{" "}
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="src/components/survey/PersonalDataWizard/steps/personalDataExplanation"
                                >
                                    Data Element Registry
                                </a>
                                .
                            </span>
                        }
                    >
                        Personal data identifiers
                    </Header>
                    {renderDataRegistryExampleElements()}
                </Container>
            </React.Fragment>
        ),
    };

    return { isLoading: false, step, error: "" };
};
