import { AppLayout } from "@amzn/awsui-components-react-v3/polaris";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "src/components/fields/Loader";
import { DisplayMessageCb } from "src/components/survey/KaleRoutes";
import { TEST_IDS } from "shared/table-view";
import TableDetailsForm from "src/components/TableDetails/TableDetailsForm";
import {
    useFetchPageResources,
    useMakeOnSaveCb,
    useMakeSidebarToolsProps,
    useResetSidebarOnRouteChange,
    useSyncTableViewStateWithServerData,
    useTableIdentifiersFromRoute,
} from "src/components/TableDetails/TableDetailsPage/hooks";
import { TableDetailsPageProvider } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
import { QuestionChoice, TableDetails } from "src/services/KaleTablesService";
import { kaleUrls } from "src/util/Urls";
import TableDetailsBreadCrumbs from "src/components/TableDetails/TableDetailsPage/TableDetailsBreadCrumbs";
import { ComplianceTypeVisibility } from "src/components/TableDetails/TableDetailsPage/types";
import { UserRole } from "src/permissions";
import { selectCanUserEdit } from "src/components/TableDetails/user-edit-permissions";
import PageNotFound from "src/components/survey/PageNotFound";

const {
    TABLE_DETAILS_PAGE: { APP_LAYOUT, ROOT, LOADING_SPINNER, LOADED_CONTENT },
} = TEST_IDS;

export interface TableDetailsPageProps {
    displayMessage: DisplayMessageCb;
}

const TableDetailsPage = (props: TableDetailsPageProps): JSX.Element => {
    const history = useHistory();
    const tableIdentifiers = useTableIdentifiersFromRoute();
    const { applicationName, reviewId } = tableIdentifiers;

    const { displayMessage } = props;
    const [tableDetailsFromServer, setTableDetailsFromServer] = useState<TableDetails | null>(null);
    const [userRole, setUserRole] = useState<UserRole | null>(null);
    const [derUuidQuestionChoices, setDerUuidQuestionChoices] = useState<QuestionChoice[]>([]);
    const [showSidebar, setShowSidebar] = useState(false);
    const [complianceTypeVisibility, setComplianceTypeVisibility] = useState<ComplianceTypeVisibility>({});

    const { isFetchingPageResources, isValidTable } = useFetchPageResources(
        setDerUuidQuestionChoices,
        setUserRole,
        setTableDetailsFromServer,
        displayMessage
    );

    useResetSidebarOnRouteChange();

    // Sync server data into global state. Monitor unsaved changes.
    const hasUnsavedChanges = useSyncTableViewStateWithServerData(tableDetailsFromServer, derUuidQuestionChoices);

    const onSaveCb = useMakeOnSaveCb(tableDetailsFromServer, setTableDetailsFromServer, displayMessage);

    const [isInitialPageLoad, setIsInitialPageLoad] = useState<boolean>(true);
    useEffect((): void => {
        if (!isFetchingPageResources) {
            setIsInitialPageLoad(false);
        }
    }, [isFetchingPageResources]);

    const deps = { history };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    const { table, linkedTables } = tableDetailsFromServer ?? {};
    const tableName = table?.name ?? "";

    const onReturnToApplication = useCallback((): void => {
        const { history } = depsRef.current;
        const applicationUrl = kaleUrls.editKaleRecordUrl(applicationName, reviewId);
        history.push(applicationUrl);
    }, [applicationName, reviewId]);

    const legalStatus = tableDetailsFromServer?.applicationStatus ?? null;
    const canUserEdit = userRole && legalStatus ? selectCanUserEdit(legalStatus, userRole) : false;

    const sidebarToolsProps = useMakeSidebarToolsProps({
        tableDetailsFromServer,
        setTableDetailsFromServer,
        tableIdentifiers,
        tableName,
        displayMessage,
        showSidebar,
        complianceTypeVisibility,
    });

    return (
        <TableDetailsPageProvider value={{ isFormReadonly: !canUserEdit }}>
            <div data-testid={ROOT}>
                {isInitialPageLoad ? (
                    <Loader data-testid={LOADING_SPINNER} />
                ) : isValidTable ? (
                    <AppLayout
                        data-testid={APP_LAYOUT}
                        breadcrumbs={
                            <TableDetailsBreadCrumbs
                                tableIdentifiers={tableIdentifiers}
                                kaleTable={table}
                                linkedTables={linkedTables}
                            />
                        }
                        navigationHide={true}
                        toolsHide={sidebarToolsProps.toolsHide}
                        toolsOpen={sidebarToolsProps.toolsOpen}
                        toolsWidth={sidebarToolsProps.toolsWidth}
                        onToolsChange={sidebarToolsProps.onToolsChange}
                        tools={sidebarToolsProps.tools}
                        headerSelector="#header"
                        footerSelector="#footer"
                        content={
                            tableDetailsFromServer ? (
                                // We want the loaded content to un-mount and re-mount whenever new Table Details
                                // are fetched as this will re-initialize all of the content subtree's local state
                                // fields that are initialized from props. We achieve this rendering the loading
                                // spinner instead of the loaded content while fetching the page resources
                                <React.Fragment>
                                    {isFetchingPageResources ? (
                                        <Loader data-testid={LOADING_SPINNER} />
                                    ) : (
                                        <div data-testid={LOADED_CONTENT}>
                                            <TableDetailsForm
                                                hasUnsavedChanges={hasUnsavedChanges}
                                                title={tableName}
                                                onSave={onSaveCb}
                                                onReturnToApplication={onReturnToApplication}
                                                isFetchingPageResources={isFetchingPageResources}
                                                tableDetails={tableDetailsFromServer}
                                                onShowSidebar={(visible, visibilityMap): void => {
                                                    setShowSidebar(visible);
                                                    setComplianceTypeVisibility(visibilityMap);
                                                }}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            ) : null
                        }
                    />
                ) : (
                    <PageNotFound />
                )}
            </div>
        </TableDetailsPageProvider>
    );
};

export default TableDetailsPage;
