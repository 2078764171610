import { Box, TableProps } from "@amzn/awsui-components-react-v3";
import React from "react";
import DASTable from "src/components/fields/table/DASTable";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { HeaderItem } from "src/components/survey/DataStoreInfo/DataStoreTable/styled";
import { Flag, SurveyResponse } from "src/components/survey/SurveyFormModel";
import { TAFDecision } from "src/components/TAF/TAFDetails/TAFDecisionTable";
import { Can, UserAction } from "src/permissions";

export const DATA_USAGE_LEGAL_POLICY = "https://policy.a2z.com/docs/50725/publication";

export const TEST_IDS = {
    FINAL_DECISION_TABLE: "taf-final-decision-table",
};

export const renderFinalDecision = (dsResponse: DataStoreResponse): React.ReactNode => {
    const retentionDecisions: JSX.Element[] = [];
    const decisionMap = {
        [Flag.accounting]: dsResponse.accountingRetentionDecision as TAFDecision,
        [Flag.ctps]: dsResponse.fraudRetentionDecision as TAFDecision,
    };

    for (const [decisionMapType, decision] of Object.entries(decisionMap)) {
        const decisionType =
            decisionMapType === Flag.ctps
                ? "prevent fraud or evaluate credit risks"
                : "perform tax and accounting activities";
        const inverseDecisionType =
            decisionMapType === Flag.ctps
                ? "perform tax and accounting activities"
                : "prevent fraud or evaluate credit risks";
        const pendingType =
            decisionMapType === Flag.ctps ? "Consumer Trust & Partner Support (CTPS)" : "Tax and Accounting";

        const hardDeletionGuidance = (
            <Box variant="p">
                We have detected this data store does not contain data used to <b>{decisionType}</b>. For deletion
                requests received, you must <b>use hard-deletion</b>. For more information, please consult this{" "}
                <a
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    href={
                        // eslint-disable-next-line max-len
                        "https://w.amazon.com/bin/view/CDOPrivacy/DeletionGuidance/PrivacyDeveloperGuidance/#HHardDeletion"
                    }
                >
                    guidance
                </a>
                . (Please note, if you need to keep the data to {inverseDecisionType}, that guidance supersedes this
                guidance.)
            </Box>
        );

        const hardDeletionGuidanceWithContext = (
            <Box variant="p">
                Previously, you were instructed to use soft deletion as we detected this data store does not contain
                data used to <b>{decisionType}</b>. Going forward for any deletion requests received, you must{" "}
                <b>use hard-deletion</b>. For more information, please consult the{" "}
                <a
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    href={
                        // eslint-disable-next-line max-len
                        "https://w.amazon.com/bin/view/CDOPrivacy/DeletionGuidance/PrivacyDeveloperGuidance/#HHardDeletion"
                    }
                >
                    hard-deletion guidance
                </a>
                . (Please note, if you need to keep the data to {inverseDecisionType}, that guidance supersedes this
                guidance.)
            </Box>
        );

        switch (decision) {
            case TAFDecision.retain:
                retentionDecisions.push(
                    <Box variant="p">
                        We have detected this data store contains data used to <b>{decisionType}</b>. For deletion
                        requests received, you must <b>retain the data</b> for the duration outlined in the{" "}
                        <a target={"_blank"} rel={"noopener noreferrer"} href={DATA_USAGE_LEGAL_POLICY}>
                            Data Use Standard
                        </a>
                        .
                    </Box>
                );
                break;
            case TAFDecision.noRetain:
            case TAFDecision.noRetainSelfApproved:
                if (decisionMapType === Flag.accounting) {
                    // Learn More: Read the code comment right above where TAFDecision.noRetain is assigned its value
                    retentionDecisions.push(hardDeletionGuidanceWithContext);
                } else {
                    retentionDecisions.push(hardDeletionGuidance);
                }
                break;
            case TAFDecision.noRetainAndHardDelete:
                retentionDecisions.push(hardDeletionGuidance);
                break;
            case TAFDecision.noDelete:
                if (retentionDecisions.length === 0) {
                    retentionDecisions.push(
                        <Box variant="p">
                            You have indicated that you are not deleting data within this data store. This data store is
                            not eligible for onboarding to a system vending deletion requests until you change your
                            action required.
                        </Box>
                    );
                }
                break;
            case TAFDecision.retainSelfSelected:
                retentionDecisions.push(
                    <Box variant="p">
                        You have indicated that this data store contains data used to <b>{decisionType}</b>. For
                        deletion requests received, you must <b>retain the data</b> for the duration outlined in the
                        <a target={"_blank"} rel={"noopener noreferrer"} href={DATA_USAGE_LEGAL_POLICY}>
                            Data Use Standard
                        </a>
                        .
                    </Box>
                );
                break;
            case TAFDecision.pending:
                retentionDecisions.push(<Box variant="p">Decision pending from {pendingType}.</Box>);
                break;
            case TAFDecision.softDeleteSelfSelected:
                // Only display one guidance and explicitly use "prevent fraud or evaluate credit risks" string in the
                // last line because the customer originally chose a Deletion_obligations response with Tax&Acct in it
                if (retentionDecisions.length === 0) {
                    retentionDecisions.push(
                        <Box variant="p">
                            You have indicated you will soft-delete the data in this data store. For deletion requests
                            received, you must use soft-deletion. For more information, please consult this{" "}
                            <a
                                target={"_blank"}
                                rel={"noopener noreferrer"}
                                href={
                                    // eslint-disable-next-line max-len
                                    "https://w.amazon.com/bin/view/CDOPrivacy/DeletionGuidance/PrivacyDeveloperGuidance/#HSoftDeletionSolutionforApprovedUseCasesofOD3"
                                }
                            >
                                guidance
                            </a>
                            . (Please note, if you need to keep the data to prevent fraud or evaluate credit risks, that
                            guidance supersedes this guidance.)
                        </Box>
                    );
                }
                break;
            case TAFDecision.hardDelete:
                // Currently, hardDelete is only given to Acct Final Decisions
                retentionDecisions.push(
                    <Box variant="p">
                        We have detected this data store does not contain data used to{" "}
                        <b>perform tax and accounting activities</b>. For deletion requests received, you must{" "}
                        <b>delete the data</b>. For more information, please consult this{" "}
                        <a
                            target={"_blank"}
                            rel={"noopener noreferrer"}
                            href={"https://w.amazon.com/bin/view/CDOPrivacy/DeletionGuidance/PrivacyDeveloperGuidance/"}
                        >
                            guidance
                        </a>
                    </Box>
                );
                break;
            default:
                if (retentionDecisions.length === 0) {
                    retentionDecisions.push(<Box variant="p">Decision Pending</Box>);
                }
                break;
        }
    }
    return <React.Fragment>{retentionDecisions}</React.Fragment>;
};

const getDecisionColumns = (): TableProps.ColumnDefinition<DataStoreResponse>[] => {
    return [
        {
            id: "dataStoreName",
            width: 200,
            header: (
                <HeaderItem title={"Data Store Name"}>
                    <b>Data Store Name</b>
                </HeaderItem>
            ),
            cell: (row): string => row.name,
        },
        {
            id: "retentionGuidance",
            width: 300,
            header: (
                <HeaderItem title={"Decision"}>
                    <b>CDO Decision</b>
                </HeaderItem>
            ),
            cell: (row: DataStoreResponse): React.ReactNode => {
                return renderFinalDecision(row);
            },
        },
    ];
};

interface Props {
    tafDetailsFromServer: SurveyResponse | null;
}

export const TAFFinalDecisionTable = (props: Props): JSX.Element => {
    const { tafDetailsFromServer } = props;

    return (
        <Can
            perform={UserAction.viewFinalDecisions}
            yes={(): JSX.Element => (
                <DASTable
                    data-testid={TEST_IDS.FINAL_DECISION_TABLE}
                    id={TEST_IDS.FINAL_DECISION_TABLE}
                    tableName={"Retention Guidance"}
                    tableDescription={<span>Based on the Financial Review, we recommend you apply the following:</span>}
                    isLoading={false}
                    hidePreferences={true}
                    hideContentSelector={true}
                    columnDefinitions={getDecisionColumns()}
                    emptyTextPrefix={"No decisions"}
                    rowItems={tafDetailsFromServer?.appInfo.review.dataStores ?? []}
                />
            )}
        />
    );
};
