import { combineReducers } from "redux";
import commentsViewReducer from "src/components/CommentsView/state/reducer";
import applicationOverviewReducer from "src/components/survey/ApplicationOverview/state/reducer/reducer";
import applicationInfoReducer from "src/components/survey/state/reducer/reducer";

/**
 * Kale root reducer
 */
const rootReducer = combineReducers({
    commentsView: commentsViewReducer,
    applicationOverview: applicationOverviewReducer,
    applicationInfo: applicationInfoReducer,
});

export default rootReducer;
