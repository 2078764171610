import React, { useRef } from "react";
import { Button, FormField, Icon } from "@amzn/awsui-components-react-v3/polaris";
import { colorTextStatusSuccess } from "@amzn/awsui-design-tokens/polaris";
import styled from "styled-components";
import Error from "src/util/Error";

const WikiDiv = styled.div`
    position: relative;
    padding-top: 50px;
`;

const StyledRelativeDiv = styled.div`
    position: relative;
    padding-top: 10px;
`;

const StyledIcon = styled.span`
    padding-right: 10px;
`;

const SuccessText = styled.span`
    &,
    * {
        color: ${colorTextStatusSuccess} !important;
    }
`;

const TEST_IDS = {
    FILE_UPLOAD_HIDDEN_INPUT: "file-upload-hidden-input",
    FILE_UPLOAD_BUTTON: "file-upload-button",
    FILE_UPLOAD_SUCCESS_MSG: "file-upload-success-msg",
    FILE_UPLOAD_ERROR_MSG: "file-upload-error-msg",
    FILE_UPLOAD_SUCCESS_ICON: "file-upload-success-icon",
    FILE_UPLOAD_ERROR_ICON: "file-upload-error-icon",
    FILE_UPLOAD_FORM_FIELD: "file-upload-form-field",
    FILE_UPLOAD_FILE_NAME_DIV: "file-upload-file-name-div",
    FILE_UPLOAD_WIKI_LINK: "file-upload-wiki-link",
};

interface Props {
    fileName: string;
    fileType: string;
    label: string;
    description: string;
    isDoneUploading: boolean;
    errMsg: string;
    onSelectFile: (uploadedFile: File | undefined) => void;
    selectFileText?: string;
    wikiLink?: string;
}

const FileUpload = ({
    fileName,
    fileType,
    label,
    description,
    onSelectFile,
    selectFileText,
    isDoneUploading,
    errMsg,
    wikiLink,
}: Props): JSX.Element => {
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);

    return (
        <FormField
            data-testid={TEST_IDS.FILE_UPLOAD_FORM_FIELD}
            label={label ?? "Upload File"}
            description={description ?? "Please select a file to upload"}
        >
            <input
                data-testid={TEST_IDS.FILE_UPLOAD_HIDDEN_INPUT}
                ref={hiddenFileInput}
                type={"file"}
                hidden
                multiple={false}
                accept={fileType}
                onChange={(event): void => {
                    const eventFiles = event?.target?.files;
                    if (eventFiles !== null && eventFiles.length > 0) {
                        onSelectFile(eventFiles[0]);
                    }
                }}
            />
            <Button
                data-testid={TEST_IDS.FILE_UPLOAD_BUTTON}
                iconName={"upload"}
                formAction={"none"}
                onClick={(): void => {
                    // @ts-ignore
                    hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                    hiddenFileInput.current?.click();
                }}
            >
                {selectFileText ?? "Choose file"}
            </Button>
            <StyledRelativeDiv>
                {errMsg ? (
                    <Error data-testid={TEST_IDS.FILE_UPLOAD_ERROR_MSG} text={`Upload failed: ${errMsg}`} />
                ) : (
                    isDoneUploading && (
                        <SuccessText data-testid={TEST_IDS.FILE_UPLOAD_SUCCESS_MSG}>Upload finished!</SuccessText>
                    )
                )}
            </StyledRelativeDiv>
            {fileName && (
                <StyledRelativeDiv data-testid={TEST_IDS.FILE_UPLOAD_FILE_NAME_DIV}>
                    <StyledIcon>
                        {isDoneUploading && errMsg === "" ? (
                            <Icon
                                data-testid={TEST_IDS.FILE_UPLOAD_SUCCESS_ICON}
                                name={"status-positive"}
                                variant={"success"}
                            />
                        ) : (
                            <Icon data-testid={TEST_IDS.FILE_UPLOAD_ERROR_ICON} name={"file"} variant={"normal"} />
                        )}
                    </StyledIcon>
                    {fileName}
                </StyledRelativeDiv>
            )}
            {wikiLink && (
                <WikiDiv>
                    For more info go{" "}
                    <a
                        data-testid={TEST_IDS.FILE_UPLOAD_WIKI_LINK}
                        href={wikiLink}
                        rel="noopener noreferrer"
                        target={"_blank"}
                    >
                        here
                    </a>
                    .
                </WikiDiv>
            )}
        </FormField>
    );
};

export { FileUpload, TEST_IDS };
