import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const SidebarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 140px);
    z-index: 2500;
`;

const SidebarHeader = styled.div`
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    border-bottom: 2px solid var(--awsui-color-border-divider-default);
`;

const SidebarContainer = styled.div`
    padding: 1.5rem;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

    /* 
     Firefox requires min-height of 0 to make scrolling element 
     obey flex-shrink rule
     https://bugzilla.mozilla.org/show_bug.cgi?id=1108514#c2
    */
    min-height: 0px;
`;

const SidebarFooter = styled("footer")`
    flex-shrink: 0;
    padding: 1.5rem;
`;

type ItemKey = unknown;

export interface ScrollableSidebarLayoutProps {
    header: React.ReactNode;
    children: React.ReactNode;
    footer: React.ReactNode;
    itemKey: ItemKey;
}

const useResetContentScrollPosition = (fieldId: ItemKey): React.RefObject<HTMLElement> => {
    // Reset scroll position of the content element whenever the user switches to a new field
    const contentRef = useRef<HTMLElement>(null);
    useEffect((): void => {
        if (fieldId) {
            const scrollableElement = contentRef.current;
            if (scrollableElement) {
                scrollableElement.scrollTop = 0;
            }
        }
    }, [fieldId]);

    return contentRef;
};

/**
 * A component responsible for sidebar layout. Renders the sidebar as a vertical flex box with 3 regions:
 * header, body, and footer. The header and footer regions grow in height to match the height of their
 * rendered contents. The body region grows or shrinks in height based on the height of its rendered contents, but only
 * up to a maximum height equal to the remaining vertical space in the sidebar that not already consumed by the header
 * or footer regions. The body region will automatically display a vertical scroll bar and become scrollable when the
 * height of its contents, would overflow this maximum height for the body region.
 * @param props.header - The sidebar header content
 * @param props.children - The sidebar body content
 * @param props.footer - The sidebar footer content
 * @param props.itemKey - a stable key representing the current item rendered in the sidebar. When this changes the
 * component assumes a new item has been loaded into the sidebar and will make sure that the vertical scroll bar of the
 * body region is reset back to zero. Set this property when the sidebar is expected to switch between similar templated
 * items while remaining open
 */
const SidebarLayout = (props: ScrollableSidebarLayoutProps): JSX.Element => {
    const { header, children, footer, itemKey } = props;

    const contentRef = useResetContentScrollPosition(itemKey);

    return (
        <SidebarWrapper>
            <SidebarHeader>{header}</SidebarHeader>
            <SidebarContainer ref={contentRef as React.RefObject<HTMLDivElement>}>{children}</SidebarContainer>
            <SidebarFooter>{footer}</SidebarFooter>
        </SidebarWrapper>
    );
};

export default SidebarLayout;
