import React, { createContext, useState } from "react";
import Header from "src/components/header/Header";
import Footer from "src/components/footer/Footer";
import { KaleConsumer } from "src/components/KaleContext";
import Kale from "src/components/survey/Kale";
import "./Layout.scss";
import IsItDownBanner from "./IsItDownBanner";

export const SIM_TICKET_LINK = "https://t.corp.amazon.com/create/templates/b5a81cb7-4351-4711-85a6-d797c595e12f";
export const FAQ_LINK = "https://w.amazon.com/bin/view/Kale/FAQ/";

interface HeaderMenuToggles {
    hasCommentsView: boolean;
    setHasCommentsView: React.Dispatch<boolean>;
}

const headerMenuDefaultValue: HeaderMenuToggles = {
    hasCommentsView: false,
    setHasCommentsView: (): void => {},
};

export const HeaderMenuContext = createContext(headerMenuDefaultValue);

export const { Provider: HeaderMenuProvider, Consumer: HeaderMenuConsumer } = HeaderMenuContext;

const Layout = React.memo((): JSX.Element => {
    const [hasCommentsView, setHasCommentsView] = useState<boolean>(false);

    return (
        <KaleConsumer>
            {({ user, config }): JSX.Element => (
                <React.Fragment>
                    <IsItDownBanner isItDownUrl={config.isItDownUrl} componentAlias={"kale"} />
                    <HeaderMenuProvider
                        value={{
                            hasCommentsView,
                            setHasCommentsView,
                        }}
                    >
                        <Header email={user.email} headerHref="/" headerTitle="Kale" />
                        <div className={"Site-content"}>
                            <Kale />
                        </div>
                    </HeaderMenuProvider>
                    <Footer
                        footerText="Kale is supported by the:"
                        teamName="CDO Privacy Engineering Team"
                        teamHref="https://w.amazon.com/bin/view/PrivacyEngineering"
                        faqHref={FAQ_LINK}
                    />
                </React.Fragment>
            )}
        </KaleConsumer>
    );
});

export default Layout;
