import React from "react";
import styled from "styled-components";
import { Badge, Box, Link } from "@amzn/awsui-components-react-v3";
import { WizardProps } from "./interfaces";

export const NAV_WIDTH = "200px";
export const NAV_MARGIN = "calc(var(--space-xxxl, 40px) * 2)";

const Nav = styled.nav`
    color: var(--color-text-disabled, #aab7b8);
    display: inline-block;
    width: ${NAV_WIDTH};
    min-width: ${NAV_WIDTH};
    margin-right: ${NAV_MARGIN};
    padding-top: 4px;
    padding-top: var(--space-xxs, 4px);
`;

const UnorderedList = styled.ul`
    padding-left: 0 !important;

    & > li:not(:last-child) {
        border-bottom: var(--border-divider-list-width, 1px) solid var(--color-border-layout, #d5dbdb);
        padding-bottom: var(--space-scaled-m, 16px);
        padding-top: 0;
    }
    & > li:not(:first-child) {
        margin-top: var(--space-scaled-m-, 16px);
    }
`;

const ListItem = styled.li`
    list-style: none;

    & > div {
        span[aria-current="step"] {
            font-weight: 700;
            color: var(--color-text-body-default, #16191f);
        }
        span[aria-disabled="true"] {
            font-weight: 700;
            opacity: 0.5;
        }
    }
`;

const InfoBadge = styled(Badge)`
    float: right;
`;

interface WizardNavigationProps {
    steps: readonly WizardProps.Step[];
    i18nStrings: WizardProps.I18nStrings;
    activeStepIndex: number;
    farthestStepIndex: number;
    onStepClick: (stepIndex: number) => void;
}

const WizardNavigation = ({
    steps,
    i18nStrings,
    activeStepIndex,
    farthestStepIndex,
    onStepClick,
}: WizardNavigationProps): JSX.Element => (
    <Nav>
        <UnorderedList>
            {steps.map(({ title, isOptional, infoCount }, stepIndex: number): JSX.Element => {
                const isActiveStep = activeStepIndex === stepIndex;
                const isLink = farthestStepIndex >= stepIndex && !isActiveStep;
                const hasBadge = Boolean(infoCount);

                return (
                    <ListItem key={stepIndex}>
                        <Box variant="small" display="block" margin={{ bottom: "xxs" }}>
                            {i18nStrings.stepNumberLabel && i18nStrings.stepNumberLabel(stepIndex + 1)}
                            {isOptional && <i>{` - ${i18nStrings.optional}`}</i>}
                        </Box>
                        <div>
                            {isLink ? (
                                <Link
                                    onFollow={(evt): void => {
                                        evt.preventDefault();
                                        onStepClick(stepIndex);
                                    }}
                                >
                                    {title}
                                </Link>
                            ) : (
                                <span
                                    aria-current={isActiveStep ? "step" : undefined}
                                    aria-disabled={isActiveStep ? undefined : "true"}
                                >
                                    {title}
                                </span>
                            )}
                            {hasBadge && <InfoBadge color={"blue"}>{infoCount}</InfoBadge>}
                        </div>
                    </ListItem>
                );
            })}
        </UnorderedList>
    </Nav>
);

export default WizardNavigation;
