import React, { useCallback, useEffect, useRef } from "react";
import { Box, Modal } from "@amzn/awsui-components-react-v3";
import { useSubscribeToSubmitMessage, SubmitStatus } from "src/websocket/message/Submit";
import { ApplicationStatus, selectLegalApprovalStatus, SurveyResponse } from "src/components/survey/SurveyFormModel";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import { useHistory } from "react-router-dom";
import { TEST_IDS } from "shared/survey";
import { kaleUrls } from "src/util/Urls";

interface Props {
    application: SurveyResponse;
    displayMessage: DisplayMessageCb;
    setIsSubmitModalVisible: (val: boolean) => void;
    isSubmitModalVisible: boolean;
}

/**
 * SubmitModal blocks the UI when submitting an application. It's triggered/closed via websockets from the backend.
 */
export const SubmitModal = (props: Props): JSX.Element => {
    const history = useHistory();
    const {
        application: { appInfo },
        displayMessage,
        setIsSubmitModalVisible,
    } = props;
    const {
        applicationName,
        review: { id: reviewId },
    } = appInfo;

    const { status } = selectLegalApprovalStatus(appInfo);

    const deps = { displayMessage, setIsSubmitModalVisible, history };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect((): void => {
        const { setIsSubmitModalVisible } = depsRef.current;
        setIsSubmitModalVisible(status === ApplicationStatus.calculating);
    }, [status]);

    useSubscribeToSubmitMessage(
        useCallback(
            async (message): Promise<void> => {
                if (message.payload.appName !== applicationName) {
                    return;
                }
                const { displayMessage, setIsSubmitModalVisible, history } = depsRef.current;

                switch (message.payload.status) {
                    case SubmitStatus.Started:
                        setIsSubmitModalVisible(true);
                        break;
                    case SubmitStatus.Completed:
                        // A `completed` mesage will always be sent by the backend as part of the submit flow regardless
                        // if an error is present in the payload as its job is to set back the modal's visibility.
                        // Without it, the user will be stuck and unable to close out the modal.
                        setIsSubmitModalVisible(false);
                        if (message.payload.error) {
                            // Show a warning instead of an error due to the nature of the post-submit actions, like
                            // email failure, flagging failure etc. Continue to redirect to TAF in these cases.
                            displayMessage(
                                MessageType.warning,
                                `${message.payload.requestId}: ${message.payload.error}`
                            );
                        }
                        history.push(kaleUrls.editKaleTafRecordUrl(applicationName, `${reviewId}`));
                        break;
                    case SubmitStatus.Failed:
                        setIsSubmitModalVisible(false);
                        displayMessage(MessageType.error, `${message.payload.requestId}: ${message.payload.error}`);
                }
            },
            [applicationName, reviewId]
        )
    );

    return (
        <React.Fragment>
            <Modal
                data-testid={TEST_IDS.SUBMIT_IN_PROGRESS.MODAL}
                visible={props.isSubmitModalVisible}
                size={"medium"}
                header={"Submitting Application"}
            >
                <Box data-testid={TEST_IDS.SUBMIT_IN_PROGRESS.MESSAGE} textAlign="center" color="inherit">
                    <Box variant={"p"}>This Application&apos;s Legal Review has been submitted.</Box>
                    <Box variant={"p"}>The Submit process may take a moment for large apps, so please be patient.</Box>
                </Box>
            </Modal>
        </React.Fragment>
    );
};
