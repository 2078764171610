import React from "react";
import { AndesPolicyCradleMetadata } from "src/components/TableDetails/TableDetailsForm/hooks";
import { SpaceBetween, Spinner, Box } from "@amzn/awsui-components-react-v3";
import { TEST_IDS } from "shared/table-view";

export type AndesCradleMetadataProps = AndesPolicyCradleMetadata;

const { ANDES_CRADLE_METADATA } = TEST_IDS.TABLE_DETAILS_PAGE.TABLE_DETAILS_FORM.TABLE_QUESTIONS_PANEL;

const AndesCradleMetadata = ({ isLoading, cradleMetadata, error }: AndesCradleMetadataProps): JSX.Element | null => {
    if (isLoading) {
        return (
            <SpaceBetween data-testid={ANDES_CRADLE_METADATA.LOADING_SPINNER} size={"s"} direction={"horizontal"}>
                <span>Syncing Cradle Account and Profile</span>
                <Spinner />
            </SpaceBetween>
        );
    } else if (error) {
        return <div data-testid={ANDES_CRADLE_METADATA.ERROR_MESSAGE}>{error}</div>;
    } else if (cradleMetadata) {
        const { cradleAccount, cradleProfileId, cradleProfileUrl } = cradleMetadata;
        return (
            <div data-testid={ANDES_CRADLE_METADATA.LOADED_CONTENT}>
                <Box variant="p">
                    <Box variant={"span"} fontWeight={"bold"} color={"text-label"}>
                        Cradle Account:
                    </Box>{" "}
                    {cradleAccount}
                </Box>
                <Box variant="p">
                    <Box variant={"span"} fontWeight={"bold"} color={"text-label"}>
                        Cradle Profile Id:
                    </Box>{" "}
                    {cradleProfileId}
                </Box>
                <Box variant="p">
                    <a href={cradleProfileUrl} rel={"noreferrer noopener"} target={"_blank"}>
                        Cradle URL
                    </a>
                </Box>
            </div>
        );
    } else {
        return null;
    }
};

export default AndesCradleMetadata;
