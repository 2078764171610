import React, { useContext, useEffect, useRef, useState } from "react";
import { AppInfoResponse, SurveyContext } from "src/components/survey/SurveyFormModel";
import { useAnvilApplications } from "src/components/survey/hooks/useAnvilApplications";
import { useFetchAAAIds } from "src/components/survey/hooks/useFetchAAAIds";
import { AnvilIdInputWithFunctionalReadonly } from "src/components/survey/ApplicationPermissions/AnvilIdInput";
import { ExpandableSection, FormField, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { WithFunctionalReadonlyRelatedBindle } from "src/components/survey/ApplicationPermissions/RelatedBindle";
import { FunctionalSurveyItemResult } from "src/components/survey/SurveyItem";
import TextArea from "src/components/fields/textbox/TextArea";
import { OnChangeCb } from "src/components/survey/ApplicationInfo/ApplicationInfo";
import { FetchBindlesResponse } from "src/components/survey/hooks/useFetchBindles";
import { AAAIdsInputWithFunctionalReadOnly } from "src/components/survey/ApplicationPermissions/AAAIdsInput";
import { AnvilIdStatus } from "src/components/survey/ApplicationPermissions/utils";
import KaleContext from "src/components/KaleContext";

export interface ApplicationPermissionsModel {
    shouldShowErrors: boolean;
    bindles: FetchBindlesResponse;
    response: AppInfoResponse;
    onChangeCallback: OnChangeCb;
}

export const ApplicationPermissions = (props: ApplicationPermissionsModel): JSX.Element => {
    const kaleContext = useContext(KaleContext);
    const { isAnvilDeprecated } = kaleContext.features;

    const onChangeCallbackRef = useRef<OnChangeCb>(props.onChangeCallback);
    onChangeCallbackRef.current = props.onChangeCallback;
    const { applicationPermissionsSectionErrorMsg } = useContext(SurveyContext).validation;
    const anvil = useAnvilApplications(props.response);
    const aaaResponse = useFetchAAAIds(props.bindles.controlBindle, props.bindles.relatedBindles);

    const responseRef = useRef<AppInfoResponse>(props.response);
    responseRef.current = props.response;

    useEffect((): void => {
        const taskId =
            anvil.anvilIdStatus === AnvilIdStatus.valid
                ? ""
                : anvil.anvilDetails.taskId || responseRef.current.review.taskId;
        onChangeCallbackRef.current({
            ...responseRef.current,
            review: {
                ...responseRef.current.review,
                applicationOwner: anvil.anvilDetails.appOwner || responseRef.current.review.applicationOwner,
                taskId: taskId,
            },
        });
    }, [anvil.anvilDetails.appOwner, anvil.anvilDetails.taskId, anvil.anvilIdStatus]);

    const onAnvilIdChange = (result: FunctionalSurveyItemResult<string>): void => {
        if (Boolean(result.response)) {
            props.onChangeCallback({
                ...props.response,
                review: {
                    ...props.response.review,
                    anvilId: result.response,
                },
            });
        } else {
            props.onChangeCallback({
                ...props.response,
                review: {
                    ...props.response.review,
                    anvilId: result.response,
                    applicationOwner: "",
                    taskId: "",
                },
            });
        }
    };
    const onChangeAAA = (result: FunctionalSurveyItemResult<string[]>): void => {
        props.onChangeCallback({
            ...props.response,
            review: {
                ...props.response.review,
                aaaIdentifier: result.response,
            },
        });
    };

    const OnAAAExplanationChange = (result: FunctionalSurveyItemResult<string>): void => {
        props.onChangeCallback({
            ...props.response,
            review: {
                ...props.response.review,
                definedExplanation: result.response,
            },
        });
    };

    const OnRelatedBindleChange = (result: FunctionalSurveyItemResult<string[]>): void => {
        props.onChangeCallback({
            ...props.response,
            review: {
                ...props.response.review,
                relatedBindles: result.response,
            },
        });
    };

    const [isContainerExpanded, setIsContainerExpanded] = useState<boolean>(true);
    const toggleIsExpanded = (): void => setIsContainerExpanded((isExpanded): boolean => !isExpanded);

    return isAnvilDeprecated ? (
        <ExpandableSection
            headingTagOverride={"h3"}
            headerText={"Application Permissions & Access Controls"}
            variant={"container"}
            expanded={isContainerExpanded}
            onChange={toggleIsExpanded}
        >
            <FormField errorText={props.shouldShowErrors && applicationPermissionsSectionErrorMsg} stretch={true}>
                <SpaceBetween size={"xl"}>
                    <WithFunctionalReadonlyRelatedBindle
                        id={"relatedBindles"}
                        options={props.bindles.bindleInfo.bindles}
                        expectedAnswer={props.response.review.relatedBindles ?? []}
                        isLoading={props.bindles.isLoadingBindles}
                        onChangeCallback={OnRelatedBindleChange}
                    />

                    <AAAIdsInputWithFunctionalReadOnly
                        id={"aaaIdentifiers"}
                        options={aaaResponse.aaaIdsOptions}
                        status={aaaResponse.aaaIdsStatus}
                        isLoading={aaaResponse.isLoadingAAAIds}
                        removedBindleNames={aaaResponse.removedBindleNames}
                        expectedAnswer={props.response.review.aaaIdentifier ?? []}
                        onChangeCallback={onChangeAAA}
                    />

                    <TextArea
                        id={"definedExplanation"}
                        expectedAnswer={props.response.review.definedExplanation}
                        onChangeCallback={OnAAAExplanationChange}
                    />
                </SpaceBetween>
            </FormField>
        </ExpandableSection>
    ) : (
        <ExpandableSection
            headingTagOverride={"h3"}
            headerText={"Application Permissions & Access Controls"}
            variant={"container"}
            expanded={isContainerExpanded}
            onChange={toggleIsExpanded}
        >
            <SpaceBetween size={"xl"}>
                <WithFunctionalReadonlyRelatedBindle
                    id={"relatedBindles"}
                    options={props.bindles.bindleInfo.bindles}
                    expectedAnswer={props.response.review.relatedBindles ?? []}
                    isLoading={props.bindles.isLoadingBindles}
                    onChangeCallback={OnRelatedBindleChange}
                />

                <AAAIdsInputWithFunctionalReadOnly
                    id={"aaaIdentifiers"}
                    options={aaaResponse.aaaIdsOptions}
                    status={aaaResponse.aaaIdsStatus}
                    isLoading={aaaResponse.isLoadingAAAIds}
                    removedBindleNames={aaaResponse.removedBindleNames}
                    expectedAnswer={props.response.review.aaaIdentifier ?? []}
                    onChangeCallback={onChangeAAA}
                />

                <FormField errorText={props.shouldShowErrors && applicationPermissionsSectionErrorMsg} stretch={true}>
                    <SpaceBetween size={"xl"}>
                        <AnvilIdInputWithFunctionalReadonly
                            id={"anvilId"}
                            expectedAnswer={props.response.review.anvilId}
                            anvilIds={anvil.anvilIdOptions}
                            errorMessage={anvil.anvilIdError}
                            status={anvil.anvilIdStatus}
                            onChangeCallback={onAnvilIdChange}
                            owner={anvil.anvilDetails.appOwner}
                            taskId={anvil.anvilDetails.taskId}
                        />

                        <TextArea
                            id={"definedExplanation"}
                            expectedAnswer={props.response.review.definedExplanation}
                            onChangeCallback={OnAAAExplanationChange}
                        />
                    </SpaceBetween>
                </FormField>
            </SpaceBetween>
        </ExpandableSection>
    );
};
