import { Container, Form, Header } from "@amzn/awsui-components-react-v3";
import { KaleHTMLParser } from "src/components/fields/textbox/TextBoxHelper";
import React from "react";
import { Table } from "@amzn/awsui-components-react-v3/polaris";
import styled from "styled-components";
import { TEST_IDS } from "shared/survey";

const { CHILD_DATA_DECORATOR } = TEST_IDS.DATA_STORE_INFO.DATA_STORE_WIZARD;

interface Props {
    subtext: string;
    content: string;
    children: React.ReactNode;
}

const StyledP = styled.p`
    margin-bottom: 1rem !important;
`;

const StyledDiv = styled.div`
    width: 60rem;
`;

const StyledOL = styled.ol`
    li {
        color: black !important;
    }
`;

export const childDataQuestionShortId = "has_child_data";

const ChildDataDecorator = (props: Props): JSX.Element => {
    const { subtext, content, children } = props;

    return (
        <Container
            key={"has_child_data_instruction"}
            data-testid={CHILD_DATA_DECORATOR}
            header={
                <Header
                    description={
                        "Child data is a sub-set of personal information and includes any data that can be directly " +
                        "or indirectly linked to a child as defined below."
                    }
                >
                    {"What is child data?"}
                </Header>
            }
        >
            <Form
                header={
                    <Header variant={"h3"}>
                        The data store <u>contains child data</u> if <u>any one</u> of the following conditions are met:
                    </Header>
                }
            />
            <StyledOL>
                <li>
                    <StyledP>
                        The data store contains data for customers who are within <u>any one</u> of the{" "}
                        <b>age limits</b>, based on country/countries of operation, noted below:
                    </StyledP>
                    <StyledDiv>
                        <Table
                            columnDefinitions={[
                                {
                                    id: "age",
                                    header: "Child Age Limit",
                                    cell: (item): string => item.age || "-",
                                },
                                {
                                    id: "country",
                                    header: "Country of Operation",
                                    cell: (item): string => item.country || "-",
                                },
                            ]}
                            items={[
                                {
                                    age: "Under 13 years",
                                    country: "US, UK, Rest of World (ROW)",
                                },
                                {
                                    age: "Under 14 years",
                                    country: "Italy, Spain",
                                },
                                {
                                    age: "Under 15 years",
                                    country: "Australia, China, France",
                                },
                                {
                                    age: "Under 16 years",
                                    country: "Brazil, Germany, Luxembourg, Netherlands, Poland",
                                },
                                {
                                    age: "Under 18 years",
                                    country: "Turkey, India, Egypt",
                                },
                            ]}
                        />
                    </StyledDiv>
                </li>
                <br />
                <li>
                    If the data store supports or receives data from either (a) a child-directed or (b) a profile-aware
                    service that supports child profiles.
                    <ul>
                        <li>
                            Examples of <b>child-directed services</b> are: Amazon Kids and Echo Glow.
                        </li>
                        <li>
                            Examples of <b>profile-aware services that support child profiles</b> are: Prime Video, Fire
                            TV, and Alexa.
                        </li>
                    </ul>
                </li>
            </StyledOL>
            <br />
            <hr />
            <Form
                header={
                    <Header variant={"h3"} description={KaleHTMLParser(subtext)}>
                        <b>{KaleHTMLParser(content)}</b>
                    </Header>
                }
            />
            {children}
        </Container>
    );
};

export default ChildDataDecorator;
