import React from "react";
import styled from "styled-components";

const StyledUL = styled.ul`
    column-count: 5;
    li {
        padding-top: 0.5rem !important;
    }
`;

export const renderDataRegistryExampleElements = (): JSX.Element => (
    <StyledUL>
        <li>Cookie IDs (3P advertising)</li>
        <li>Customer ID</li>
        <li>Speaker ID</li>
        <li>Voice Recording</li>
        <li>IP Address</li>
        <li>Device ID for Advertising</li>
        <li>Device Serial Number</li>
        <li>Street Number and Street Name</li>
        <li>Customer Geolocation</li>
        <li>Phone Number</li>
        <li>Drivers License Number</li>
        <li>Passport Number</li>
        <li>Order ID</li>
        <li>Address ID</li>
        <li>Shipment ID</li>
        <li>Merchant ID</li>
        <li>Seller ID</li>
        <li>Tax ID</li>
        <li>Email Address</li>
        <li>Date of Birth</li>
    </StyledUL>
);

export const renderLineageLearnMore = (): JSX.Element => (
    <React.Fragment>
        Learn more about our data lineage technology by visiting our{" "}
        <a rel="noreferrer" target="_blank" href={"https://w.amazon.com/bin/view/PrivacyEngineering/Product/Lineage/"}>
            wiki
        </a>
        .
    </React.Fragment>
);

export const WHAT_IS_PERSONAL_DATA_TITLE = "What is personal data?";
export const LINEAGE_FINDINGS_TITLE = "Lineage findings";
export const PERSONAL_DATA_CLASSIFIER_FINDINGS_TITLE = "Personal Data Classifier findings";
export const PERSONAL_DATA_STATEMENT_TITLE = "Personal Data Statement";
export const SUMMARY_OF_FINDINGS_TITLE = "Summary of findings";
