import React, { useEffect, useRef, useState } from "react";
import WizardForm from "./wizard-form";
import WizardNavigation, { NAV_MARGIN, NAV_WIDTH } from "./wizard-navigation";
import { InlineWizardProps, WizardProps } from "./interfaces";
import { fireNonCancelableEvent } from "./DEPRECATED_polarisEvents";

import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
`;

const Content = styled.div`
    width: calc(100% - ${NAV_WIDTH} - ${NAV_MARGIN}) !important;
`;

export type { InlineWizardProps };
// TODO - clean up to use props instead of destructuring
export default function KaleInlineWizard({
    steps,
    activeStepIndex: controlledActiveStepIndex,
    hideSubmitButton,
    i18nStrings,
    isLoadingNextStep = false,
    onSubmit,
    onNavigate,
    ...rest
}: InlineWizardProps): JSX.Element {
    const baseProps = rest;
    const [activeStepIndex, setActiveStepIndex] = useState(controlledActiveStepIndex);
    const actualActiveStepIndex = activeStepIndex ? Math.min(activeStepIndex, steps.length - 1) : 0;

    const farthestStepIndex = useRef<number>(actualActiveStepIndex);
    farthestStepIndex.current = Math.max(farthestStepIndex.current, actualActiveStepIndex);

    const navigationEvent = (requestedStepIndex: number, reason: WizardProps.NavigationReason): void => {
        fireNonCancelableEvent(onNavigate, { requestedStepIndex, reason });
    };

    const isLastStep = actualActiveStepIndex >= steps.length - 1;

    useEffect(() => {
        setActiveStepIndex(controlledActiveStepIndex);
    }, [controlledActiveStepIndex]);

    return (
        <div data-testid={"inline-wizard"} {...baseProps}>
            <Wrapper>
                <WizardNavigation
                    steps={steps}
                    activeStepIndex={actualActiveStepIndex}
                    i18nStrings={i18nStrings}
                    farthestStepIndex={farthestStepIndex.current}
                    onStepClick={(stepIndex): void => navigationEvent(stepIndex, "step")}
                />
                <Content>
                    <WizardForm
                        steps={steps}
                        i18nStrings={i18nStrings}
                        activeStepIndex={actualActiveStepIndex}
                        hideSubmitButton={hideSubmitButton}
                        isPrimaryLoading={isLoadingNextStep}
                        onPreviousClick={(): void => navigationEvent(actualActiveStepIndex - 1, "previous")}
                        onPrimaryClick={
                            isLastStep
                                ? (): void => fireNonCancelableEvent(onSubmit, {})
                                : (): void => navigationEvent(actualActiveStepIndex + 1, "next")
                        }
                    />
                </Content>
            </Wrapper>
        </div>
    );
}
