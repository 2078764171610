import { FormField, Input } from "@amzn/awsui-components-react-v3";
import React from "react";
import { TEST_IDS } from "shared/new-application";

const { APPLICATION_NAME_FIELD } = TEST_IDS;

interface ApplicationNameFieldProps {
    value: string;
    onChange: (value: string) => void;
}
const ApplicationNameField = (props: ApplicationNameFieldProps): JSX.Element => {
    return (
        <FormField
            label={"Application Name"}
            description={
                <span>
                    Enter a unique name to identify your application. You should use the Anvil application name, if one
                    exists. This <b>cannot</b> be changed afterwards.
                </span>
            }
        >
            <Input
                id={"applicationNameField"}
                data-testid={APPLICATION_NAME_FIELD}
                value={props.value}
                onChange={(e): void => props.onChange(e.detail.value)}
                onBlur={(): void => props.onChange(props.value.trim())}
            />
        </FormField>
    );
};

export default React.memo(ApplicationNameField);
