import { AppLayout, BreadcrumbGroupProps, ExpandableSection, FormField, Header } from "@amzn/awsui-components-react-v3";
import React, { useCallback, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Loader from "src/components/fields/Loader";
import InputPanel from "src/components/schema_table/importers/bumblebee_importer/InputPanel";
import TablePicker from "src/components/schema_table/importers/bumblebee_importer/TablePicker";
import ImportForm, { ImporterRouteParams } from "src/components/schema_table/importers/ImportForm";
import SchemaPreview from "src/components/schema_table/importers/SchemaPreview";
import { TableDefinition } from "src/components/schema_table/SchemaTable";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { KaleRoutesProps } from "src/components/survey/KaleRoutes";
import { makeDefaultDataStoreResponse } from "src/components/survey/LegalSurveyPage";
import { Table } from "src/services/BumblebeeService";
import { TableSources } from "src/services/KaleTablesService";
import { BreadcrumbItems } from "src/util/Breadcrumbs";
import KaleBreadcrumbGroup from "src/components/KaleBreadcrumbGroup";

export const TEST_IDS = {
    SPINNER: "bumblebee-importer-spinner",
};

export interface BumblebeeMetadata {
    teamName: string;
    hostEndpoint: string;
    databaseName: string;
    schemaName: string;
    tableName: string;
}

const BumblebeeImporter = (props: KaleRoutesProps): JSX.Element => {
    const { displayMessage } = props;
    const location = useLocation<DataStoreResponse>();
    const params = useParams<ImporterRouteParams>();
    const [tables, setTables] = useState<Table[]>([]);
    const [tableDefinitions, setTableDefinitions] = useState<TableDefinition[]>([]);
    const [sourceMetadata, setSourceMetadata] = useState<BumblebeeMetadata[]>([]);
    const dataStore = location.state || makeDefaultDataStoreResponse();
    const isLoading = false;

    const onTableIdentifiersChange = useCallback(
        (
            teamName: string,
            hostEndpoint: string,
            databaseName: string,
            schemaNames: string[],
            tables: Table[]
        ): void => {
            setTableDefinitions([]);
            setSourceMetadata(
                tables.map((table): BumblebeeMetadata => {
                    return {
                        teamName,
                        hostEndpoint,
                        databaseName,
                        schemaName: table.schema_name,
                        tableName: table.table_name,
                    };
                })
            );
            setTables(tables);
        },
        []
    );
    const onTableDefinitionsChange = useCallback((tableDefinitions: TableDefinition[]): void => {
        setTableDefinitions(tableDefinitions);
    }, []);

    const isSubmitEnabled = (tableDefinitions: TableDefinition[]): boolean => {
        return (
            tableDefinitions.length > 0 &&
            tableDefinitions.every((td): boolean => Boolean(td && td.name && td.name.length > 0))
        );
    };
    const renderBreadcrumbs = (): JSX.Element => {
        const dsName =
            Boolean(dataStore?.name) && Boolean(dataStore?.technology)
                ? `${dataStore.name} (${dataStore.technology})`
                : "";
        const items: BreadcrumbGroupProps.Item[] = [
            BreadcrumbItems.KALE_LANDING_PAGE,
            BreadcrumbItems.editKaleRecord(params.applicationName, params.reviewId),
            BreadcrumbItems.kaleDataStore(params.applicationName, params.reviewId, dsName),
            BreadcrumbItems.kaleBumblebeeImporter(params.applicationName, params.reviewId, Number(params.dataStoreId)),
        ];
        return <KaleBreadcrumbGroup items={items} />;
    };
    const renderContent = (): JSX.Element => {
        return (
            <ImportForm
                header={<Header variant={"h1"}>Import Bumblebee Schema to Kale</Header>}
                isDisabled={!isSubmitEnabled(tableDefinitions)}
                tableDefinitions={tableDefinitions}
                dataStore={dataStore}
                source={TableSources.BumbleBee}
                bumblebeeSourceMetadata={sourceMetadata}
                isMultiTable={true}
                displayMessage={displayMessage}
            >
                <InputPanel onTableIdentifiersChange={onTableIdentifiersChange} />
                <br />
                <TablePicker tables={tables} onTableDefinitionsChange={onTableDefinitionsChange} />
                <br />
                <ExpandableSection
                    variant={"container"}
                    header={
                        <Header variant={"h2"}>{`Schema Preview from Bumblebee (${tableDefinitions.length})`}</Header>
                    }
                >
                    <FormField stretch={true}>
                        <SchemaPreview tableDefinitions={tableDefinitions} />
                    </FormField>
                </ExpandableSection>
            </ImportForm>
        );
    };

    return (
        <React.Fragment>
            {isLoading && <Loader data-testid={TEST_IDS.SPINNER} />}
            <AppLayout
                breadcrumbs={renderBreadcrumbs()}
                navigationHide={true}
                toolsHide={true}
                content={renderContent()}
            />
        </React.Fragment>
    );
};

export default BumblebeeImporter;
