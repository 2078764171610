import { Route, Switch } from "react-router";
import SchemaImporterHelp from "src/components/schema_table/importers/schema_importer/SchemaImporterHelp";
import Anvil from "src/components/survey/Anvil";
import IndexPage from "src/components/survey/IndexPage";
import PageNotFound from "src/components/survey/PageNotFound";
import React, { useContext } from "react";
import { AnswerActionConsumer, AnswerCombinedProvider } from "src/answers_legacy";
import KaleApplication from "src/components/survey/KaleApplication/KaleApplication";
import { KaleApplicationRouterProps } from "src/components/survey/KaleApplication/KaleApplicationRoutes";
import NewApplicationPage from "src/components/NewApplication/NewApplicationPage";
import { Redirect } from "react-router-dom";
import KaleContext from "src/components/KaleContext";
import RedirectToNewEditURL from "src/components/survey/RedirectToNewEditURL";

export enum MessageType {
    error = "error",
    success = "success",
    warning = "warning",
    info = "info",
}

export type DisplayMessageCb = (type: MessageType, message: React.ReactNode) => void;
export interface KaleRoutesProps {
    displayMessage: DisplayMessageCb;
}
/**
 * Component responsible for rendering all the routes in Kale
 */
const KaleRoutes = (props: KaleRoutesProps): JSX.Element => {
    const kaleContext = useContext(KaleContext);
    const { isAnvilDeprecated } = kaleContext.features;
    return (
        <AnswerCombinedProvider>
            <AnswerActionConsumer>
                {(): JSX.Element => {
                    return (
                        <Switch>
                            <Route
                                exact
                                path="/"
                                render={(routerProps): JSX.Element => {
                                    return <IndexPage {...routerProps} {...props} />;
                                }}
                            />
                            <Route
                                exact
                                path="/help/schema-importer-help"
                                render={(): JSX.Element => <SchemaImporterHelp />}
                            />
                            <Route
                                path="/edit/:applicationName"
                                render={(routerProps: KaleApplicationRouterProps): JSX.Element => {
                                    return <RedirectToNewEditURL {...routerProps} {...props} />;
                                }}
                            />
                            <Route
                                path="/:applicationName/reviews/:reviewId/edit"
                                render={(routerProps: KaleApplicationRouterProps): JSX.Element => {
                                    return <KaleApplication {...routerProps} {...props} />;
                                }}
                            />
                            <Route
                                exact
                                path={"/new"}
                                render={(): JSX.Element => {
                                    return <NewApplicationPage />;
                                }}
                            />
                            <Route
                                path="/anvil"
                                render={(routerProps): JSX.Element => {
                                    return !!isAnvilDeprecated ? (
                                        <Redirect to={"/"} />
                                    ) : (
                                        <Anvil {...routerProps} {...props} />
                                    );
                                }}
                            />
                            <Route render={(): JSX.Element => <PageNotFound />} />
                        </Switch>
                    );
                }}
            </AnswerActionConsumer>
        </AnswerCombinedProvider>
    );
};

export default KaleRoutes;
