import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { BackendTableRecord, TableRecord, TableStatus } from "src/services/KaleTablesService";
import { Table } from "@amzn/awsui-components-react-v3/polaris";
import Alert from "@amzn/awsui-components-react-v3/polaris/alert";
import KaleContext from "src/components/KaleContext";
import { MessageType } from "src/components/survey/KaleRoutes";
import { Box, Spinner } from "@amzn/awsui-components-react-v3";
import { ConfirmActionModal } from "src/components/ConfirmActions";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { useMessageBannerActions } from "src/components/MessageBannerActions";
import { ApplicationStatus, ApprovalType, SurveyContext } from "src/components/survey/SurveyFormModel";
import { TEST_IDS as SURVEY_TEST_IDS } from "shared/survey";
import { kaleUrls } from "src/util/Urls";

const { EXPORT_TABLES } = SURVEY_TEST_IDS.DATA_STORE_INFO.SCHEMA_TABLE.ACTIONS.CSV;

const TEST_IDS = {
    EXPORT_CSV_TABLE: "export-csv-table",
    EXPORT_FLASHBAR: "export-csv-table-flashbar",
};

interface Props {
    currentDS: DataStoreResponse;
    tables: TableRecord[];
    isModalVisible: boolean;
    appName: string;
    setShouldShow: Dispatch<SetStateAction<boolean>>;
}

const ExportTablesCSV = ({ currentDS, tables, isModalVisible, appName, setShouldShow }: Props): JSX.Element => {
    const { application } = useContext(SurveyContext);
    const reviewId = application.appInfo.review.id;
    const { exportTablesToCSV } = useContext(KaleContext).service.kaleTablesService;
    const [exportIsDisabled, setExportIsDisabled] = useState<boolean>(true);
    const [selectedItems, setSelectedItems] = useState<BackendTableRecord[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { displayMessage } = useMessageBannerActions();

    const onClickExport = useCallback((): void => {
        setIsLoading(true);
        exportTablesToCSV(
            selectedItems.map((item): number => item.id),
            `${currentDS.name}.csv`,
            currentDS
        )
            .then((): void => {
                setSelectedItems([]);
                setShouldShow(false);
                const flashBarContent: React.ReactNode = (
                    <div data-testid={TEST_IDS.EXPORT_FLASHBAR}>
                        <Box variant="h3">Table export initiated</Box>Once your export is complete, you will receive an
                        email notification.{" "}
                        <a
                            href={kaleUrls.csvJobsStatusUrl(encodeURIComponent(appName), `${reviewId}`)}
                            rel={"noreferrer noopener"}
                            target={"_blank"}
                            style={{ color: "goldenrod", textDecoration: "underline" }}
                        >
                            {"View current status."}
                        </a>
                        {application.appInfo.review.approvalStatuses.find(
                            (status): boolean =>
                                status.type == ApprovalType.PrivacyApproval &&
                                status.status == ApplicationStatus.approved
                        ) && (
                            <Alert type={"warning"}>
                                Warning: Exported table data on an approved application cannot be re-imported. If you
                                need to make changes, recall your application first and then export your table data to
                                modify.
                            </Alert>
                        )}
                    </div>
                );
                displayMessage(MessageType.info, flashBarContent);
            })
            .catch((err: Error): void => {
                displayMessage(MessageType.error, err.message);
            })
            .finally((): void => setIsLoading(false));
    }, [
        exportTablesToCSV,
        selectedItems,
        currentDS,
        setShouldShow,
        appName,
        reviewId,
        application.appInfo.review.approvalStatuses,
        displayMessage,
    ]);

    const showModalCb = useCallback((): JSX.Element => {
        return (
            <React.Fragment>
                {isLoading ? (
                    <Box textAlign="center">
                        <Spinner size="large" />
                    </Box>
                ) : (
                    <Table
                        data-testid={TEST_IDS.EXPORT_CSV_TABLE}
                        items={tables}
                        columnDefinitions={colDefinitions}
                        selectionType={"multi"}
                        selectedItems={selectedItems}
                        onSelectionChange={({ detail }): void => setSelectedItems(detail.selectedItems)}
                    />
                )}
            </React.Fragment>
        );
    }, [selectedItems, isLoading, tables]);

    const onClose = useCallback((): void => {
        setShouldShow(false);
    }, [setShouldShow]);

    useEffect((): void => {
        setExportIsDisabled(selectedItems.length === 0);
    }, [selectedItems]);

    return (
        <ConfirmActionModal
            testIds={{
                cancelButton: EXPORT_TABLES.MODAL_CANCEL_BUTTON,
                confirmButton: EXPORT_TABLES.MODAL_CONFIRM_BUTTON,
                modalRoot: EXPORT_TABLES.MODAL_ROOT,
            }}
            confirmButtonOverrides={{
                label: "Export",
                disabled: exportIsDisabled,
            }}
            header={`${currentDS.name} Tables`}
            onConfirm={onClickExport}
            loading={isLoading}
            onCancel={onClose}
            visible={isModalVisible}
        >
            {showModalCb()}
        </ConfirmActionModal>
    );
};

const colDefinitions = [
    {
        id: "table_name",
        header: "Table name",
        cell: (table: BackendTableRecord): string => table.name,
    },
    {
        id: "table_status",
        header: "Status",
        cell: (table: BackendTableRecord): "" | TableStatus => table.status,
    },
    {
        id: "field_num",
        header: "# of Fields",
        cell: (table: BackendTableRecord): number => table.fieldCount,
    },
];

export { ExportTablesCSV, TEST_IDS };
