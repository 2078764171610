import { Badge, Box, TableProps } from "@amzn/awsui-components-react-v3";
import React, { useCallback, useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import DASTable, { DASTableFilterType } from "src/components/fields/table/DASTable";
import KaleContext from "src/components/KaleContext";
import ManualImporter from "src/components/schema_table/importers/ManualImporter";
import { DataStoreResponse, TransferTablesCb } from "src/components/survey/DataStoreInfo";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import { ApplicationStatus, selectLegalApprovalStatus, SurveyContext } from "src/components/survey/SurveyFormModel";
import {
    CreateTableRequest,
    CreateTableResponseBody,
    TableRecord,
    TableSources,
    TableStatus,
} from "src/services/KaleTablesService";
import styled from "styled-components";
import { AndesMetadata } from "src/components/schema_table/importers/andes_importer/AndesImporter";
import { ExportTablesCSV } from "src/components/schema_table/csv/ExportTablesCSV";
import { ImportTablesCSV } from "src/components/schema_table/csv/ImportTablesCSV";
import { TableHeaderActionStripe } from "src/components/schema_table/TableHeaderActionStripe";
import { useSynchronizedTableItemSelection } from "src/components/schema_table/useSynchronizedTableItemSelection";
import { kaleUrls } from "src/util/Urls";

enum ColumnIds {
    index = "index",
    name = "name",
    status = "status",
    fieldCount = "fieldCount",
    createdAt = "createdAt",
    updatedAt = "updatedAt",
    provider = "provider",
    version = "version",
    hootLink = "hootLink",
}

export interface TableAttribute {
    name: string;
    type: string;
    description?: string;
    samples?: string[];
}

export interface TableDefinition {
    name: string;
    source: string;
    schemaVersion?: string;
    andesProvider?: string;
    rawSchema?: string;
    schema: TableAttribute[];
}

type BadgeColor = "blue" | "grey" | "green" | "red";

const tableStatusColors: Map<TableStatus, BadgeColor> = new Map([
    [TableStatus.IdentifyDataset, "grey"],
    [TableStatus.DocumentAndAnalyze, "blue"],
    [TableStatus.ReadyForPreReview, "blue"],
    [TableStatus.PreReviewStarted, "blue"],
    [TableStatus.PreReviewComplete, "blue"],
    [TableStatus.ApprovalStarted, "blue"],
    [TableStatus.ApprovalCompleteWithActions, "red"],
    [TableStatus.ApprovalComplete, "green"],
    [TableStatus.PostApprovalChanges, "red"],
]);

export interface SchemaTableProps {
    appName: string;
    appNames: string[];
    dataStore: DataStoreResponse;
    status: ApplicationStatus;
    onChangeCallback: (dataStore: DataStoreResponse) => void;
    onTransferTablesCb: TransferTablesCb;
    displayMessage: DisplayMessageCb;
}

const Redirect = styled.a`
    cursor: pointer;
`;
const CSSString = `
    th:nth-child(2) {
        padding: 0.4rem 0 0.4rem 0 !important;
    }
    td:nth-child(2) {
        padding: 0.4rem 1rem 0.4rem 1rem !important;
    }
`;

const SchemaTable = (props: SchemaTableProps): JSX.Element => {
    const { dataStore, appName, onChangeCallback, displayMessage } = props;
    const kaleContext = useContext(KaleContext);
    const { createTable } = kaleContext.service.kaleTablesService;
    const { saveDraft, application } = useContext(SurveyContext);
    const [shouldShowManualImporter, setShouldShowManualImporter] = useState<boolean>(false);
    const [shouldShowCSVExport, setShouldShowCSVExport] = useState<boolean>(false);
    const [shouldShowCSVImport, setShouldShowCSVImport] = useState<boolean>(false);

    const { selectedItems, onSelectionChange, onAfterDeleteCb, tableKey } = useSynchronizedTableItemSelection();

    const appInfo = application.appInfo;

    const dataStoreRef = useRef<DataStoreResponse>(dataStore);
    dataStoreRef.current = dataStore;
    const indexMap = dataStoreRef.current.tables.reduce(
        (map: Map<TableRecord, number>, table: TableRecord, index: number): Map<TableRecord, number> => {
            map.set(table, index + 1);
            return map;
        },
        new Map()
    );

    const history = useHistory();
    const columnDefinitions = (): TableProps.ColumnDefinition<TableRecord>[] => {
        const baseColumns: TableProps.ColumnDefinition<TableRecord>[] = [
            {
                id: ColumnIds.index,
                header: "#",
                minWidth: "50px",
                width: 50,
                cell: (table): number => {
                    return indexMap.get(table) ?? -1;
                },
                sortingField: ColumnIds.index,
                sortingComparator: (item1: TableRecord, item2: TableRecord): number => {
                    const id1 = indexMap.get(item1 as TableRecord) ?? -1;
                    const id2 = indexMap.get(item2 as TableRecord) ?? -1;
                    return id1 > id2 ? 1 : -1;
                },
            },
            {
                id: ColumnIds.name,
                header: "Table Name",
                minWidth: "150px",
                cell: (table): JSX.Element => {
                    if (!dataStoreRef.current.id) {
                        displayMessage(MessageType.error, "Data Store ID undefined");
                    }

                    const pathname = kaleUrls.editKaleTableUrl(
                        appName,
                        `${appInfo.review.id}`,
                        dataStoreRef.current.id ?? 0,
                        `${table.id}`
                    );

                    return (
                        <Redirect
                            href={pathname}
                            onClick={async (e): Promise<void> => {
                                /*
                            When the link is clicked with either the Ctrl key or the Meta key (Command key), we simply
                            allow the browser to open the link in a new tab by performing its native operation, but a
                            click without either key requires special handling to ensure that they don't leave their
                            application without first saving it.
                             */
                                if (!e.ctrlKey && !e.metaKey) {
                                    e.preventDefault();
                                    try {
                                        // Fetching the application status again to persist the latest status,
                                        // protecting unintentional revert
                                        const legalStatus = await selectLegalApprovalStatus(appInfo);
                                        await saveDraft(legalStatus.status);
                                        history.push({
                                            pathname,
                                        });
                                    } catch (err) {
                                        displayMessage(MessageType.error, (err as Error)?.message ?? "");
                                    }
                                }
                            }}
                        >
                            {table.name}
                        </Redirect>
                    );
                },
                sortingField: ColumnIds.name,
            },
            {
                id: ColumnIds.status,
                header: "Status",
                minWidth: "150px",
                cell: (table): JSX.Element => (
                    <Badge color={tableStatusColors.get(table.status as TableStatus) || "grey"}>{table.status}</Badge>
                ),
                sortingField: ColumnIds.status,
            },
            {
                id: ColumnIds.fieldCount,
                header: "# of Fields",
                minWidth: "150px",
                cell: (table): number => {
                    return table.fieldCount;
                },
                sortingField: ColumnIds.fieldCount,
            },
        ];
        const timeColumns: TableProps.ColumnDefinition<TableRecord>[] = [
            {
                id: ColumnIds.createdAt,
                header: "Created On",
                minWidth: "150px",
                cell: (table): string => {
                    return new Date(table.createdAt).toUTCString();
                },
                sortingField: ColumnIds.createdAt,
            },
            {
                id: ColumnIds.updatedAt,
                header: "Last Modified",
                minWidth: "150px",
                cell: (table): string => {
                    return new Date(table.updatedAt).toUTCString();
                },
                sortingField: ColumnIds.updatedAt,
            },
        ];
        if (dataStoreRef.current.technology === "Andes") {
            // Tables in Andes Datastore are expected to have sourceMetadata if they were imported as Andes Tables.
            // It is currently possible to import tables to a non andes datastore and change the data store technology
            // to Andes. Our Kap to Kale Table migration campaign mis-diagnosed some Andes tables as non-Andes tables,
            // And now we have customers with these migrated data stores of non-Andes tables trying to change the data
            // store technology to Andes.
            const EMPTY_DATA = <span>&mdash;</span>;
            const andesColumns: TableProps.ColumnDefinition<TableRecord>[] = [
                {
                    id: ColumnIds.provider,
                    header: "Provider",
                    minWidth: "150px",
                    cell: (table): React.ReactNode => {
                        const sourceMetadata = table.sourceMetadata as AndesMetadata | undefined;
                        return sourceMetadata?.provider ?? EMPTY_DATA;
                    },
                },
                {
                    id: ColumnIds.version,
                    header: "Schema Version",
                    minWidth: "150px",
                    cell: (table): React.ReactNode => {
                        const sourceMetadata = table.sourceMetadata as AndesMetadata | undefined;
                        return sourceMetadata?.schemaVersion ?? EMPTY_DATA;
                    },
                },
                {
                    id: ColumnIds.hootLink,
                    header: "Hoot Link",
                    minWidth: "150px",
                    cell: (table): React.ReactNode => {
                        const sourceMetadata = table.sourceMetadata as AndesMetadata | undefined;
                        const { provider, schemaVersion } = sourceMetadata || {};
                        if (provider && schemaVersion) {
                            const hootURL =
                                `https://hoot.corp.amazon.com/providers/${provider}/tables/` +
                                `${table.name}/versions/${schemaVersion}`;
                            return (
                                <React.Fragment>
                                    <a href={hootURL} rel={"noopener noreferrer"} target={"_blank"}>
                                        View In Hoot
                                    </a>
                                </React.Fragment>
                            );
                        } else {
                            return EMPTY_DATA;
                        }
                    },
                },
            ];
            return [...baseColumns, ...andesColumns, ...timeColumns];
        } else {
            return [...baseColumns, ...timeColumns];
        }
    };

    const onCreateManualSchemaTable = useCallback(
        async (tableName: string, description: string): Promise<void> => {
            setShouldShowManualImporter(false);
            const { name = "", technology = "" } = dataStoreRef.current;
            let { id } = dataStoreRef.current;
            if (!id) {
                try {
                    const response = await saveDraft();
                    id =
                        response.appInfo.review.dataStores.find(
                            (rDatsStore): boolean => rDatsStore.name === dataStoreRef.current.name
                        )?.id ?? 0;
                } catch (err) {
                    displayMessage(MessageType.error, (err as Error)?.message ?? "");
                    return;
                }
            }
            const request: CreateTableRequest = {
                table: {
                    dataStoreName: name,
                    dataStoreId: id ?? 0,
                    dataStoreTech: technology,
                    name: tableName,
                    fieldCount: 0,
                    status: TableStatus.IdentifyDataset,
                    source: TableSources.Manual,
                    description: description,
                    hasPersonalData: "",
                },
                fields: [],
            };
            createTable(appName, id ?? 0, request)
                .then((response: CreateTableResponseBody): void => {
                    onChangeCallback({
                        ...dataStoreRef.current,
                        tables: [...dataStoreRef.current.tables, response.table],
                    });
                })
                .catch((err: Error): void => {
                    displayMessage(MessageType.error, err.message);
                });
        },
        [appName, createTable, displayMessage, onChangeCallback, saveDraft]
    );

    const getFilterKeys = (): string[] => {
        return [ColumnIds.name, ColumnIds.status];
    };

    const actionStripeEventProps = {
        onCreateTableClick: useCallback((): void => setShouldShowManualImporter(true), []),
        onExportCSVClick: useCallback((): void => setShouldShowCSVExport(true), []),
        onImportCSVClick: useCallback((): void => setShouldShowCSVImport(true), []),
        onAfterDelete: onAfterDeleteCb,
    };

    return (
        <React.Fragment>
            <DASTable<TableRecord>
                key={tableKey}
                id={"schemaTable"}
                tableName={"Tables"}
                tableDescription={
                    <Box variant="p">
                        {"Add all tables contained within the application’s data store. Once a table is added, you " +
                            "will be able to drill down to the table level view to answer privacy compliance " +
                            "questions about the table and field(s). If this is a financially relevant application, " +
                            "this section is mandatory for tables of data stores that store data beyond 30 days."}
                    </Box>
                }
                filterProps={{
                    type: DASTableFilterType.propertyFilter,
                    placeholder: "Find Tables",
                    filterKeys: getFilterKeys(),
                }}
                isLoading={false}
                isResizable={true}
                selectionType={"multi"}
                isStickyHeader={true}
                onSelectionChange={onSelectionChange}
                columnDefinitions={columnDefinitions()}
                rowItems={dataStoreRef.current.tables}
                cssString={CSSString}
                trackBy={"id"}
                renderActionStripe={(): JSX.Element => (
                    <TableHeaderActionStripe
                        onChangeCallback={props.onChangeCallback}
                        displayMessage={props.displayMessage}
                        appName={props.appName}
                        appNames={props.appNames}
                        dataStore={props.dataStore}
                        onTransferTablesCb={props.onTransferTablesCb}
                        status={props.status}
                        selectedItems={selectedItems}
                        onCreateTableClick={actionStripeEventProps.onCreateTableClick}
                        onExportCSVClick={actionStripeEventProps.onExportCSVClick}
                        onImportCSVClick={actionStripeEventProps.onImportCSVClick}
                        onAfterDelete={actionStripeEventProps.onAfterDelete}
                    />
                )}
            />
            <ManualImporter
                dataStoreName={dataStoreRef.current.name}
                isModalVisible={shouldShowManualImporter}
                onCreate={onCreateManualSchemaTable}
                onCancel={(): void => setShouldShowManualImporter(false)}
            />
            <ExportTablesCSV
                currentDS={dataStoreRef.current}
                tables={dataStoreRef.current.tables}
                isModalVisible={shouldShowCSVExport}
                appName={application.appInfo.applicationName}
                setShouldShow={setShouldShowCSVExport}
            />
            <ImportTablesCSV
                currentDS={dataStoreRef.current}
                appName={application.appInfo.applicationName}
                setShouldShow={setShouldShowCSVImport}
                isModalVisible={shouldShowCSVImport}
            />
        </React.Fragment>
    );
};

const propsAreEqual = (prevProps: SchemaTableProps, props: SchemaTableProps): boolean => {
    return prevProps.status === props.status && prevProps.dataStore === props.dataStore;
};

/**
 * This component is memoized to avoid rerender for performance gains in large tables
 * Schema table is a read only table and gets rendered only after required props are in place.
 * This is ensured by <TwoStageRender>. Only concerned props for rerender are data stores and app status.
 * Closing/opening the container of schema table would remount the component.
 */
export default React.memo(SchemaTable, propsAreEqual);
