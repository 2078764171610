import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    Box,
    Button,
    CollectionPreferencesProps,
    PropertyFilterProps,
    TableProps,
} from "@amzn/awsui-components-react-v3";
import React from "react";
import { UseCollectionOptions, UseCollectionResult } from "@amzn/awsui-collection-hooks/dist/cjs/interfaces";
import { getNodeText } from "./utils";
import {
    DASTableFilterProps,
    DASTableFilterType,
    GetFilteringPropertiesOptions,
    GetFilteringPropertiesResult,
} from "src/components/fields/table/DASTable/types";

interface EmptyStateProps {
    action: JSX.Element;
}

const EMPTY_STATE_STRINGS = {
    TITLE: "No matches",
    SUBTITLE: "We can’t find a match.",
};
function EmptyState({ action }: EmptyStateProps): JSX.Element {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {EMPTY_STATE_STRINGS.TITLE}
            </Box>
            <Box variant="p" padding={{ bottom: "s" }} color="inherit">
                {EMPTY_STATE_STRINGS.SUBTITLE}
            </Box>
            {action}
        </Box>
    );
}

function defaultGetFilteringProperties<T>({
    columnDefinitions,
    rowItems,
    filterKeys,
}: GetFilteringPropertiesOptions<T>): GetFilteringPropertiesResult {
    let filteringProperties: PropertyFilterProps.FilteringProperty[] = [];
    if (rowItems.length > 0 && columnDefinitions.length > 0) {
        filteringProperties =
            filterKeys?.map((key: string): PropertyFilterProps.FilteringProperty => {
                const column = columnDefinitions.find((column): boolean => column.id === key);
                const headerText = getNodeText(column?.header as unknown as React.ReactChild) ?? "";
                return {
                    key,
                    groupValuesLabel: `${headerText} Values`,
                    operators: [":", "=", "!="],
                    propertyLabel: headerText,
                };
            }) ?? [];
    }
    return filteringProperties;
}

type DASTableCollectionResult<T> = Pick<
    UseCollectionResult<T>,
    "items" | "actions" | "filteredItemsCount" | "collectionProps" | "paginationProps"
> & {
    // Renames UsesCollectionResult's "filterProps" and "propertyFilterProps" keys to more specifically target the
    // intended "<TextFilter>" and "<PropertyFilter>" Polaris components that they are meant to be props for.

    /** A props object to be spread onto a Polaris <TextFilter> component. */
    textFilterComponentProps: UseCollectionResult<T>["filterProps"];
    /** A props object to be spread onto a Polaris <PropertyFilter> component. */
    propertyFilterComponentProps: UseCollectionResult<T>["propertyFilterProps"];
};

interface DASTableCollectionOptions<T> {
    columnDefinitions: TableProps.ColumnDefinition<T>[];
    rowItems: T[];
    preferences: CollectionPreferencesProps.Preferences;
    filterProps?: DASTableFilterProps<T>;
    trackBy: TableProps["trackBy"];
}

/**
 * A custom hook built on top of the Polaris hook "useCollection" for tables with data that is loaded entirely into the
 * client side. This custom hook leverages Polaris' to drive behaviors for sorting, filtering, pagination, and item
 * selection.
 * @return DASTableCollectionResult - an object containing various collections of props to be used with the Polaris
 * Table. Refer to the Polaris documentation on using the "useCollection" hook with Table components for more details.
 */
function useDASTableCollection<T>(options: DASTableCollectionOptions<T>): DASTableCollectionResult<T> {
    const { columnDefinitions, filterProps, preferences, rowItems, trackBy } = options;

    let useCollectionOptions: UseCollectionOptions<T> = {
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {
            keepSelection: true,
            trackBy,
        },
    };

    if (filterProps?.type === DASTableFilterType.textFilter) {
        // Mixin the configuration for text filtering
        useCollectionOptions = {
            ...useCollectionOptions,
            filtering: {
                noMatch: (
                    <EmptyState action={<Button onClick={(): void => actions.setFiltering("")}>Clear filter</Button>} />
                ),
            },
        };
    } else if (filterProps?.type === DASTableFilterType.propertyFilter) {
        // Mixin the configuration for property filtering
        const { filterKeys, internalOverrides } = filterProps;
        const { getFilteringProperties = defaultGetFilteringProperties, filteringFunction } = internalOverrides ?? {};
        const filteringProperties = getFilteringProperties({ columnDefinitions, rowItems, filterKeys });

        useCollectionOptions = {
            ...useCollectionOptions,
            propertyFiltering: {
                filteringProperties,
                filteringFunction,
                noMatch: (
                    <EmptyState
                        action={
                            <Button
                                onClick={(): void =>
                                    actions.setPropertyFiltering({
                                        tokens: [],
                                        operation: "and",
                                    })
                                }
                            >
                                Clear filter
                            </Button>
                        }
                    />
                ),
            },
        };
    }

    const {
        items,
        actions,
        filteredItemsCount,
        filterProps: textFilterComponentProps,
        collectionProps,
        propertyFilterProps: propertyFilterComponentProps,
        paginationProps,
    } = useCollection<T>(rowItems, useCollectionOptions);

    return {
        actions,
        collectionProps,
        filteredItemsCount,
        items,
        paginationProps,
        propertyFilterComponentProps,
        textFilterComponentProps,
    };
}

export { useDASTableCollection };
