import { ApplicationStatus } from "src/components/survey/SurveyFormModel";
import { EMPTY_PERMISSION_ROLE_MAPPING, PermissionMapping, UserAction, UserRole } from "../constants";

export const LEGAL_CAN_PERMISSION_MAPPING: PermissionMapping = {
    [UserAction.editApplication]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inProgress, ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.serviceOwner]: [ApplicationStatus.inProgress],
    },
    [UserAction.editCampaignApplication]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inProgress, ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.serviceOwner]: [ApplicationStatus.inReview, ApplicationStatus.approved],
    },
    [UserAction.submitApplication]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inProgress],
        [UserRole.serviceOwner]: [ApplicationStatus.inProgress],
    },
    [UserAction.deleteApplication]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.serviceOwner]: [ApplicationStatus.inProgress, ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.admin]: [ApplicationStatus.inProgress, ApplicationStatus.inReview, ApplicationStatus.approved],
    },
    [UserAction.viewRestrictedFields]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.reviewer]: [ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.accountingReviewer]: [ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.fraudReviewer]: [ApplicationStatus.inReview, ApplicationStatus.approved],
    },
    [UserAction.approveApplication]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inReview],
        [UserRole.reviewer]: [ApplicationStatus.inReview],
    },
    [UserAction.rejectApplication]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.reviewer]: [ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.admin]: [ApplicationStatus.inReview, ApplicationStatus.approved],
    },
    [UserAction.recallApplication]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.serviceOwner]: [ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.admin]: [ApplicationStatus.inReview, ApplicationStatus.approved],
    },
    [UserAction.reviewRestrictedFields]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.approved],
        [UserRole.reviewer]: [ApplicationStatus.approved],
    },
    [UserAction.canOverrideStatus]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inProgress, ApplicationStatus.inReview, ApplicationStatus.approved],
    },
    [UserAction.canOverrideRole]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inProgress, ApplicationStatus.inReview, ApplicationStatus.approved],
    },
    [UserAction.viewAdminOnlyFields]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inProgress, ApplicationStatus.inReview, ApplicationStatus.approved],
    },
    [UserAction.viewReviewerOnlyFields]: {
        // ReviewerOnlyFields should be visible to admins and all reviewer types
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inProgress, ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.reviewer]: [ApplicationStatus.inProgress, ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.fraudReviewer]: [
            ApplicationStatus.inProgress,
            ApplicationStatus.inReview,
            ApplicationStatus.approved,
        ],
        [UserRole.accountingReviewer]: [
            ApplicationStatus.inProgress,
            ApplicationStatus.inReview,
            ApplicationStatus.approved,
        ],
    },
    [UserAction.bulkEditTableAndFields]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.serviceOwner]: [ApplicationStatus.inProgress, ApplicationStatus.inReview],
        [UserRole.reviewer]: [ApplicationStatus.inProgress, ApplicationStatus.inReview],
        [UserRole.admin]: [ApplicationStatus.inProgress, ApplicationStatus.inReview, ApplicationStatus.approved],
    },
    [UserAction.submitFinancialFeedback]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
    },
    [UserAction.rejectFinancialApp]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
    },
    [UserAction.recallFinancialApp]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
    },
    [UserAction.viewDecisions]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.editDecisions]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.viewFinalDecisions]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.ctaReaffirmPersonalData]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.approved, ApplicationStatus.inProgress, ApplicationStatus.inReview],
        [UserRole.serviceOwner]: [ApplicationStatus.approved, ApplicationStatus.inProgress, ApplicationStatus.inReview],
    },
    [UserAction.ctaRecertify]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.approved, ApplicationStatus.inProgress, ApplicationStatus.inReview],
        [UserRole.serviceOwner]: [ApplicationStatus.approved, ApplicationStatus.inProgress, ApplicationStatus.inReview],
    },
};
