export const TEST_IDS = {
    KALE_CHECKBOX_GROUP: "kale-checkbox-group--root",
    KALE_CHECKBOX: {
        ROOT: "kale-checkbox--root",
        INLINE_CHECKBOX: "kale-checkbox--inline-checkbox",
        POPOVER: "kale-checkbox--popover",
    },
    DROPDOWN_WITH_TAGS: {
        FORMFIELD: "dropdown-with-tags-formfield",
        BUTTON: "dropdown-with-tags-button",
    },
    CONTACT_LIST: "contact-list",
    ANVIL_ID: {
        LINKED_TESTID: "anvil-id-linked",
        LOADING_TESTID: "anvil-id-loading",
        VALID_TESTID: "anvil-id-valid",
        INPUT_TESTID: "anvil-id-input",
    },
    BINDLE: {
        CONTROL_LINK: "ControlBindle-bindleLink",
        CONTROL_NO_POPOVER: "ControlBindle-noBindlesPopover",
        CONTROL_REFRESH_BUTTON: "ControlBindle-refreshButton",
        RELATED_NO_POPOVER: "RelatedBindle-noBindlesPopover",
        RELATED_REFRESH_BUTTON: "RelatedBindle-refreshButton",
    },
    APP_NAME: {
        COPY_CLIPBOARD_BUTTON: "app-name--copy-button",
        STATIC_TEXT: "app-name--static-text",
        TEXT_INPUT: "app-name--text-input",
    },

    AUDIT_HISTORY_LINK: "app-name--audit-history-link",
};
