import {
    FormField,
    NonCancelableCustomEvent,
    SpaceBetween,
    Toggle,
    ToggleProps,
} from "@amzn/awsui-components-react-v3/polaris";
import React, { useContext, useState } from "react";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
import { TableQuestion } from "src/services/KaleTablesService";
import { SYNTH_METADATA_COMPLIANCE_TYPE, SYNTH_UNIVERSAL_COMPLIANCE_TYPE } from "src/components/TableDetails/constants";
import { ComplianceTypeVisibility } from "src/components/TableDetails/TableDetailsPage/types";

export type SetComplianceTypeVisibilityState = (newState: ComplianceTypeVisibility) => void;
/**
 * Custom hook to elicit the supported complianceTypes within a set of Table and Field questions and generate a dynamic
 * state shape to indicate the visibility of Table and Field questions belonging to each group of complianceTypes.
 */
export const useToggleVisibilityState = (
    tableQuestions: TableQuestion[]
): [ComplianceTypeVisibility, SetComplianceTypeVisibilityState] => {
    // This code to initialize complianceTypes state makes the following assumptions:
    // 1)
    // tableQuestions and fieldQuestions will always have the same set of compliance types, therefore we can
    // elicit what the complete set of complianceTypes for a table are, just from parsing all the tableQuestions.
    // 2)
    // the full set of complianceTypes contained within a Table will never change while that table is mounted in the
    // tableView, therefore we only need to elicit the set of complianceTypes during the first render, so we currently
    // process them in a useState initializer function, which is guaranteed to only ever be run during component mount.
    const [stateMap, setStateMap] = useState<ComplianceTypeVisibility>((): ComplianceTypeVisibility => {
        const initialState: Record<string, boolean> = {};
        tableQuestions.forEach(({ complianceType }: TableQuestion): void => {
            // Add an entry for each discovered complianceType into our state map,
            // except for the SYNTH_UNIVERSAL_COMP_TYPE, SYNTH_METADATA_COMP_TYPE.
            // The metadata questions are displayed specifically underneath the fields table
            if (
                complianceType !== SYNTH_UNIVERSAL_COMPLIANCE_TYPE &&
                complianceType !== SYNTH_METADATA_COMPLIANCE_TYPE
            ) {
                // Set each compliance type to be initially visible
                initialState[complianceType] = true;
            }
        });
        return initialState;
    });

    return [stateMap, setStateMap];
};

interface ToggleVisibilityPanelProps {
    stateMap: ComplianceTypeVisibility;
    setStateMap: SetComplianceTypeVisibilityState;
}
// Component to render a list of UI Toggles, one Toggle for each key found in the stateMap prop.
const ToggleVisibilityPanel = ({ stateMap, setStateMap }: ToggleVisibilityPanelProps): JSX.Element => {
    const complianceTypes = Object.keys(stateMap);
    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly;
    const toggleControls = complianceTypes.map((complianceType): JSX.Element => {
        const props: ToggleProps = {
            disabled: isReadonly,
            checked: stateMap[complianceType]!,
            name: complianceType,
            description: complianceType,
            onChange: (e: NonCancelableCustomEvent<ToggleProps.ChangeDetail>): void => {
                const checked = e.detail.checked;
                const newStateMap = {
                    ...stateMap,
                    [complianceType]: checked,
                };
                setStateMap(newStateMap);
            },
        };
        return <Toggle {...props} key={complianceType} />;
    });
    return (
        <FormField
            label={
                <React.Fragment>
                    Select the compliance types which apply to this table to view and answer the required questions.
                </React.Fragment>
            }
            description={
                <React.Fragment>
                    The questions below are currently optional, but some organizations would like you to use them.
                    Please consult your org on which fields should be used (
                    <a href="https://w.amazon.com/bin/view/Kale/OrgSpecificGuidance/">Guidance</a>).
                </React.Fragment>
            }
        >
            <SpaceBetween direction={"horizontal"} size={"l"}>
                {toggleControls}
            </SpaceBetween>
        </FormField>
    );
};

const MemoizedToggleVisibilityPanel = React.memo(ToggleVisibilityPanel);
export default MemoizedToggleVisibilityPanel;
