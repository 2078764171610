import React, { useCallback, useContext, useState } from "react";
import { omit, uniq } from "lodash";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import { AppInfoResponse, ReviewResponse, SurveyResponse } from "src/components/survey/SurveyFormModel";
import { answersToAttributes } from "src/components/TAF/TAFDetails/answerUtils";
import {
    DataStoreAttributePayload,
    FETCH_TAF_ERROR,
    ReviewAttributePayload,
} from "src/components/TAF/TAFDetails/constants";
import { Decision } from "src/components/TAF/TAFDetails/TAFDecisionTable";
import { ReviewGroup } from "src/services/AbstractKaleService";
import { QuestionTag } from "src/services/dynamic-questions";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import {
    DataStoresAccessor,
    mergeAnswers,
    Question,
    ReviewAccessor,
    useDataStoresPayloads,
    useReviewPayload,
} from "src/answers_legacy";
import { useTAFDetailsSelectors } from "src/components/TAF/TAFDetails/hooks";
import KaleContext from "src/components/KaleContext";
import {
    FinancialFeedbackBody,
    KaleQuestion,
    ReviewGroupsMap,
    selectGroupTypesByRole,
    TAFNotificationType,
} from "src/services/KaleApplicationService";
import { formatPageError, rebaseLocalDataWithServer } from "src/components/TAF/TAFDetails/utils";
import { useHistory } from "react-router-dom";
import { UserRole } from "src/permissions";

interface UseSaveCallbacks {
    showErrors: boolean;
    isSaveRequesting: boolean;
    isSubmitRequesting: boolean;
    isRejectRequesting: boolean;
    isRecallRequesting: boolean;
    isAssignedReviewGroupsRequesting: boolean;
    isSubmitFeedbackRequesting: boolean;
    saveSurvey: (hasSubmit?: boolean) => Promise<void>;
    saveFeedback: () => Promise<void>;
    saveAssignedReviewGroups: () => Promise<void>;
    rejectSurvey: () => Promise<void>;
    recallSurvey: () => Promise<void>;
}

export const mergeReviewGroupsPayload = (
    userRole: UserRole,
    leftReviewGroupsMap: ReviewGroupsMap,
    rightReviewGroupsMap: ReviewGroupsMap
): ReviewGroupsMap => {
    // Right-side select review group map items that are in the same review group type as the user.
    const groupTypes = selectGroupTypesByRole(userRole);
    const filteredRightReviewGroupsMap: ReviewGroupsMap = {};
    Object.keys(rightReviewGroupsMap).forEach((groupKey): void => {
        const reviewGroup = groupKey as ReviewGroup;
        if (groupTypes.includes(reviewGroup)) {
            filteredRightReviewGroupsMap[reviewGroup] = rightReviewGroupsMap[reviewGroup];
        }
    });

    // Left-side select review group map items that are not in the same review group type as the user.
    const filteredLeftReviewGroupsMap: ReviewGroupsMap = {};
    Object.keys(leftReviewGroupsMap).forEach((groupKey): void => {
        const reviewGroup = groupKey as ReviewGroup;
        if (!groupTypes.includes(reviewGroup)) {
            filteredLeftReviewGroupsMap[reviewGroup] = leftReviewGroupsMap[reviewGroup];
        }
    });

    // Merge the current review group map values to prevent the user from
    // removing groups for which they do not have permission.
    const mergedReviewGroupPayload: ReviewGroupsMap = {};
    Object.keys({ ...filteredRightReviewGroupsMap, ...filteredLeftReviewGroupsMap }).forEach((groupKey): void => {
        mergedReviewGroupPayload[groupKey] = [
            ...uniq(filteredRightReviewGroupsMap[groupKey] ?? []),
            ...uniq(filteredLeftReviewGroupsMap[groupKey] ?? []),
        ];
    });
    Object.keys(mergedReviewGroupPayload).forEach((groupKey): void => {
        mergedReviewGroupPayload[groupKey] = uniq(mergedReviewGroupPayload[groupKey]);
    });

    return mergedReviewGroupPayload;
};

export const useSaveCallbacks = (props: {
    userRole: UserRole;
    tafDetailsFromServer: SurveyResponse | null;
    originalDataStoreDecisions: DataStoreResponse[];
    originalAssignedReviewGroups: string[];
    dataStoreAccessors: DataStoresAccessor[];
    reviewAccessor: ReviewAccessor;
    displayMessage: DisplayMessageCb;
    reviewGroupsMap: ReviewGroupsMap;
    kaleQuestion: KaleQuestion;
    handleTAFDecisionChange: (dataStore: DataStoreResponse, surveyResponse: SurveyResponse) => void;
    setTAFDetailsFromServer: (tafDetails: SurveyResponse | null) => void;
}): UseSaveCallbacks => {
    const {
        userRole,
        dataStoreAccessors,
        displayMessage,
        reviewAccessor,
        tafDetailsFromServer,
        originalDataStoreDecisions,
        originalAssignedReviewGroups,
        reviewGroupsMap,
        kaleQuestion,
        handleTAFDecisionChange,
        setTAFDetailsFromServer,
    } = props;

    const {
        service: { kaleAppService },
    } = useContext(KaleContext);

    const history = useHistory();

    const dataStorePayloads = useDataStoresPayloads();
    const reviewPayload = useReviewPayload();

    const {
        selectDeletionObligationQuestion,
        selectReview,
        selectDataStoreResponseByAnswer,
        selectAssignedReviewGroups,
    } = useTAFDetailsSelectors({
        tafDetailsFromServer,
        dataStoreAccessors,
    });

    const [showErrors, setShowErrors] = React.useState<boolean>(false);

    const [isSaveRequesting, setIsSaveRequesting] = useState<boolean>(false);
    const [isSubmitRequesting, setIsSubmitRequesting] = useState<boolean>(false);
    const [isRejectRequesting, setIsRejectRequesting] = useState<boolean>(false);
    const [isRecallRequesting, setIsRecallRequesting] = useState<boolean>(false);
    const [isAssignedReviewGroupsRequesting, setIsAssignedReviewGroupsRequesting] = useState<boolean>(false);
    const [isSubmitFeedbackRequesting, setIsSubmitFeedbackRequesting] = useState<boolean>(false);

    const setLoading = (isRequesting: boolean, hasSubmit: boolean): void => {
        if (hasSubmit) {
            setIsSubmitRequesting(isRequesting);
        } else {
            setIsSaveRequesting(isRequesting);
        }
    };

    const allowSave = useCallback((): boolean => {
        return (
            dataStoreAccessors.every((dataStoreAccessor): boolean => dataStoreAccessor.isValidSave) &&
            reviewAccessor.isValidSave
        );
    }, [dataStoreAccessors, reviewAccessor]);

    const allowSubmit = useCallback((): boolean => {
        return dataStoreAccessors.every((dataStoreAccessor): boolean => {
            const deletionObligation = selectDeletionObligationQuestion(dataStoreAccessor);
            if (!deletionObligation) {
                return false;
            }
            // DeletionObligation question might have 4 or 3 options depending on the Org/review-group of the app
            if (deletionObligation.question.choices.length === 4) {
                const [NO_ACTION, IMPL_RETENTION, , SOFT_DELETE] = deletionObligation.question.choices;
                return (
                    [NO_ACTION.value, IMPL_RETENTION.value, SOFT_DELETE.value].includes(
                        deletionObligation.value as string
                    ) ||
                    (dataStoreAccessor.isValidSubmit && reviewAccessor.isValidSubmit)
                );
            } else if (deletionObligation.question.choices.length === 3) {
                const [NO_ACTION, IMPL_RETENTION] = deletionObligation.question.choices;
                return (
                    [NO_ACTION.value, IMPL_RETENTION.value].includes(deletionObligation.value as string) ||
                    (dataStoreAccessor.isValidSubmit && reviewAccessor.isValidSubmit)
                );
            } else {
                return false;
            }
        });
    }, [selectDeletionObligationQuestion, dataStoreAccessors, reviewAccessor]);

    const sendNotification = useCallback((): Promise<void> => {
        const appInfo = tafDetailsFromServer?.appInfo ?? ({} as unknown as AppInfoResponse);
        const successMessage = "TAF review is complete.";

        return kaleAppService
            .notify({
                name: appInfo.applicationName,
                type: TAFNotificationType.tafRequest,
            })
            .then((): void => {
                displayMessage(MessageType.success, successMessage);
            })
            .catch((error: Error): void => {
                displayMessage(MessageType.warning, `${successMessage} ${error.message}`);
            });
    }, [displayMessage, kaleAppService, tafDetailsFromServer]);

    const saveSurvey = useCallback(
        async (hasSubmit = false): Promise<void> => {
            if (hasSubmit && !allowSubmit()) {
                setShowErrors(true);
                displayMessage(MessageType.error, "Please provide responses to all required questions.");
                return;
            }

            if (!allowSave()) {
                setShowErrors(true);
                displayMessage(MessageType.error, "Please provide responses to all required questions.");
                return;
            }

            const appInfo = tafDetailsFromServer?.appInfo ?? ({} as unknown as AppInfoResponse);
            const tafDetails = await kaleAppService.view(appInfo.applicationName);

            const { dataStores: latestDataStores } = tafDetails.appInfo.review;

            let review: ReviewResponse = selectReview() ?? ({} as unknown as ReviewResponse);

            const reviewAttributes = answersToAttributes<ReviewAttributePayload>(reviewPayload);
            const reviewTopLevel = omit(reviewAttributes, ["assignedReviewGroups"]);
            const surveyQuestions = await kaleAppService.fetchQuestions(QuestionTag.primaryView);

            if (tafDetailsFromServer?.appInfo && review) {
                const dataStores = dataStorePayloads
                    .filter((dataStorePayload): boolean => dataStorePayload.length > 0)
                    .map((dataStorePayload): DataStoreResponse => {
                        const dataStoreTopLevel = answersToAttributes<DataStoreAttributePayload>(dataStorePayload);

                        const response = selectDataStoreResponseByAnswer(dataStorePayload[0]);

                        return {
                            ...response,
                            ...dataStoreTopLevel,
                            dataStoreAnswers: mergeAnswers(
                                response?.dataStoreAnswers ?? [],
                                dataStorePayload.filter((answer): boolean => answer.questionId !== -1),
                                surveyQuestions.dataStores as Question[],
                                kaleQuestion.dataStores as Question[]
                            ),
                        } as unknown as DataStoreResponse;
                    }) as DataStoreResponse[];

                // After receiving the most recent version of the survey response, we merge the changes made during
                // the user's session with the data store decisions in the most recent version, ensuring that their
                // changes supersede any other changes made in the most recent version.
                const mergedDataStores: DataStoreResponse[] = [];

                dataStores.forEach((dataStore): void => {
                    const originalDecisions =
                        originalDataStoreDecisions.find(
                            (originalDataStore): boolean => originalDataStore.id === dataStore.id
                        )?.decisions ?? [];

                    const latestDecisions =
                        latestDataStores.find((latestDataStore): boolean => latestDataStore.id === dataStore.id)
                            ?.decisions ?? [];

                    const mergedDecisions = rebaseLocalDataWithServer<Decision>({
                        originalList: originalDecisions,
                        updatedList: latestDecisions,
                        localList: dataStore.decisions ?? [],
                        itemCompareKeysProps: { idKey: "id", valueKey: "decision" },
                    });

                    mergedDataStores.push({ ...dataStore, decisions: mergedDecisions });
                });

                review = {
                    ...review,
                    ...reviewTopLevel,
                    dataStores: mergedDataStores,
                };

                setLoading(true, hasSubmit);

                try {
                    await kaleAppService.update({
                        ...tafDetailsFromServer,
                        appInfo: {
                            ...tafDetailsFromServer.appInfo,
                            review,
                        },
                    });
                    if (hasSubmit) {
                        displayMessage(MessageType.success, "TAF review is complete.");
                        return sendNotification().then((): void => {
                            history.push({ pathname: "/" });
                        });
                    }
                    displayMessage(MessageType.success, "TAF review successfully saved.");
                } catch {
                    displayMessage(MessageType.error, "There was an unexpected error processing your request.");
                } finally {
                    setLoading(false, hasSubmit);
                }
            }
        },
        [
            allowSave,
            allowSubmit,
            dataStorePayloads,
            displayMessage,
            history,
            kaleAppService,
            originalDataStoreDecisions,
            reviewPayload,
            selectDataStoreResponseByAnswer,
            selectReview,
            sendNotification,
            tafDetailsFromServer,
            kaleQuestion.dataStores,
        ]
    );

    const saveFeedback = useCallback(async (): Promise<void> => {
        const review: ReviewResponse | undefined = selectReview();

        if (tafDetailsFromServer?.appInfo && review) {
            setIsSubmitFeedbackRequesting(true);

            const body: FinancialFeedbackBody = {
                dataStoreDecisions:
                    tafDetailsFromServer?.appInfo.review.dataStores
                        .map((dataStore): Decision[] => dataStore.decisions ?? [])
                        .flat() ?? [],
            };

            try {
                await kaleAppService.submitFeedback(tafDetailsFromServer.appInfo.applicationName, body);
            } catch {
                displayMessage(MessageType.error, "There was an unexpected error processing your request.");
            } finally {
                try {
                    const tafDetails = await kaleAppService.view(tafDetailsFromServer.appInfo.applicationName);
                    setTAFDetailsFromServer(tafDetails);
                } catch (err) {
                    const fetchTAFError = formatPageError(FETCH_TAF_ERROR, (err as Error).message);
                    displayMessage(MessageType.error, fetchTAFError);
                }
                displayMessage(MessageType.success, "Financial Review Feedback successfully saved.");
                setIsSubmitFeedbackRequesting(false);
            }
        }
    }, [displayMessage, kaleAppService, selectReview, setTAFDetailsFromServer, tafDetailsFromServer]);

    const rejectSurvey = useCallback(async (): Promise<void> => {
        if (tafDetailsFromServer?.appInfo.applicationName) {
            setIsRejectRequesting(true);
            try {
                await kaleAppService.rejectFinancial(tafDetailsFromServer.appInfo.applicationName);
                displayMessage(MessageType.success, "Financial review successfully rejected.");
            } catch (err) {
                const fetchTAFError = formatPageError(
                    "There was an unexpected error processing your request.",
                    (err as Error).message
                );
                displayMessage(MessageType.error, fetchTAFError);
            } finally {
                try {
                    const tafDetails = await kaleAppService.view(tafDetailsFromServer.appInfo.applicationName);
                    setTAFDetailsFromServer(tafDetails);
                } catch (err) {
                    const fetchTAFError = formatPageError(FETCH_TAF_ERROR, (err as Error).message);
                    displayMessage(MessageType.error, fetchTAFError);
                }
                setIsRejectRequesting(false);
            }
        }
    }, [displayMessage, kaleAppService, setTAFDetailsFromServer, tafDetailsFromServer]);

    const recallSurvey = useCallback(async (): Promise<void> => {
        if (tafDetailsFromServer?.appInfo.applicationName) {
            setIsRecallRequesting(true);
            try {
                await kaleAppService.recallFinancial(tafDetailsFromServer.appInfo.applicationName);
                displayMessage(MessageType.success, "Financial review successfully recalled.");
            } catch (err) {
                const fetchTAFError = formatPageError(
                    "There was an unexpected error processing your request.",
                    (err as Error).message
                );
                displayMessage(MessageType.error, fetchTAFError);
            } finally {
                try {
                    const tafDetails = await kaleAppService.view(tafDetailsFromServer.appInfo.applicationName);
                    setTAFDetailsFromServer(tafDetails);
                } catch (err) {
                    const fetchTAFError = formatPageError(FETCH_TAF_ERROR, (err as Error).message);
                    displayMessage(MessageType.error, fetchTAFError);
                }
                setIsRecallRequesting(false);
            }
        }
    }, [displayMessage, kaleAppService, setTAFDetailsFromServer, tafDetailsFromServer?.appInfo.applicationName]);

    const reviewGroupListToMap = useCallback(
        (reviewGroups): ReviewGroupsMap => {
            const result: ReviewGroupsMap = {};
            reviewGroups?.forEach((reviewGroup: string): void => {
                Object.keys(reviewGroupsMap).forEach((groupType): void => {
                    if (reviewGroupsMap[groupType].includes(reviewGroup)) {
                        result[groupType] = result[groupType] || [];
                        result[groupType].push(reviewGroup);
                    }
                });
            });
            return result;
        },
        [reviewGroupsMap]
    );

    const requestLatestMergedReviewGroupsPayload = useCallback(async (): Promise<ReviewGroupsMap> => {
        const appInfo = tafDetailsFromServer?.appInfo ?? ({} as unknown as AppInfoResponse);
        const tafDetails = await kaleAppService.view(appInfo.applicationName);

        const latestAssignedReviewGroups: string[] = [];

        tafDetails.appInfo.review.dataStores.forEach((dataStore): void => {
            dataStore.decisions?.forEach((decision): void => {
                latestAssignedReviewGroups.push(decision.reviewGroupName);
            });
        });

        const reviewAttributes = answersToAttributes<ReviewAttributePayload>(reviewPayload);

        const assignedReviewGroups = rebaseLocalDataWithServer<string>({
            originalList: originalAssignedReviewGroups,
            updatedList: latestAssignedReviewGroups,
            localList: reviewAttributes.assignedReviewGroups ?? [],
        });

        const leftReviewGroupsMap = selectAssignedReviewGroups();
        const rightReviewGroupsMap = reviewGroupListToMap(assignedReviewGroups);

        return mergeReviewGroupsPayload(userRole, leftReviewGroupsMap, rightReviewGroupsMap);
    }, [
        kaleAppService,
        originalAssignedReviewGroups,
        reviewGroupListToMap,
        reviewPayload,
        selectAssignedReviewGroups,
        tafDetailsFromServer?.appInfo,
        userRole,
    ]);

    const saveAssignedReviewGroups = useCallback(async (): Promise<void> => {
        const appInfo = tafDetailsFromServer?.appInfo ?? ({} as unknown as AppInfoResponse);

        if (tafDetailsFromServer?.appInfo) {
            const mergedReviewGroupPayload = await requestLatestMergedReviewGroupsPayload();

            setIsAssignedReviewGroupsRequesting(true);

            try {
                await kaleAppService.updateAssignedReviewGroups(appInfo.applicationName, mergedReviewGroupPayload);
            } catch {
                displayMessage(MessageType.error, "There was an unexpected error processing your request.");
            } finally {
                try {
                    const tafDetails = await kaleAppService.view(appInfo.applicationName);
                    tafDetails.appInfo.review.dataStores.forEach((dataStore): void => {
                        handleTAFDecisionChange(dataStore, tafDetails);
                    });
                } catch (err) {
                    const fetchTAFError = formatPageError(FETCH_TAF_ERROR, (err as Error).message);
                    displayMessage(MessageType.error, fetchTAFError);
                }
                displayMessage(MessageType.success, "Successfully notified teams to review this application.");
                setIsAssignedReviewGroupsRequesting(false);
            }
        }
    }, [
        displayMessage,
        handleTAFDecisionChange,
        kaleAppService,
        requestLatestMergedReviewGroupsPayload,
        tafDetailsFromServer?.appInfo,
    ]);

    return {
        showErrors,
        isSaveRequesting,
        isSubmitRequesting,
        isRejectRequesting,
        isRecallRequesting,
        isAssignedReviewGroupsRequesting,
        isSubmitFeedbackRequesting,
        saveSurvey,
        saveFeedback,
        saveAssignedReviewGroups,
        rejectSurvey,
        recallSurvey,
    };
};
