import { attrSelector, compoundSelector, descendantSelector, tidSelector } from "../util/selectors";
import { TEST_IDS } from "./test-ids";

const {
    APPLICATION_OVERVIEW,
    APPLICATION_IDENTITY,
    APPLICATION_INFO,
    DATA_STORE_INFO,
    FORM_ACTIONS,
    AEDU_SANDFIRE,
    RECALL_IN_PROGRESS,
    SUBMIT_IN_PROGRESS,
} = TEST_IDS;
const {
    SUMMARY_CARDS: { CARD_BODY: SUMMARY_CARD_BODY },
    PRIVACY_ALERTS_BANNER,
} = APPLICATION_OVERVIEW;
const { DATA_STORE_TABLE, DATA_STORE_WIZARD, SCHEMA_TABLE } = DATA_STORE_INFO;
const { DELETE_DATA_STORE } = DATA_STORE_TABLE.ACTIONS;
const { COLLECT_INFO_STEP, CHILD_DATA_DECORATOR, DYNAMIC_QUESTION_STEP, SUMMARY_REVIEW_STEP } = DATA_STORE_WIZARD;
const { QUESTION_GROUP, CHILD_DATA_GATING_QUESTION_GROUP } = DYNAMIC_QUESTION_STEP;
const {
    TRANSFER_TABLES,
    CSV: { EXPORT_TABLES, IMPORT_TABLES },
    ADD_TABLE: { CREATE_TABLE },
    DELETE_TABLES,
    BULK_EDIT,
} = SCHEMA_TABLE.ACTIONS;

export const SELECTORS = {
    APPLICATION_OVERVIEW: {
        ROOT: tidSelector(APPLICATION_OVERVIEW.ROOT),
        MAIN_CONTENT: tidSelector(APPLICATION_OVERVIEW.MAIN_CONTENT),
        PRIVACY_ALERTS_BANNER: {
            ROOT: tidSelector(PRIVACY_ALERTS_BANNER.ROOT),
            PRIVACY_ALERTS_COUNT: tidSelector(PRIVACY_ALERTS_BANNER.ALERTS_COUNT),
        },
        SUMMARY_CARDS: {
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            CARD_BODY: (() => {
                const ROOT = tidSelector(SUMMARY_CARD_BODY.ROOT);
                const cardBodyByCardName = (cardName: string): string =>
                    compoundSelector(ROOT, attrSelector("data-card-name", cardName));
                return {
                    cardBodyByCardName,
                    ROOT,
                    NO_SUMMARY_ITEMS_MESSAGE: tidSelector(SUMMARY_CARD_BODY.NO_SUMMARY_ITEMS_MESSAGE),
                    FETCH_ERROR_MESSAGE: tidSelector(SUMMARY_CARD_BODY.FETCH_ERROR_MESSAGE),
                    SUMMARY_ITEMS_CONTENT: tidSelector(SUMMARY_CARD_BODY.SUMMARY_ITEMS_CONTENT),
                };
            })(),
        },
        RESOLVE_OPEN_QUESTIONS_BUTTON: tidSelector(APPLICATION_OVERVIEW.RESOLVE_OPEN_QUESTIONS_BUTTON),
    },
    APPLICATION_IDENTITY: {
        APPLICATION_NAME: tidSelector(APPLICATION_IDENTITY.APPLICATION_NAME),
        AUDIT_HISTORY: tidSelector(APPLICATION_IDENTITY.AUDIT_HISTORY),
        CREATED_ON: tidSelector(APPLICATION_IDENTITY.CREATED_ON),
        LAST_MODIFIED: tidSelector(APPLICATION_IDENTITY.LAST_MODIFIED),
        RESPONDENT_ALIAS: tidSelector(APPLICATION_IDENTITY.RESPONDENT_ALIAS),
        REVIEWER_GROUP: tidSelector(APPLICATION_IDENTITY.REVIEWER_GROUP),
        SUBMITTING_TEAM: tidSelector(APPLICATION_IDENTITY.SUBMITTING_TEAM),
    },
    APPLICATION_INFO: {
        REVIEW_QUESTIONS_PANE: {
            ROOT: tidSelector(APPLICATION_INFO.REVIEW_QUESTIONS_PANE.ROOT),
        },
        IS_FOUNDATIONAL_APP: {
            FORM_FIELD: tidSelector(APPLICATION_INFO.IS_FOUNDATIONAL_APP.FORM_FIELD),
            SELECT: tidSelector(APPLICATION_INFO.IS_FOUNDATIONAL_APP.SELECT),
        },
    },
    DATA_STORE_INFO: {
        DATA_STORE_WIZARD: {
            ROOT: tidSelector(DATA_STORE_WIZARD.ROOT),
            COLLECT_INFO_STEP: {
                ROOT: tidSelector(COLLECT_INFO_STEP.ROOT),
                NAME_INPUT: tidSelector(COLLECT_INFO_STEP.NAME_INPUT),
                UUID_COPY_CLIPBOARD_BUTTON: tidSelector(COLLECT_INFO_STEP.UUID_COPY_CLIPBOARD_BUTTON),
                UUID_TEXT: tidSelector(COLLECT_INFO_STEP.UUID_TEXT),
                DESCRIPTION_INPUT: tidSelector(COLLECT_INFO_STEP.DESCRIPTION_INPUT),
                IDENTIFIERS_FIELD: tidSelector(COLLECT_INFO_STEP.IDENTIFIERS_FIELD),
                TECHNOLOGY_FIELD: tidSelector(COLLECT_INFO_STEP.TECHNOLOGY_FIELD),
                ADD_INSTANCE_BUTTON: tidSelector(COLLECT_INFO_STEP.ADD_INSTANCE_BUTTON),
                DUPLICATE_NAME_ERROR: tidSelector(COLLECT_INFO_STEP.DUPLICATE_NAME_ERROR),
                DUPLICATE_ANDES_TECHNOLOGY_ERROR: tidSelector(COLLECT_INFO_STEP.DUPLICATE_ANDES_TECHNOLOGY_ERROR),
                DUPLICATE_DATA_STORE_ERROR: tidSelector(COLLECT_INFO_STEP.DUPLICATE_DATA_STORE_ERROR),
            },
            CHILD_DATA_DECORATOR: tidSelector(CHILD_DATA_DECORATOR),
            DYNAMIC_QUESTION_STEP:
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                (() => {
                    const questionByShortID = (questionShortId: string): string =>
                        attrSelector("data-question-shortId", questionShortId);
                    return {
                        questionByShortID,
                        ROOT: tidSelector(DYNAMIC_QUESTION_STEP.ROOT),
                        MULTISELECT_QUESTION: tidSelector(DYNAMIC_QUESTION_STEP.MULTISELECT_QUESTION),
                        STAND_ALONE_QUESTION:
                            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                            (() => {
                                const STAND_ALONE_QUESTION_ROOT = tidSelector(
                                    DYNAMIC_QUESTION_STEP.STAND_ALONE_QUESTION
                                );

                                const standAloneQuestionById = (questionShortId: string): string =>
                                    compoundSelector(STAND_ALONE_QUESTION_ROOT, questionByShortID(questionShortId));

                                return {
                                    ROOT: STAND_ALONE_QUESTION_ROOT,
                                    standAloneQuestionById,
                                };
                            })(),
                        QUESTION_GROUP:
                            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                            (() => {
                                const QUESTION_GROUP_ROOT_SELECTOR = tidSelector(QUESTION_GROUP.ROOT);
                                const GROUP_QUESTION_SELECTOR = tidSelector(
                                    QUESTION_GROUP.GROUP_SECTION.GROUP_QUESTION
                                );

                                const questionGroupById = (groupId: string): string =>
                                    compoundSelector(
                                        QUESTION_GROUP_ROOT_SELECTOR,
                                        attrSelector("data-groupId", groupId)
                                    );

                                const groupQuestionByIds = (groupId: string, questionShortId: string): string =>
                                    descendantSelector(
                                        questionGroupById(groupId),
                                        compoundSelector(GROUP_QUESTION_SELECTOR, questionByShortID(questionShortId))
                                    );

                                return {
                                    questionGroupById,
                                    ROOT: QUESTION_GROUP_ROOT_SELECTOR,
                                    CONTENT: tidSelector(QUESTION_GROUP.CONTENT),
                                    SEPARATOR: tidSelector(QUESTION_GROUP.SEPARATOR),
                                    GROUP_SECTION: {
                                        ROOT: tidSelector(QUESTION_GROUP.GROUP_SECTION.ROOT),
                                        GROUP_QUESTION: GROUP_QUESTION_SELECTOR,
                                        groupQuestionByIds,
                                    },
                                };
                            })(),
                        CHILD_DATA_GATING_QUESTION_GROUP: {
                            ROOT: tidSelector(CHILD_DATA_GATING_QUESTION_GROUP.ROOT),
                            HEADER: tidSelector(CHILD_DATA_GATING_QUESTION_GROUP.HEADER),
                            CONTENT: tidSelector(CHILD_DATA_GATING_QUESTION_GROUP.CONTENT),
                        },
                    };
                })(),
            SUMMARY_REVIEW_STEP: {
                ROOT: tidSelector(SUMMARY_REVIEW_STEP.ROOT),
                NAME: tidSelector(SUMMARY_REVIEW_STEP.NAME),
                DESCRIPTION: tidSelector(SUMMARY_REVIEW_STEP.DESCRIPTION),
                TECHNOLOGY: tidSelector(SUMMARY_REVIEW_STEP.TECHNOLOGY),
                INSTANCES: tidSelector(SUMMARY_REVIEW_STEP.INSTANCES),
                QUESTION_BLOCK: tidSelector(SUMMARY_REVIEW_STEP.QUESTION_BLOCK),
                ALERT_CONTAINER: tidSelector(SUMMARY_REVIEW_STEP.ALERT_CONTAINER),
                PRIVACY_ALERT_CONTAINER: tidSelector(SUMMARY_REVIEW_STEP.PRIVACY_ALERT_CONTAINER),
            },
        },
        DATA_STORE_TABLE: {
            ACTIONS: {
                ROOT: tidSelector(DATA_STORE_TABLE.ACTIONS.ROOT),
                ADD_DATA_STORE_BUTTON: tidSelector(DATA_STORE_TABLE.ACTIONS.ADD_DATA_STORE_BUTTON),
                EDIT_DATA_STORE_BUTTON: tidSelector(DATA_STORE_TABLE.ACTIONS.EDIT_DATA_STORE_BUTTON),
                DELETE_DATA_STORE: {
                    MODAL_TRIGGER_BUTTON: tidSelector(DELETE_DATA_STORE.MODAL_TRIGGER_BUTTON),
                    MODAL_CONFIRM_BUTTON: tidSelector(DELETE_DATA_STORE.MODAL_CONFIRM_BUTTON),
                    MODAL_CANCEL_BUTTON: tidSelector(DELETE_DATA_STORE.MODAL_CONFIRM_BUTTON),
                    MODAL_ROOT: tidSelector(DELETE_DATA_STORE.MODAL_ROOT),
                },
            },
        },
        SCHEMA_TABLE: {
            ACTIONS: {
                DELETE_TABLES: {
                    MODAL_TRIGGER_BUTTON: tidSelector(DELETE_TABLES.MODAL_TRIGGER_BUTTON),
                    MODAL_CONFIRM_BUTTON: tidSelector(DELETE_TABLES.MODAL_CONFIRM_BUTTON),
                    MODAL_CANCEL_BUTTON: tidSelector(DELETE_TABLES.MODAL_CANCEL_BUTTON),
                    MODAL_ROOT: tidSelector(DELETE_TABLES.MODAL_ROOT),
                },
                BULK_EDIT: {
                    MODAL_TRIGGER_BUTTON: tidSelector(BULK_EDIT.MODAL_TRIGGER_BUTTON),
                    MODAL_CONFIRM_BUTTON: tidSelector(BULK_EDIT.MODAL_CONFIRM_BUTTON),
                    MODAL_CANCEL_BUTTON: tidSelector(BULK_EDIT.MODAL_CANCEL_BUTTON),
                    MODAL_ROOT: tidSelector(BULK_EDIT.MODAL_ROOT),
                },

                TRANSFER_TABLES: {
                    MODAL_TRIGGER_BUTTON: tidSelector(TRANSFER_TABLES.MODAL_TRIGGER_BUTTON),
                    MODAL_CONFIRM_BUTTON: tidSelector(TRANSFER_TABLES.MODAL_CONFIRM_BUTTON),
                    MODAL_CANCEL_BUTTON: tidSelector(TRANSFER_TABLES.MODAL_CANCEL_BUTTON),
                    MODAL_ROOT: tidSelector(TRANSFER_TABLES.MODAL_ROOT),
                },
                CSV: {
                    EXPORT_TABLES: {
                        MODAL_CONFIRM_BUTTON: tidSelector(EXPORT_TABLES.MODAL_CONFIRM_BUTTON),
                        MODAL_CANCEL_BUTTON: tidSelector(EXPORT_TABLES.MODAL_CANCEL_BUTTON),
                        MODAL_ROOT: tidSelector(EXPORT_TABLES.MODAL_ROOT),
                    },
                    IMPORT_TABLES: {
                        MODAL_CONFIRM_BUTTON: tidSelector(IMPORT_TABLES.MODAL_CONFIRM_BUTTON),
                        MODAL_CANCEL_BUTTON: tidSelector(IMPORT_TABLES.MODAL_CANCEL_BUTTON),
                        MODAL_ROOT: tidSelector(IMPORT_TABLES.MODAL_ROOT),
                    },
                },
                ADD_TABLE: {
                    CREATE_TABLE: {
                        MODAL_CONFIRM_BUTTON: tidSelector(CREATE_TABLE.MODAL_CONFIRM_BUTTON),
                        MODAL_CANCEL_BUTTON: tidSelector(CREATE_TABLE.MODAL_CANCEL_BUTTON),
                        MODAL_ROOT: tidSelector(CREATE_TABLE.MODAL_ROOT),
                    },
                },
            },
        },
    },

    RECALL_IN_PROGRESS: {
        MESSAGE: tidSelector(RECALL_IN_PROGRESS.MESSAGE),
        OPEN_READ_ONLY_BUTTON: tidSelector(RECALL_IN_PROGRESS.OPEN_READ_ONLY_BUTTON),
    },

    SUBMIT_IN_PROGRESS: {
        MODAL: tidSelector(SUBMIT_IN_PROGRESS.MODAL),
        MESSAGE: tidSelector(SUBMIT_IN_PROGRESS.MESSAGE),
    },

    FORM_ACTIONS: {
        SUBMIT: {
            MODAL_TRIGGER_BUTTON: tidSelector(FORM_ACTIONS.SUBMIT.MODAL_TRIGGER_BUTTON),
            MODAL_CONFIRM_BUTTON: tidSelector(FORM_ACTIONS.SUBMIT.MODAL_CONFIRM_BUTTON),
            MODAL_CANCEL_BUTTON: tidSelector(FORM_ACTIONS.SUBMIT.MODAL_CONFIRM_BUTTON),
            MODAL_ROOT: tidSelector(FORM_ACTIONS.SUBMIT.MODAL_ROOT),
        },
        DELETE: {
            MODAL_TRIGGER_BUTTON: tidSelector(FORM_ACTIONS.DELETE.MODAL_TRIGGER_BUTTON),
            MODAL_CONFIRM_BUTTON: tidSelector(FORM_ACTIONS.DELETE.MODAL_CONFIRM_BUTTON),
            MODAL_CANCEL_BUTTON: tidSelector(FORM_ACTIONS.DELETE.MODAL_CONFIRM_BUTTON),
            MODAL_ROOT: tidSelector(FORM_ACTIONS.DELETE.MODAL_ROOT),
        },
    },

    AEDU_SANDFIRE: {
        TABLE: tidSelector(AEDU_SANDFIRE.TABLE),
        ERROR: tidSelector(AEDU_SANDFIRE.ERROR),
    },
};
