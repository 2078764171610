import { Alert, Box, Link, Popover, SelectProps, SpaceBetween, TableProps } from "@amzn/awsui-components-react-v3";
import { PersonalDataWizardStep } from "src/components/survey/PersonalDataWizard/steps/index";
import { WizardProps } from "src/components/InlineWizard/interfaces";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
    ClassificationScore,
    FoundEntityType,
    PDCFinding,
    PDCPlatformType,
} from "src/services/NodeKaleApplicationService";
import KaleContext from "src/components/KaleContext";
import DASTable from "src/components/fields/table/DASTable";
import { useAppParamsFromRoute } from "src/components/survey/hooks/useAppParamsFromRoute";
import { TEST_IDS } from "shared/survey/personalDataWizard";
import { PERSONAL_DATA_CLASSIFIER_FINDINGS_TITLE } from "../content";
import useDriftDetectionCase, {
    DriftCase,
} from "src/components/survey/LegalSurveyActionButtons/DriftDetection/useDriftDetectionCase";

const { PDC_TABLE } = TEST_IDS.WIZARD.PDC_STEP;
const { ENTITY_TYPE } = PDC_TABLE;

interface Props {
    /**
     * List representing the combined set of the applications control bindle and all related bindles.
     * [{label: <bindle name>, value: <bindle id (from fetchBindleByOwner() API)>}].
     * PDC API would require bindle ids as input and so we use "value" from this props
     */
    requestBindles: SelectProps.Option[];
}

interface Response extends PersonalDataWizardStep {
    pdcFindings: PDCFinding[];
}

// This the value that the PDC team has asked us to use to determine when a PDC finding is considered high confidence.
// In the future we may receive this value from a PDC api instead.
export const PDC_HIGH_CONFIDENCE_THRESHOLD: ClassificationScore = 1;
const isHighConfidenceFinding = (confidenceScore: ClassificationScore) =>
    confidenceScore >= PDC_HIGH_CONFIDENCE_THRESHOLD;

export const usePersonalDataClassifierStep = ({ requestBindles }: Props): Response => {
    const kaleContext = useContext(KaleContext);
    const {
        config: { personalDataClassifierHost },
    } = kaleContext;

    const bindleValues = useMemo(
        () =>
            requestBindles.reduce<string[]>((values, { value: optionValue }) => {
                if (optionValue) {
                    values.push(optionValue);
                }
                return values;
            }, []),
        [requestBindles]
    );

    const { pdcFindings, error, isLoading } = usePDCFindings(bindleValues);

    const columnDefinitions: TableProps.ColumnDefinition<PDCFinding>[] = [
        { id: "platformType", header: "Platform Type", cell: (e: PDCFinding): string => e.platformType },
        {
            id: "resourceGroupId",
            header: "Resource Group ID",
            cell: (e: PDCFinding): React.ReactNode => {
                const resourceGroupIdLink =
                    e.platformType === PDCPlatformType.Aws
                        ? `https://conduit.security.a2z.com/accounts/aws/${e.resourceGroupId}/attributes`
                        : `https://datacentral.a2z.com/hoot/providers/${e.resourceGroupId}`;

                return (
                    <Link external={true} href={resourceGroupIdLink}>
                        {e.resourceGroupId}
                    </Link>
                );
            },
        },
        {
            id: "storageResourceName",
            header: "Storage Resource Name",
            cell: (e: PDCFinding): React.ReactNode => {
                const tableVersion = e.storageResourceConfiguration.andesTableConfiguration?.tableVersion;
                const version = Boolean(tableVersion) ? `v${tableVersion}` : "";
                const andesTableLink =
                    `https://datacentral.a2z.com/hoot/providers/${e.resourceGroupId}` +
                    `/tables/${e.storageResourceName}/versions/${tableVersion}`;
                const storageResourceDetails = `${e.storageResourceName} ${version}`;

                return e.platformType === PDCPlatformType.Andes ? (
                    <Link external={true} href={andesTableLink}>
                        {storageResourceDetails}
                    </Link>
                ) : (
                    e.storageResourceName
                );
            },
        },
        {
            id: "type",
            header: "Type",
            cell: (e: PDCFinding): string => (e.platformType === PDCPlatformType.Aws ? e.storageSystem : "Andes Table"),
        },
        {
            id: "foundEntityTypes",
            header: "Found Entity Types",
            cell: (e: PDCFinding): React.ReactNode => <PDCEntityTypes entityTypes={e.foundEntityTypes} />,
        },
        {
            id: "view",
            header: "Review / Provide Feedback",
            cell: (e: PDCFinding): React.ReactNode => {
                const url = new URL(`${personalDataClassifierHost}/consolidated/view`);
                url.searchParams.append("resourceGroupId", e.resourceGroupId);
                url.searchParams.append("platformType", e.platformType);
                url.searchParams.append("region", e.region);
                url.searchParams.append("storageResourceName", e.storageResourceName);
                url.searchParams.append("storageSystem", e.storageSystem);
                if (e.platformType === PDCPlatformType.Andes) {
                    url.searchParams.append(
                        "tableVersion",
                        e.storageResourceConfiguration.andesTableConfiguration?.tableVersion?.toString() ?? ""
                    );
                }
                return (
                    <Link external={true} href={url.href}>
                        View
                    </Link>
                );
            },
        },
    ];
    const step: WizardProps.Step = {
        title: PERSONAL_DATA_CLASSIFIER_FINDINGS_TITLE,
        infoCount: pdcFindings.length,
        description: (
            <Box variant={"small"}>
                Data store technologies are periodically scanned to identify instances of personal data. These results
                indicate that the resources used by your application may process personal data. Learn more about our
                Personal Data Classifier technology by visiting our{" "}
                <Link
                    variant={"info"}
                    external={true}
                    href="https://w.amazon.com/bin/view/PrivacyEngineering/Product/DataClassifier"
                >
                    wiki.
                </Link>{" "}
                To view additional details of the findings or to provide feedback, please click on the links below to be
                directed to the Personal Data Classifier Portal.
            </Box>
        ),
        content: (
            <SpaceBetween direction="vertical" size="l">
                {pdcFindings.length !== 0 && (
                    <Alert type="warning">
                        Based on the resources associated to your Bindle(s), we have detected the following instances of
                        personal data in your application.
                    </Alert>
                )}
                <DASTable
                    data-testid={TEST_IDS.WIZARD.PDC_STEP.PDC_TABLE.ROOT}
                    id={"pdc-findings-table"}
                    tableName={"Connected resources that process personal data"}
                    isLoading={isLoading}
                    hidePreferences={true}
                    columnDefinitions={columnDefinitions}
                    rowItems={pdcFindings}
                    emptyTextPrefix={"No resources"}
                />
            </SpaceBetween>
        ),
    };

    return { isLoading, step, pdcFindings, error };
};

type FetchPDCFindingsResult = { pdcFindings: PDCFinding[]; isLoading: boolean; error: string };
const useFetchPDCFindings = (requestBindles: string[]): FetchPDCFindingsResult => {
    const kaleContext = useContext(KaleContext);
    const {
        service: {
            nodeKaleAppService: { fetchPDCFindings },
        },
    } = kaleContext;

    const { applicationName } = useAppParamsFromRoute();

    const [pdcFindings, setPDCFindings] = useState<PDCFinding[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const deps = { fetchPDCFindings };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    useEffect((): void => {
        const { fetchPDCFindings } = dependenciesRef.current;

        if (!requestBindles.length) {
            return;
        }

        (async function IIFE(): Promise<void> {
            setIsLoading(true);
            setError("");
            let pdcFindings: PDCFinding[] = [];
            try {
                pdcFindings = await fetchPDCFindings(applicationName, requestBindles);
            } catch (e) {
                setError((e as Error).message);
            } finally {
                setIsLoading(false);
            }
            setPDCFindings(pdcFindings);
        })();
    }, [applicationName, requestBindles]);

    return { pdcFindings, error, isLoading };
};

/**
 * Custom hook is responsible for fetching PDC findings and determining which of those should be rendered into the UI
 */
const usePDCFindings = (requestBindles: string[]): FetchPDCFindingsResult => {
    const { pdcFindings, ...restOfResult } = useFetchPDCFindings(requestBindles);
    const highConfidenceFindings = useMemo(
        () =>
            pdcFindings.filter((finding) =>
                isHighConfidenceFinding(finding.highestClassificationScoreAcrossAllEntities)
            ),
        [pdcFindings]
    );

    const campaignType = useDriftDetectionCase();
    const pdcFindingsToRender = campaignType === DriftCase.REAFFIRM ? highConfidenceFindings : pdcFindings;

    return { ...restOfResult, pdcFindings: pdcFindingsToRender };
};

type PDCEntityTypesProps = { entityTypes: FoundEntityType[] };
/** Component is responsible for rendering a list of FoundEntityTypes */
const PDCEntityTypes = ({ entityTypes }: PDCEntityTypesProps): JSX.Element => {
    const campaignType = useDriftDetectionCase();
    const includeLowConfidenceResults = campaignType !== DriftCase.REAFFIRM;

    return (
        <SpaceBetween size={"xxs"} direction={"vertical"}>
            {entityTypes.reduce<JSX.Element[]>(function renderEntityTypes(renderList, entity, index): JSX.Element[] {
                const confidenceScore = entity.highestClassificationScore;
                const isHighConfidence = isHighConfidenceFinding(confidenceScore);
                const shouldRender = includeLowConfidenceResults || isHighConfidence;

                if (shouldRender) {
                    renderList.push(
                        <EntityType
                            key={index}
                            entityTypeDerName={entity.entityTypeIdentifier.derName}
                            confidenceScore={confidenceScore}
                        />
                    );
                }
                return renderList;
            }, [])}
        </SpaceBetween>
    );
};

interface EntityTypeProps {
    entityTypeDerName: string;
    confidenceScore: ClassificationScore;
}

const confidenceScoreToFixedPercentage = (confidenceScore: ClassificationScore, fractionDigits?: number): string => {
    const fixedPercentage = (confidenceScore * 100).toFixed(fractionDigits);
    return `${fixedPercentage}%`;
};
const EntityType = ({ entityTypeDerName, confidenceScore }: EntityTypeProps): JSX.Element => {
    return (
        <span data-testid={ENTITY_TYPE.ROOT} data-entity-dername={entityTypeDerName}>
            <Popover
                size="large"
                position="top"
                triggerType="text"
                dismissButton={false}
                content={`Confidence: ${confidenceScoreToFixedPercentage(confidenceScore, 2)}`}
            >
                <SpaceBetween data-testid={ENTITY_TYPE.LABEL} size={"xxs"} direction={"horizontal"}>
                    {entityTypeDerName} {confidenceScoreToFixedPercentage(confidenceScore)}
                </SpaceBetween>
            </Popover>
        </span>
    );
};
