import React from "react";
import { Box } from "@amzn/awsui-components-react-v3";
import { Container, FormField, Header } from "@amzn/awsui-components-react-v3/polaris";
import {
    Importer,
    IntelligentImporter,
} from "src/components/schema_table/importers/schema_importer/IntelligentImporter";
import styled from "styled-components";

export const TEST_IDS = {
    CONTAINER: "schema-importer-help-container",
};

const StyledContainer = styled.pre`
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    background-color: white;
    color: black;
`;

const SchemaImporterHelp = (): JSX.Element => {
    return (
        <React.Fragment>
            <br />
            <br />
            <Container header={<Header variant={"h1"}>Supported Databases and Samples</Header>}>
                {new IntelligentImporter().getSupportedImporters().map((importer: Importer, i): JSX.Element => {
                    return (
                        <Container
                            data-testid={`${TEST_IDS.CONTAINER}-${i}`}
                            key={importer.constructor.name + "Samples"}
                            header={
                                <Header
                                    variant={"h2"}
                                    description={
                                        importer.hasDocLink() && <a href={importer.getDocLink()}>Documentation</a>
                                    }
                                >
                                    {importer.getSource()}
                                </Header>
                            }
                        >
                            {importer.getCommand() && (
                                <FormField
                                    key={importer.constructor.name + "Command"}
                                    label={<Box variant="strong">Command: </Box>}
                                >
                                    {importer.getCommand()}
                                </FormField>
                            )}
                            <React.Fragment>
                                {importer.getSamples().map(
                                    (sample: string, i: number): JSX.Element => (
                                        <FormField
                                            key={`${importer.constructor.name}Sample_${i}`}
                                            label={<Box variant="strong">Sample {i + 1}:</Box>}
                                        >
                                            <StyledContainer>{sample}</StyledContainer>
                                        </FormField>
                                    )
                                )}
                            </React.Fragment>
                        </Container>
                    );
                })}
            </Container>
        </React.Fragment>
    );
};

export default SchemaImporterHelp;
