import { ColumnLayout, Input, Link, Select, SelectProps, SpaceBetween } from "@amzn/awsui-components-react-v3";
import React, { useState } from "react";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import Model from "src/components/constants/Constants";
import { applyTemplateToText } from "src/components/fields/textbox/TextBoxHelper";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { TEST_IDS } from "shared/fields";
import { ANVIL_OPTION, AnvilIdStatus, defaultAnvilOptions } from "src/components/survey/ApplicationPermissions/utils";
import { useBindAnvil } from "src/components/survey/ApplicationPermissions/useBindAnvil";
import { AnvilStatus } from "src/components/survey/ApplicationPermissions/AnvilStatus";
import { KaleFormField } from "src/components/fields/KaleFormField";

export interface AnvilIdInputProps extends FunctionalSurveyItemProps<string> {
    readonly anvilIds: SelectProps.Option[];
    readonly status: AnvilIdStatus;
    readonly applicationName?: string;
    readonly errorMessage?: React.ReactNode;
    readonly owner: string;
    readonly taskId: string;
}

const AnvilIdInput = (props: AnvilIdInputProps): JSX.Element => {
    const data = Model.getData(props.id);
    const readonly = props.status === AnvilIdStatus.loading || !!props.isReadonly || !!props.isFormInReadonlyMode;

    const anvilChangeEffect = useBindAnvil(props);

    const label = data.name;
    const description = applyTemplateToText(data.question, data.templateValues);
    const errorText = props.status === AnvilIdStatus.invalid && props.errorMessage;
    const [filteredOptions, setFilteredOptions] = useState<SelectProps.Option[]>(anvilChangeEffect.options);

    const onLoad = (event: any): void => {
        const newFilteredOptions = anvilChangeEffect.options.filter((option: SelectProps.Option): boolean => {
            const value = event.detail.filteringText;
            return option.label?.includes(value) || !!option.description?.includes(value);
        });
        if (newFilteredOptions.length) {
            setFilteredOptions(
                anvilChangeEffect.options.filter((option: SelectProps.Option): boolean => {
                    const value = event.detail.filteringText;
                    return option.label?.includes(value) || !!option.description?.includes(value);
                })
            );
        } else {
            setFilteredOptions(defaultAnvilOptions);
        }
    };

    const onChange = (event: any): void => {
        anvilChangeEffect.setSelectedOption(event.detail.selectedOption);
        if (event.detail.selectedOption.value !== ANVIL_OPTION.OTHER.value) {
            anvilChangeEffect.setCustomValue("");
        }
    };
    const { ANVIL_ID } = TEST_IDS;

    return (
        <SpaceBetween size={"xxxs"}>
            <KaleFormField label={label} description={description} stretch={false} errorText={errorText}>
                <Select
                    id={props.id}
                    placeholder="Choose an option"
                    options={filteredOptions}
                    selectedOption={anvilChangeEffect.selectedOption}
                    disabled={readonly}
                    onChange={onChange}
                    filteringType="manual"
                    onLoadItems={onLoad}
                />
                {anvilChangeEffect.isCustom && (
                    <Input
                        data-testid={ANVIL_ID.INPUT_TESTID}
                        readOnly={readonly}
                        disabled={readonly}
                        value={anvilChangeEffect.customValue}
                        onChange={(event): void => {
                            anvilChangeEffect.setCustomValue(event.detail.value);
                        }}
                    />
                )}
                <AnvilStatus {...props} />
            </KaleFormField>

            <ColumnLayout variant={"text-grid"} columns={2}>
                <div>
                    Owner :
                    <Link external={true} href={`https://phonetool.amazon.com/users/${props.owner}`}>
                        {props.owner}
                    </Link>
                </div>
                <div>TaskId: {props.taskId}</div>
            </ColumnLayout>
        </SpaceBetween>
    );
};

export const AnvilIdInputWithFunctionalReadonly = withFunctionalReadonly(AnvilIdInput);
