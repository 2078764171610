import React, { useMemo } from "react";
import { FrontEndComment } from "src/components/CommentsView/state/model";
import { TEST_IDS } from "shared/comments-view";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import styled from "styled-components";
import {
    COMMENT_HEADER_BOTTOM_PADDING,
    COMMENT_HEADER_CONTENT_HEIGHT,
    COMMENT_HEADER_TOP_PADDING,
} from "src/components/CommentsView/constants";

export const ChevronIconSpacer = styled.div`
    width: 16px;
    padding-top: 5px;
    padding-left: px;
`;

export const Avatar = styled.div`
    height: 30px;
    width: 30px;
    background-color: #d5dbdb;
    border-radius: 50%;
    margin: 0 auto;
`;

export const AvatarContainer = styled.span`
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
`;

export const RelativeDate = styled.small`
    margin-right: -20px !important;
`;

// Style Rules to force text rows to overflow with ellipsis
export const FlexibleTextRows = styled.div`
    & * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const FullName = styled.div`
    display: block;
    font-weight: bold;
`;

export const CommentHeaderWrapper = styled.div`
    position: relative;
    padding: ${COMMENT_HEADER_TOP_PADDING}px 20px ${COMMENT_HEADER_BOTTOM_PADDING}px 0;
    box-sizing: content-box !important;
    height: ${COMMENT_HEADER_CONTENT_HEIGHT}px;
`;

// Flexbox style rules for Comment Header Layout
export const HeaderLayout = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    & ${FlexibleTextRows} {
        flex: auto;
        min-width: 0;
    }

    & ${AvatarContainer}, & ${RelativeDate}, & ${ChevronIconSpacer} {
        flex: none;
    }

    // Create space around every immediate child
    & > * {
        margin-left: 8px !important;
    }
`;

// Create Relative date formatter (English).
TimeAgo.locale(en);
const timeAgo = new TimeAgo("en-US");

const {
    COMMENT: { COMMENT_HEADER },
} = TEST_IDS.COMMENTS_VIEW;

export interface HeadCommentFragments {
    chevronIcon: JSX.Element;
    messagePreview: JSX.Element;
}

export interface CommentHeaderProps {
    commentData: FrontEndComment;
    headCommentFragments: HeadCommentFragments | null;
}

const CommentHeader = React.memo(({ commentData, headCommentFragments }: CommentHeaderProps): JSX.Element => {
    const { createdAt, firstName, lastName } = commentData;
    const { chevronIcon, messagePreview } = headCommentFragments || {};

    const relativeDate = useMemo((): string => {
        const date = new Date(createdAt);
        return timeAgo.format(date, "mini-minute-now");
    }, [createdAt]);

    return (
        <CommentHeaderWrapper data-testid={COMMENT_HEADER}>
            <HeaderLayout>
                <ChevronIconSpacer>{chevronIcon}</ChevronIconSpacer>
                <AvatarContainer>
                    <Avatar>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</Avatar>
                </AvatarContainer>
                <FlexibleTextRows>
                    <FullName>
                        {firstName} {lastName}
                    </FullName>
                    {messagePreview}
                </FlexibleTextRows>
                <RelativeDate>{relativeDate}</RelativeDate>
            </HeaderLayout>
        </CommentHeaderWrapper>
    );
});

export default CommentHeader;
