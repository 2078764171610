/* istanbul ignore file */
// Skipping this file from code coverage of unit-test (jest).

import React from "react";
import { ReviewAnswer } from "src/answers_legacy";
import { UserRole } from "src/permissions";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { BindleResource } from "src/services/KaleApplicationService";
import { HistoricalTAFDecision } from "src/components/TAF/TAFDetails/components/DataStoreDecisions";
import { ValidationResponse } from "src/components/survey/hooks/useApplicationValidation";
import { SelectProps } from "@amzn/awsui-components-react-v3";

export enum ApplicationStatus {
    inProgress = "In Progress",
    inReview = "In Review",
    approved = "Approved",
    notApplicable = "Not Applicable",
    notAnalyzed = "Not Analyzed",
    recallInProgress = "Recall In Progress",
    calculating = "Calculating",
    archived = "Archived",
}

export enum Flag {
    accounting = "Accounting",
    ctps = "CTPS",
}

export interface AppInfoResponse {
    applicationName: string;
    isConfidential: boolean;
    controlBindle: string[];
    appUuid: string;
    metaDataCreationDate: string;
    metaDataLastUpdate: string;
    review: ReviewResponse;
}

export enum HasPersonalDataResponse {
    Empty = "",
    Store = "Store",
    Process = "Process",
    No = "No",
}

export interface ReviewResponse {
    aaaIdentifier: string[];
    anvilId: string;
    applicationDescription: string;
    applicationId: string;
    applicationOwner: string;
    approvalStatuses: ApprovalStatus[];
    assignedReviewGroups?: string[];
    bindleIds: string;
    dataStores: DataStoreResponse[];
    definedExplanation: string;
    dgrResources: BindleResource[];
    flags?: Flag[];
    hasPersonalData: HasPersonalDataResponse;
    historicalFinancialDecisions: HistoricalTAFDecision[];
    id: number;
    legalPocAlias: string;
    metaDataLastUpdate: string;
    relatedBindles: string[];
    respondentAlias: string;
    createdBy: string;
    contactList: string[];
    reviewAnswers: ReviewAnswer[];
    reviewGroup: string;
    taskId: string;
    willingToProvideInfo: string;
    isFoundationalApp: string;
    deletion_onboarding_approved: DSARDeletionApprovedStatus;
    dsar_onboarding_approved: DSARDeletionApprovedStatus;
}

export enum DSARDeletionApprovedStatus {
    Yes = "Yes",
    No = "No",
}

export enum ApprovalType {
    PrivacyApproval = "Legal Privacy Review",
    AccountingApproval = "Accounting Privacy Review",
    FraudApproval = "CTPS Privacy Review",
    TaxApproval = "Tax Privacy Review",
}

export interface ApprovalStatus {
    id: number;
    reviewId: number;
    status: ApplicationStatus;
    approvedOn: string;
    approverAlias: string;
    type: ApprovalType;
}

export const getApproval = (appInfo: AppInfoResponse, type: ApprovalType): ApprovalStatus => {
    const status = appInfo.review.approvalStatuses.find((approval: ApprovalStatus): boolean => approval.type == type);
    return (
        status || {
            id: 0,
            reviewId: 0,
            status: ApplicationStatus.notApplicable,
            approvedOn: "",
            approverAlias: "",
            type: type,
        }
    );
};

export const selectLegalApprovalStatus = (appInfo: AppInfoResponse): ApprovalStatus => {
    return getApproval(appInfo, ApprovalType.PrivacyApproval);
};

export const selectAccountingApprovalStatus = (appInfo: AppInfoResponse): ApprovalStatus => {
    return getApproval(appInfo, ApprovalType.AccountingApproval);
};

export const selectFraudApprovalStatus = (appInfo: AppInfoResponse): ApprovalStatus => {
    return getApproval(appInfo, ApprovalType.FraudApproval);
};

export const setLegalApprovalInfo = (
    appInfo: AppInfoResponse,
    newStatus?: ApplicationStatus,
    newDate?: string,
    newAlias?: string
): AppInfoResponse => {
    const legalStatusIndex = appInfo.review.approvalStatuses.findIndex(
        (approval: ApprovalStatus): boolean => approval.type == ApprovalType.PrivacyApproval
    );
    if (legalStatusIndex < 0) {
        return appInfo;
    }
    let approvalStatus = { ...appInfo.review.approvalStatuses[legalStatusIndex] };
    approvalStatus = {
        ...approvalStatus,
        status: newStatus || approvalStatus.status,
        approverAlias: newAlias || approvalStatus.approverAlias,
        approvedOn: newDate || approvalStatus.approvedOn,
    };
    const approvalStatuses = [
        ...appInfo.review.approvalStatuses.slice(0, legalStatusIndex),
        approvalStatus,
        ...appInfo.review.approvalStatuses.slice(legalStatusIndex + 1),
    ];
    return { ...appInfo, review: { ...appInfo.review, approvalStatuses } };
};

export interface SurveyResponse {
    appInfo: AppInfoResponse;
}

export const DefaultAppInfo = (): AppInfoResponse => {
    return {
        applicationName: "",
        isConfidential: false,
        appUuid: "",
        controlBindle: [],
        metaDataCreationDate: "",
        metaDataLastUpdate: "",
        review: {
            id: 0,
            applicationDescription: "",
            applicationId: "",
            bindleIds: "",
            respondentAlias: "",
            createdBy: "",
            contactList: [],
            taskId: "",
            applicationOwner: "testOwner",
            legalPocAlias: "",
            relatedBindles: [],
            anvilId: "",
            aaaIdentifier: [],
            definedExplanation: "",
            hasPersonalData: HasPersonalDataResponse.Empty,
            willingToProvideInfo: "",
            metaDataLastUpdate: "",
            reviewGroup: "",
            isFoundationalApp: "",
            dgrResources: [],
            approvalStatuses: DefaultApprovalStatus(),
            reviewAnswers: [],
            dataStores: [],
            historicalFinancialDecisions: [],
            deletion_onboarding_approved: DSARDeletionApprovedStatus.No,
            dsar_onboarding_approved: DSARDeletionApprovedStatus.No,
        },
    };
};

export const DefaultApprovalStatus = (status?: ApplicationStatus, type?: ApprovalType): ApprovalStatus[] => {
    const defaultAppStatus: ApprovalStatus = {
        id: 0,
        reviewId: 0,
        status: status || ApplicationStatus.inProgress,
        approvedOn: "",
        approverAlias: "",
        type: type || ApprovalType.PrivacyApproval,
    };
    return [defaultAppStatus];
};

export const DefaultSurveyResponse = (): SurveyResponse => {
    return {
        appInfo: DefaultAppInfo(),
    };
};

export const DEFAULT_VALIDATION: ValidationResponse = {
    submitStatus: {
        isValid: false,
        errorMessage: "",
    },
    saveStatus: {
        isValid: false,
        errorMessage: "",
    },
    appNameErrMsg: "",
    applicationPermissionsSectionErrorMsg: "",
};

export type UpdateBulkEditPanelCb = (tableIds: number[], dataStore: DataStoreResponse) => void;

export interface SurveyContextProps {
    application: SurveyResponse;
    role: UserRole;
    controlBindle: SelectProps.Option | null;
    setControlBindle: (
        value: ((prevState: SelectProps.Option | null) => SelectProps.Option | null) | SelectProps.Option | null
    ) => void;
    saveDraft: (status?: ApplicationStatus) => Promise<SurveyResponse>;
    updateBulkEditPanel: UpdateBulkEditPanelCb;
    validation: ValidationResponse;
}

export const defaultSaveDraftCb = (): Promise<SurveyResponse> => Promise.resolve(DefaultSurveyResponse());

export const defaultSurveyContextValue = {
    application: DefaultSurveyResponse(),
    role: "readOnly" as UserRole,
    controlBindle: null,
    setControlBindle: (): void => {},
    saveDraft: defaultSaveDraftCb,

    updateBulkEditPanel: (): void => {},
    validation: DEFAULT_VALIDATION,
};

export const SurveyContext = React.createContext<SurveyContextProps>(defaultSurveyContextValue);

export const SurveyProvider = SurveyContext.Provider;
export const SurveyConsumer = SurveyContext.Consumer;
