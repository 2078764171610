import { AnvilIdStatus, GreenText, GreyText } from "src/components/survey/ApplicationPermissions/utils";
import React from "react";
import { AnvilIdInputProps } from "src/components/survey/ApplicationPermissions/AnvilIdInput";
import { TEST_IDS } from "shared/fields";
import { Box, Spinner } from "@amzn/awsui-components-react-v3";

export const AnvilStatus = (props: AnvilIdInputProps): JSX.Element | null => {
    switch (props.status) {
        case AnvilIdStatus.loading:
            return <AnvilIdLoading />;
        case AnvilIdStatus.linked:
            return <AnvilIdLinked {...props} />;
        case AnvilIdStatus.valid:
            return <AnvilIdValid {...props} />;
    }
    return null;
};

export const AnvilIdLinked = (props: AnvilIdInputProps): JSX.Element => {
    const { ANVIL_ID } = TEST_IDS;

    return (
        <GreenText>
            <Box variant="p" data-testid={ANVIL_ID.LINKED_TESTID}>
                (Anvil Application Linked: &quot;{props.applicationName}&quot;)
            </Box>
        </GreenText>
    );
};
export const AnvilIdLoading = (): JSX.Element => {
    const { ANVIL_ID } = TEST_IDS;

    return (
        <GreyText>
            <Box variant="p" data-testid={ANVIL_ID.LOADING_TESTID}>
                (Anvil Application Linking
                <Spinner />)
            </Box>
        </GreyText>
    );
};

export const AnvilIdValid = (props: AnvilIdInputProps): JSX.Element => {
    const { ANVIL_ID } = TEST_IDS;
    return (
        <GreyText>
            <Box variant="p" data-testid={ANVIL_ID.VALID_TESTID}>
                (Valid Application ID: &quot;{props.applicationName}&quot;)
            </Box>
        </GreyText>
    );
};
