import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import { StyledSpan } from "src/components/ApprovalStatuses/utils";
import { HelpIcon } from "src/components/HelpIcon";
import React from "react";

export interface DescriptionWithInfoLinkProps {
    displayText: React.ReactNode;
    content: React.ReactNode;
}

export const DescriptionWithInfoLink = (props: DescriptionWithInfoLinkProps): JSX.Element => {
    return (
        <SpaceBetween direction={"horizontal"} size={"xxs"}>
            <StyledSpan>{props.displayText}</StyledSpan>
            <HelpIcon content={props.content}></HelpIcon>
        </SpaceBetween>
    );
};
