import React, { useState } from "react";
import styled from "styled-components";
import { groupBy, padStart } from "lodash";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    Container,
    FormField,
    Grid,
    Header,
    Select,
    SpaceBetween,
    Table,
    TableProps,
    SelectProps,
} from "@amzn/awsui-components-react-v3";
import { OptionDefinition } from "@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces";

export interface HistoricalTAFDecision {
    reviewGroupName: string;
    dataStoreName: string;
    decision: string;
    id: number;
    groupID: string;
    recallDate: string;
}

interface Props {
    decisions: HistoricalTAFDecision[];
}

interface TafDecisionItem {
    reviewGroupName: string;
    [dataStoreName: string]: string;
}

interface FinalTafDecisionItem {
    dataStoreName: string;
    accountingDecision: string;
    ctpsDecision: string;
    taxDecision: string;
}

const StyledContainer = styled(Container)`
    box-shadow: none !important;
`;

const RightCol = styled.div`
    right: 0;
    position: absolute;
    margin-right: 1em;
`;

const DECISION_SEARCH: any = {
    ACCOUNTING: "Accounting Final Decision",
    CTPS: "CTPS Final Decision",
    TAX: "Tax Final Decision",
};

export const isAccountingDecision = (decision: HistoricalTAFDecision): boolean => {
    return new RegExp(DECISION_SEARCH.ACCOUNTING, "gi").test(decision.reviewGroupName);
};

export const isCTPSDecision = (decision: HistoricalTAFDecision): boolean => {
    return new RegExp(DECISION_SEARCH.CTPS, "gi").test(decision.reviewGroupName);
};

export const isTaxDecision = (decision: HistoricalTAFDecision): boolean => {
    return new RegExp(DECISION_SEARCH.TAX, "gi").test(decision.reviewGroupName);
};

export const isAnyDecision = (decision: HistoricalTAFDecision): boolean => {
    return isAccountingDecision(decision) || isCTPSDecision(decision) || isTaxDecision(decision);
};

const makeTafDecisionsColumnDefinitions = (
    decisions: HistoricalTAFDecision[]
): TableProps.ColumnDefinition<TafDecisionItem>[] => {
    const columnDefinitions: TableProps.ColumnDefinition<TafDecisionItem>[] = [
        {
            id: "reviewGroupName",
            header: "Reviewer Group Name",
            cell: (e): JSX.Element => (
                <a target="_blank" rel="noreferrer" href={`https://permissions.amazon.com/a/team/${e.reviewGroupName}`}>
                    {e.reviewGroupName}
                </a>
            ),
            sortingField: "reviewGroupName",
        },
    ];

    const dataStoreMap: any = {};

    decisions.forEach((decision): void => {
        dataStoreMap[decision.dataStoreName] = true;
    });

    Object.keys(dataStoreMap).forEach((dataStoreName): void => {
        columnDefinitions.push({
            id: dataStoreName,
            header: dataStoreName,
            cell: (e): string => e[dataStoreName],
            sortingField: dataStoreName,
        });
    });

    return columnDefinitions;
};

const makeTafDecisionsItems = (decisions: HistoricalTAFDecision[]): TafDecisionItem[] => {
    const items: TafDecisionItem[] = [];

    const reviewGroupNameMap: any = {};

    decisions.forEach((decision): void => {
        reviewGroupNameMap[decision.reviewGroupName] = true;
    });

    Object.keys(reviewGroupNameMap).forEach((reviewGroupName): void => {
        const item: any = {
            reviewGroupName,
        };

        decisions.forEach((decision): void => {
            if (reviewGroupName === decision.reviewGroupName) {
                item[decision.dataStoreName] = decision.decision;
            }
        });

        items.push(item);
    });

    return items;
};

const makeFinalTafDecisionsColumnDefinitions = (
    hasTax = false
): TableProps.ColumnDefinition<FinalTafDecisionItem>[] => {
    const definitions = [
        {
            id: "dataStoreName",
            header: "Data Store Name",
            cell: (e: FinalTafDecisionItem): string => e.dataStoreName,
            sortingField: "dataStoreName",
        },
        {
            id: "accountingDecision",
            header: "Accounting Decision",
            cell: (e: FinalTafDecisionItem): string => e.accountingDecision,
            sortingField: "accountingDecision",
        },
        {
            id: "ctpsDecision",
            header: "CTPS Decision",
            cell: (e: FinalTafDecisionItem): string => e.ctpsDecision,
            sortingField: "ctpsDecision",
        },
    ];

    if (hasTax) {
        definitions.push({
            id: "taxDecision",
            header: "Tax Decision",
            cell: (e: FinalTafDecisionItem): string => e.taxDecision,
            sortingField: "taxDecision",
        });
    }

    return definitions;
};

const makeFinalTafDecisionsItems = (decisions: HistoricalTAFDecision[]): FinalTafDecisionItem[] => {
    const items: FinalTafDecisionItem[] = [];

    const dataStoreNameMap: any = {};

    decisions.forEach((decision): void => {
        dataStoreNameMap[decision.dataStoreName] = true;
    });

    Object.keys(dataStoreNameMap).forEach((dataStoreName): void => {
        const item: Partial<FinalTafDecisionItem> = {
            dataStoreName,
        };

        decisions.forEach((decision): void => {
            if (dataStoreName === decision.dataStoreName) {
                if (isAccountingDecision(decision)) {
                    item.accountingDecision = decision.decision;
                }

                if (isCTPSDecision(decision)) {
                    item.ctpsDecision = decision.decision;
                }

                if (isTaxDecision(decision)) {
                    item.taxDecision = decision.decision;
                }
            }
        });

        items.push(item as FinalTafDecisionItem);
    });

    return items;
};

const zeroPad = (num: number): string => padStart(num.toString(), 2, "0");

const formatDate = (date: Date): string => {
    return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1)}-${zeroPad(date.getDate())}`;
};

const selectGroupIDs = (decisions: HistoricalTAFDecision[]): string[] => {
    const groups = groupBy(
        decisions
            .sort((a: HistoricalTAFDecision, b: HistoricalTAFDecision): number =>
                a.recallDate > b.recallDate ? -1 : 0
            )
            .map((decision: HistoricalTAFDecision): string => decision.groupID ?? "")
    );

    return Object.keys(groups);
};

const selectRecallDatesByGroupID = (decisions: HistoricalTAFDecision[]): Date[] => {
    const groupIDs = selectGroupIDs(decisions);

    return groupIDs.map((groupID): Date => {
        // Finds first instance to determine date value for dropdown option
        const decision = decisions.find((decision): boolean => decision.groupID === groupID) as HistoricalTAFDecision;

        return new Date(decision.recallDate);
    });
};

export const renderDateHeader = (decisions: HistoricalTAFDecision[]): JSX.Element | null => {
    const [{ recallDate: decisionDateValue = null } = {}] = decisions;
    const decisionDate = decisionDateValue ? new Date(decisionDateValue) : null;

    return decisionDate ? (
        <Header variant={"h3"}>
            {formatDate(decisionDate)} {decisionDate.toLocaleTimeString().toLocaleLowerCase()}
        </Header>
    ) : null;
};

export const DataStoreDecisions = (props: Props): JSX.Element => {
    const { decisions: decisionsProp } = props;

    const finalDecisions: HistoricalTAFDecision[] = [];

    let hasTax = false;

    const decisions = decisionsProp.filter((decision): boolean => {
        // Flag to make tax column visible
        if (isTaxDecision(decision)) {
            hasTax = true;
        }

        // Pushes final decisions into separate list
        if (isAnyDecision(decision)) {
            finalDecisions.push(decision);
            return false;
        }

        return true;
    });

    const filterOptions = selectRecallDatesByGroupID(decisions).map(
        (date, index): SelectProps.Option => ({
            value: index === 0 ? `${formatDate(date)} (most recent)` : formatDate(date),
        })
    );

    const selectDecisionsByFilterOption = (decisions: HistoricalTAFDecision[]): HistoricalTAFDecision[] =>
        decisions.filter((decision): boolean => decision.groupID === groupBySelectedOption);

    const filterAvailableGroupIDs = selectGroupIDs(decisions).map((groupID): string => groupID);

    const [selectedFilterOption, setSelectedFilterOption] = useState<OptionDefinition>(filterOptions[0] ?? {});

    const selectedIndex = filterOptions.findIndex((option): boolean => option.value === selectedFilterOption.value);
    const groupBySelectedOption = filterAvailableGroupIDs[selectedIndex];

    const selectedDecisions = selectDecisionsByFilterOption(decisions);
    const selectedFinalDecisions = selectDecisionsByFilterOption(finalDecisions);

    const allTafDecisionsItems = makeTafDecisionsItems(selectedDecisions);
    const allFinalTafDecisionsItems = makeFinalTafDecisionsItems(selectedFinalDecisions);

    const { items: tafDecisionsItems, collectionProps: tafDecisionsCollectionProps } = useCollection(
        allTafDecisionsItems,
        { sorting: {} }
    );

    const { items: finalTafDecisionsItems, collectionProps: finalTafDecisionsCollectionProps } = useCollection(
        allFinalTafDecisionsItems,
        { sorting: {} }
    );

    return (
        <StyledContainer header={<Header variant="h2">Data Store Decisions</Header>}>
            <SpaceBetween size={"xl"}>
                <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
                    <FormField label="Filter report by date:">
                        <Select
                            selectedOption={selectedFilterOption}
                            options={filterOptions}
                            disabled={!Boolean(filterOptions.length)}
                            onChange={(event): void => setSelectedFilterOption(event.detail.selectedOption)}
                        />
                    </FormField>
                    <RightCol>{renderDateHeader(selectedDecisions)}</RightCol>
                </Grid>
                <Header variant="h2">Decisions in TAF Review {`(${tafDecisionsItems.length})`}</Header>
                <Table
                    {...tafDecisionsCollectionProps}
                    columnDefinitions={makeTafDecisionsColumnDefinitions(selectedDecisions)}
                    items={tafDecisionsItems}
                    empty={<span>No decisions to show.</span>}
                />
                <Header variant="h2">Retention Guidance</Header>
                <Table
                    {...finalTafDecisionsCollectionProps}
                    columnDefinitions={makeFinalTafDecisionsColumnDefinitions(hasTax)}
                    items={finalTafDecisionsItems}
                    empty={<span>No decisions to show.</span>}
                />
            </SpaceBetween>
        </StyledContainer>
    );
};
