/* eslint-disable max-len */
import {
    Autosuggest,
    AutosuggestProps,
    Box,
    Button,
    FormField,
    Grid,
    NonCancelableCustomEvent,
    Container,
    Header,
    Link,
} from "@amzn/awsui-components-react-v3";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Model from "src/components/constants/Constants";
import { generateIdentifiers, DataStoreIdentifier } from "src/components/DataStoreIdentifiers";
import Dropdown from "src/components/fields/dropdown/Dropdown";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { InstanceIdContainer, StyledDataStoreInfoContent } from "src/components/fields/styled";
import { isAndes, isDataStoreInstancesValid } from "src/components/survey/DataStoreInfo/DataStoreWizard/DataStoreUtils";
import { BindleResource } from "src/services/KaleApplicationService";
import { findResourceName } from "src/util/DGRUtil";
import "src/components/fields/Fields.scss";
import { INCOMPLETE_WIZARD_FIELD_ERROR } from "src/components/survey/DataStoreInfo/DataStoreWizard/constants";
import { CollectDataStoreInfoProps } from "src/components/survey/DataStoreInfo/DataStoreWizard/CollectDataStoreInfo/types";
import { useCustomValidation } from "src/components/survey/DataStoreInfo/DataStoreWizard/CollectDataStoreInfo/useCustomValidation";
import DataStoreName from "src/components/survey/DataStoreInfo/DataStoreWizard/CollectDataStoreInfo/DataStoreName";
import { TEST_IDS } from "shared/survey";
import { copyToClipboard } from "src/util/clipboard";
import styled from "styled-components";
import CopyToClipboardButton from "src/components/buttons/CopyToClipboardButton";
import DataStoreInstance from "src/components/survey/DataStoreInfo/DataStoreWizard/CollectDataStoreInfo/DataStoreInstance";
import DataStoreDescription from "src/components/survey/DataStoreInfo/DataStoreWizard/CollectDataStoreInfo/DataStoreDescription";
/* eslint-enable max-len */

const { COLLECT_INFO_STEP } = TEST_IDS.DATA_STORE_INFO.DATA_STORE_WIZARD;

const GreyText = styled.div`
    color: grey;
`;

const InstanceContainer = styled.div`
    display: flex;
`;

const DataStoreInstanceId = styled(InstanceIdContainer)`
    flex: 3;
    margin-bottom: 1rem !important;
`;

const InstanceButton = styled(Button)`
    top: -1rem;
`;

/**
 * This component is responsible for rendering the first step of the LegalSurvey's
 * DataStoreWizard, which collects info about the data store to uniquely identify it.
 */
const CollectDataStoreInfo = (props: CollectDataStoreInfoProps): JSX.Element => {
    const {
        id,
        activeDataStoreUUID,
        isRequired,
        isFormInReadonlyMode = false,
        expectedAnswer,
        dgrResources = [],
        onChangeCallback,
        onTechnologyChangeCallback,
        shouldRenderErrors,
    } = props;
    const data = Model.getData(id);

    const [technologyOptions, setTechnologyOptions] = useState<AutosuggestProps.Option[]>([]);
    const dgrResourcesRef = useRef<BindleResource[]>(dgrResources);

    const stepValidationErrors = useCustomValidation(props);

    useEffect((): void => {
        // Make Technology options for Autosuggest.
        const resources = dgrResourcesRef.current
            .filter(
                (resource): boolean => resource.resourceType.toLowerCase() === expectedAnswer?.technology.toLowerCase()
            )
            .map((resource): AutosuggestProps.Option => {
                const option: AutosuggestProps.Option = {
                    value: resource.resourceArn,
                    tags: [`AWS account: ${resource.awsAccountId}`, `Region ${resource.awsRegion}`],
                };
                const resourceName = findResourceName(resource);
                if (resourceName !== "") {
                    option["description"] = `Name: ${resourceName}`;
                }
                return option;
            });
        setTechnologyOptions(resources);
    }, [expectedAnswer?.technology]);

    const onDataStoreDescriptionChangeCb = useCallback(
        (description: string): void => {
            onChangeCallback({
                id: id,
                response: {
                    name: expectedAnswer!.name,
                    description,
                    instances: expectedAnswer!.instances,
                    technology: expectedAnswer!.technology,
                },
            });
        },
        [id, expectedAnswer, onChangeCallback]
    );

    const onDataStoreTechnologyChangedCb = useCallback(
        (value: string): void => {
            onTechnologyChangeCallback?.call(null, {
                id: id,
                isValid: true,
                isLoading: false,
                response: {
                    name: isAndes(value) ? "Andes" : expectedAnswer!.name,
                    description: expectedAnswer!.description,
                    instances: [{ identifiers: generateIdentifiers(value) }],
                    technology: value,
                },
            });
        },
        [id, expectedAnswer, onTechnologyChangeCallback]
    );

    const onDataStoreIdentifierAddedCb = useCallback((): void => {
        onChangeCallback({
            id: id,
            response: {
                name: expectedAnswer!.name,
                description: expectedAnswer!.description,
                instances: [
                    ...(expectedAnswer?.instances ?? []),
                    { identifiers: generateIdentifiers(expectedAnswer?.technology ?? "") },
                ],
                technology: expectedAnswer!.technology,
            },
        });
    }, [expectedAnswer, id, onChangeCallback]);

    const onDataStoreIdentifierChangedCb = useCallback(
        (value: string, instIndex: number, idIndex: number): void => {
            const instances = [...expectedAnswer!.instances];
            instances[instIndex].identifiers[idIndex].value = value;
            onChangeCallback({
                id: id,
                response: {
                    name: expectedAnswer!.name,
                    description: expectedAnswer!.description,
                    instances: instances,
                    technology: expectedAnswer?.technology ?? "",
                },
            });
        },
        [expectedAnswer, id, onChangeCallback]
    );

    const onDataStoreIdentifierRemovedCb = useCallback(
        (index: number): void => {
            expectedAnswer!.instances.splice(index, 1);
            onChangeCallback({
                id: id,
                response: {
                    name: expectedAnswer!.name,
                    description: expectedAnswer!.description,
                    instances: expectedAnswer!.instances,
                    technology: expectedAnswer!.technology,
                },
            });
        },
        [expectedAnswer, id, onChangeCallback]
    );

    const onDataStoreNameBlur = (newName: string): void => {
        // Important to keep the trimmed result so that we can treat "A" and "A  "
        // as the same string and display a duplicate name error. Failure to
        // block user from creating Data Stores with duplicate names will result
        // in React rendering issues elsewhere in DataStoreInfo where the
        // Data Store Names are relied upon as unique keys in a list of elements
        onChangeCallback({
            id: id,
            response: {
                name: newName.trim(),
                description: expectedAnswer!.description,
                instances: expectedAnswer!.instances,
                technology: expectedAnswer!.technology,
            },
        });
    };

    const renderIdentifierComponents = (
        instIndex: number,
        identifier: DataStoreIdentifier,
        idIndex: number
    ): JSX.Element => {
        const technology = expectedAnswer?.technology.toLowerCase() ?? "";
        const key = `${id}-ds-${instIndex}-${identifier.type}-${technology}`;
        if (technology && technologyOptions.length) {
            return (
                <Autosuggest
                    key={`${key}-autosuggest`}
                    data-testid={`${key}-autosuggest`}
                    disabled={isFormInReadonlyMode}
                    options={technologyOptions}
                    empty={"Enter custom value"}
                    placeholder={identifier.type}
                    value={identifier.value}
                    onChange={(event: NonCancelableCustomEvent<AutosuggestProps.ChangeDetail>): void =>
                        onDataStoreIdentifierChangedCb(event.detail.value, instIndex, idIndex)
                    }
                    enteredTextLabel={(value: string): string => value}
                />
            );
        } else {
            return (
                <DataStoreInstance
                    key={`${key}-input`}
                    data-testid={`${key}-input`}
                    disabled={isFormInReadonlyMode}
                    placeholder={identifier.type}
                    readOnly={isFormInReadonlyMode}
                    value={identifier.value}
                    onBlur={(instanceName): void => {
                        onDataStoreIdentifierChangedCb(instanceName, instIndex, idIndex);
                    }}
                />
            );
        }
    };

    const renderIdentifiers = (): JSX.Element[] | null => {
        return (
            expectedAnswer?.instances.map((instance, instIndex): JSX.Element => {
                return (
                    <InstanceContainer key={`${instIndex}-ids-remove-button`}>
                        <DataStoreInstanceId key={`${instIndex}-ids`}>
                            {instance.identifiers.map((identifier, idIndex): JSX.Element => {
                                return renderIdentifierComponents(instIndex, identifier, idIndex);
                            })}
                        </DataStoreInstanceId>
                        <InstanceButton
                            key={`remove-${instIndex}-button`}
                            data-testid={`remove-${instIndex}-button`}
                            disabled={isFormInReadonlyMode}
                            iconName={"close"}
                            variant={"icon"}
                            onClick={(): void => {
                                onDataStoreIdentifierRemovedCb(instIndex);
                            }}
                        />
                    </InstanceContainer>
                );
            }) ?? null
        );
    };

    // The most recent data store name value that was passed to a parent component
    const activeDataStoreName = expectedAnswer?.name ?? "";
    const activeDataStoreTechnology = expectedAnswer?.technology ?? "";

    return (
        <div data-testid={COLLECT_INFO_STEP.ROOT}>
            <Container
                header={
                    <Header
                        variant={"h2"}
                        description={
                            <Box variant="p">
                                <div>Provide information to help us uniquely identify your data store.</div>
                                For naming standards, please refer to this{" "}
                                <Link
                                    external
                                    externalIconAriaLabel="Opens in a new tab"
                                    href="https://w.amazon.com/bin/view/Devices-data/Data_Governance/FQON/"
                                >
                                    wiki
                                </Link>
                            </Box>
                        }
                    >
                        Identify your data store
                    </Header>
                }
            >
                <FormField stretch={true}>
                    {shouldRenderErrors && stepValidationErrors}
                    {activeDataStoreUUID && (
                        <CopyToClipboardButton
                            data-testid={COLLECT_INFO_STEP.UUID_COPY_CLIPBOARD_BUTTON}
                            successMessage="Data Store UUID copied"
                            onClick={async (): Promise<void> => {
                                await copyToClipboard(activeDataStoreUUID);
                            }}
                        >
                            <GreyText data-testid={COLLECT_INFO_STEP.UUID_TEXT}>{activeDataStoreUUID}</GreyText>
                        </CopyToClipboardButton>
                    )}
                    <Grid gridDefinition={[{ colspan: { default: 6 } }, { colspan: { default: 6 } }]}>
                        <StyledDataStoreInfoContent>
                            <div>
                                <FormField
                                    id={"data-store-tech-label"}
                                    label={"Data store technology"}
                                    errorText={
                                        isRequired && !Boolean(activeDataStoreTechnology)
                                            ? INCOMPLETE_WIZARD_FIELD_ERROR
                                            : ""
                                    }
                                    data-testid={COLLECT_INFO_STEP.TECHNOLOGY_FIELD}
                                >
                                    <Dropdown
                                        options={data.concat("Other")}
                                        expectedAnswer={activeDataStoreTechnology}
                                        customDropdownValue={"Other"}
                                        isDisabled={isFormInReadonlyMode}
                                        onChange={onDataStoreTechnologyChangedCb}
                                    />
                                </FormField>
                            </div>
                            <StyledDataStoreInfoContent>
                                <div>
                                    <DataStoreName
                                        value={activeDataStoreName}
                                        disabled={isFormInReadonlyMode}
                                        isRequired={isRequired}
                                        data-testid={COLLECT_INFO_STEP.NAME_INPUT}
                                        onBlur={onDataStoreNameBlur}
                                    />
                                </div>
                                <div>
                                    <DataStoreDescription
                                        data-testid={COLLECT_INFO_STEP.DESCRIPTION_INPUT}
                                        disabled={isFormInReadonlyMode}
                                        value={expectedAnswer?.description ?? ""}
                                        onBlur={onDataStoreDescriptionChangeCb}
                                    />
                                </div>
                            </StyledDataStoreInfoContent>
                        </StyledDataStoreInfoContent>
                        <div>
                            {!isAndes(activeDataStoreTechnology) && (
                                <div>
                                    <FormField
                                        data-testid={COLLECT_INFO_STEP.IDENTIFIERS_FIELD}
                                        id={"data-store-id-label"}
                                        label={
                                            <div>
                                                <span>Data store identifier</span>
                                            </div>
                                        }
                                        errorText={
                                            isRequired && !isDataStoreInstancesValid(expectedAnswer?.instances ?? [])
                                                ? INCOMPLETE_WIZARD_FIELD_ERROR
                                                : ""
                                        }
                                        constraintText={
                                            <Box variant="p">AWS ARN, Database name, Sable scope & entity, etc.</Box>
                                        }
                                    >
                                        {renderIdentifiers()}
                                    </FormField>
                                </div>
                            )}
                            {!isAndes(activeDataStoreTechnology) && (
                                <div id="data-store-buttons">
                                    <Button
                                        data-testid={COLLECT_INFO_STEP.ADD_INSTANCE_BUTTON}
                                        onClick={onDataStoreIdentifierAddedCb}
                                        disabled={isFormInReadonlyMode}
                                    >
                                        Add data store identifier
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Grid>
                </FormField>
            </Container>
        </div>
    );
};

export default withFunctionalReadonly(CollectDataStoreInfo);
