import React from "react";
import NewDropdown from "src/components/fields/dropdown/NewDropdown";
import DASTable from "src/components/fields/table/DASTable";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { HeaderItem } from "src/components/survey/DataStoreInfo/DataStoreTable/styled";
import { KeyValuePairs } from "src/components/survey/LegalSurveyPage";
import { ApplicationStatus, Flag, SurveyResponse } from "src/components/survey/SurveyFormModel";
import { Can, isAuthorized, selectApplicationStatusByUserAction, UserAction, UserRole } from "src/permissions";
import { TableProps } from "@amzn/awsui-components-react-v3";
import { makeOptionFromChoice, QuestionOption } from "src/services/dynamic-questions";

const STYLED_TABLE = `
     {        
        td:first-child,
        th:first-child {
            position: sticky !important;
            width: 50px;
            left: 0;
            z-index: 1;
            background-color: white;
        }

        th {
            position: sticky !important;
            top: 0;
            z-index: 2;
        }

        td:first-child,
        th:first-child {
            z-index: 3;
        }

        tr.awsui-table-row-selected td:first-of-type {
            background-color: #f0faff;
        }
    }
    
    {
        div.awsui-table-container {
            min-height: 250px;
            max-height: 600px;
        }
    }
`;

export const TEST_IDS = {
    DECISION_DROPDOWN: "taf-decision-table-dropdown",
    FINAL_DECISION_TABLE: "taf-final-decision-table",
};

type EditDecisionMapping = {
    [key in UserRole]: Flag[];
};
const EDIT_DECISION_MAPPING: EditDecisionMapping = {
    accountingReviewer: [Flag.accounting],
    fraudReviewer: [Flag.ctps],
    admin: [Flag.accounting, Flag.ctps],
    readOnly: [],
    reviewer: [],
    serviceOwner: [],
};

export enum TAFDecision {
    noDelete = "Not Deleting Data",
    // noRetain was the final datastore-level decision granted for "No Retention Required" (when chosen at the subgroup
    // level) from 2021 to Feb 2023
    noRetain = "No Retention Required",
    // noRetainAndHardDelete is the decision granted for "No Retention Required" responses from Feb 2023 onwards
    // The difference between noRetain and noRetainAndHardDelete is that Accounting's noRetain decisions recommend
    // users to soft-delete. But Accounting's noRetainAndHardDelete recommend hard delete.
    // The reason for the change is that there was a policy change in Feb 2023 which let users hard-delete in this
    // scenario.
    // noRetain and noRetainAndHardDelete for CTPS give the same guidance to users (which is hard-delete).
    noRetainAndHardDelete = "No Retention Required (Hard-Delete)",
    noRetainSelfApproved = "No Retention Required (Self-Approved)",
    pending = "Decision Pending",
    retain = "Retention Required",
    retainSelfSelected = "Retention (Self-Selected)",
    softDeleteSelfSelected = "Soft-Delete (Self-Selected)",
    hardDelete = "Hard-Delete",
}

export interface Decision {
    id: number;
    decision: TAFDecision;
    reviewGroupName: string;
    reviewGroupType: Flag;
}

type DecisionOptionMapping = {
    [key in Flag]: TAFDecision[];
};
// Only these two options are present at the subgroup level for human reviewers to choose from
const DECISION_OPTION_MAPPING: DecisionOptionMapping = {
    Accounting: [TAFDecision.retain, TAFDecision.noRetain],
    CTPS: [TAFDecision.retain, TAFDecision.noRetain],
};

interface Props {
    userRole: UserRole;
    tafDetailsFromServer: SurveyResponse | null;
    applicableDataStores: DataStoreResponse[];
    onChange: (dataStore: DataStoreResponse) => void;
}

interface TAFDecisionRow {
    index: number;
    reviewGroupName: string;
    decisionsMap: KeyValuePairs<Decision>;
}

export const TAFDecisionTable = (props: Props): JSX.Element => {
    const { userRole, tafDetailsFromServer, applicableDataStores, onChange } = props;
    const getStatus = (action: UserAction): ApplicationStatus | undefined => {
        return selectApplicationStatusByUserAction(action, tafDetailsFromServer ?? undefined, userRole);
    };

    const handleDecisionChange = (row: TAFDecisionRow, dataStore: DataStoreResponse, decision?: string): void => {
        const decisionIndex =
            dataStore.decisions?.findIndex((decision): boolean => decision.reviewGroupName === row.reviewGroupName) ??
            -1;
        if (dataStore.decisions === undefined || decisionIndex < 0) {
            return;
        }

        const decisions = dataStore.decisions.slice();
        decisions[decisionIndex] = {
            ...decisions[decisionIndex],
            decision: (decision as TAFDecision) ?? "",
        };

        onChange({ ...dataStore, decisions });
    };

    const decisionColumns: TableProps.ColumnDefinition<TAFDecisionRow>[] = [
        {
            id: "reviewGroupName",
            width: 200,
            header: (
                <HeaderItem title={"Review Group Name"}>
                    <b>Review Group Name</b>
                </HeaderItem>
            ),
            cell: (row): string => row.reviewGroupName,
        },
        ...applicableDataStores.map(
            (dataStore): TableProps.ColumnDefinition<TAFDecisionRow> => ({
                id: dataStore.name,
                minWidth: "150px",
                width: 300,
                header: (
                    <HeaderItem title={dataStore.name}>
                        <b>{dataStore.name}</b>
                    </HeaderItem>
                ),
                cell: (row: TAFDecisionRow): JSX.Element => {
                    if (!(dataStore.name in row.decisionsMap)) {
                        return <div />;
                    }
                    const id = `${TEST_IDS.DECISION_DROPDOWN}-${dataStore.name}-${row.index}`;
                    const cell = row.decisionsMap[dataStore.name];
                    const isNotReadonly =
                        isAuthorized(userRole, UserAction.editDecisions, (): ApplicationStatus | undefined =>
                            getStatus(UserAction.editDecisions)
                        ) && EDIT_DECISION_MAPPING[userRole].includes(cell.reviewGroupType);
                    return (
                        <NewDropdown
                            id={id}
                            width={280}
                            options={["-", ...DECISION_OPTION_MAPPING[cell.reviewGroupType]].map(
                                (value): QuestionOption => makeOptionFromChoice({ value })
                            )}
                            expectedAnswer={cell.decision as string}
                            isReadonly={!isNotReadonly}
                            onChangeCallback={(selectedDecision): void => {
                                const decision = selectedDecision.response === "-" ? "" : selectedDecision.response;
                                handleDecisionChange(row, dataStore, decision);
                            }}
                        />
                    );
                },
            })
        ),
    ];

    const reviewGroupNameMap: KeyValuePairs<KeyValuePairs<Decision>> = {};
    applicableDataStores.forEach((dataStore): void => {
        dataStore.decisions?.forEach((decision): void => {
            if (!(decision.reviewGroupName in reviewGroupNameMap)) {
                reviewGroupNameMap[decision.reviewGroupName] = {};
            }
            reviewGroupNameMap[decision.reviewGroupName][dataStore.name] = { ...decision };
        });
    });
    const decisionRows: TAFDecisionRow[] = Object.entries(reviewGroupNameMap).map(
        ([reviewGroupName, decisionsMap], index): TAFDecisionRow => ({
            reviewGroupName,
            decisionsMap,
            index,
        })
    );

    return (
        <React.Fragment>
            {applicableDataStores.length > 0 && (
                <Can
                    perform={UserAction.viewDecisions}
                    yes={(): JSX.Element => (
                        <DASTable
                            id={"taf-decision-table"}
                            tableName={"Decisions in TAF Review"}
                            isLoading={false}
                            hidePreferences={true}
                            hideContentSelector={true}
                            columnDefinitions={decisionColumns}
                            emptyTextPrefix={"No review group"}
                            rowItems={decisionRows}
                            cssString={STYLED_TABLE}
                        />
                    )}
                />
            )}
        </React.Fragment>
    );
};
