import { Alert, Box, Header, SelectProps, SpaceBetween, Table } from "@amzn/awsui-components-react-v3";
import { LINEAGE_FINDINGS_TITLE, renderLineageLearnMore } from "src/components/survey/PersonalDataWizard/content";
import React, { useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { PersonalDataWizardStep } from "src/components/survey/PersonalDataWizard/steps/index";
import { WizardProps } from "src/components/InlineWizard/interfaces";
import { TEST_IDS } from "shared/survey/personalDataWizard";
import {
    BindleConnection,
    BindleConnectionDisplayName,
    BindleConnectionType,
} from "src/services/external-lineage/types";

interface Props {
    /**
     * List representing the combined set of the applications control bindle and all related bindles.
     * [{label: <bindle name>, value: <bindle id (from fetchBindleByOwner() API)>}].
     * Lineage API would require bindle ids as input and so we use "value" from this props
     */
    requestBindles: SelectProps.Option[];
}

interface Response extends PersonalDataWizardStep {
    lineageFindings: BindleConnection[];
}

export const useLineageStep = (props: Props): Response => {
    const kaleContext = useContext(KaleContext);
    const {
        service: {
            kaleAppService: { fetchBindlesConnections },
        },
    } = kaleContext;
    const { requestBindles } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [lineageFindings, setLineageFindings] = useState<BindleConnection[]>([]);

    const deps = { fetchBindlesConnections };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    useEffect((): void => {
        const { fetchBindlesConnections } = dependenciesRef.current;

        (async (): Promise<void> => {
            if (requestBindles.length > 0) {
                setIsLoading(true);
                setError("");
                let bindleConnections: BindleConnection[] = [];
                try {
                    bindleConnections = await fetchBindlesConnections(
                        requestBindles.map((relatedBindle): string => relatedBindle.value ?? "")
                    );
                } catch (e) {
                    setError((e as Error).message);
                } finally {
                    setIsLoading(false);
                }
                setLineageFindings(bindleConnections);
            }
        })();
    }, [requestBindles]);

    const step: WizardProps.Step = {
        title: LINEAGE_FINDINGS_TITLE,
        infoCount: lineageFindings.length,
        description: (
            <Box variant="small">
                These data lineage results show the systems, tools, or resources connected to yours, that have attested
                to storing personal data in Kale.
                {renderLineageLearnMore()}
            </Box>
        ),
        content: (
            <SpaceBetween direction="vertical" size="l">
                {lineageFindings.length !== 0 && (
                    <Alert type="warning">
                        Your application is connected to these resources. If you send or receive data with these
                        connections, your application may also process personal data.
                    </Alert>
                )}
                <div>
                    <Table
                        data-testid={TEST_IDS.WIZARD.LINEAGE_STEP.LINEAGE_TABLE}
                        header={
                            <Header counter={`(${lineageFindings.length})`}>
                                Connected resources that process personal data
                            </Header>
                        }
                        loading={isLoading}
                        columnDefinitions={[
                            {
                                id: "bindle",
                                header: "Bindle",
                                cell: (e: BindleConnection): string => e.otherBindleName,
                            },
                            {
                                id: "connectionType",
                                header: "Connection Type",
                                cell: (e: BindleConnection): string => BindleConnectionDisplayName[e.connectionType],
                            },
                            {
                                id: "connectionDetails",
                                header: "Connection Details",
                                cell: (e: BindleConnection): JSX.Element | null => {
                                    let href = "";

                                    switch (e.connectionType) {
                                        case BindleConnectionType.AAA: {
                                            // eslint-disable-next-line max-len
                                            href = `https://aaa.amazon.com/applications/${e.otherResourceId}`;
                                            break;
                                        }
                                        case BindleConnectionType.AWS_ACCOUNT: {
                                            // eslint-disable-next-line max-len
                                            href = `https://conduit.security.a2z.com/accounts/aws/${e.otherResourceId}`;
                                            break;
                                        }
                                        default: {
                                            break;
                                        }
                                    }

                                    if (href) {
                                        return (
                                            <a rel="noreferrer" target="_blank" href={href}>
                                                {BindleConnectionDisplayName[e.connectionType]}
                                            </a>
                                        );
                                    } else {
                                        return null;
                                    }
                                },
                            },
                            {
                                id: "categoryTypeItem",
                                header: "Category type item",
                                cell: (e: BindleConnection): string => e.otherBindleCti,
                            },
                        ]}
                        items={lineageFindings}
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No resources</b>
                                <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                                    No resources to display.
                                </Box>
                            </Box>
                        }
                    />
                </div>
            </SpaceBetween>
        ),
    };

    return { isLoading, step, lineageFindings, error };
};
