import { useLocation } from "react-router-dom";
import { UserAction, useIsAuthorized } from "src/permissions";

export enum DriftCase {
    NONE = "NONE",
    REAFFIRM = "REAFFIRM",
    RECERTIFY = "RECERTIFY",
    UNAUTHORIZED = "UNAUTHORIZED",
}

/**
    Utility to decide the active Drift Detection UX workflow 
    based on the query parameters, application data, and user permissions.
*/
const useDriftDetectionCase = (): DriftCase => {
    const { cta } = Object.fromEntries(new URLSearchParams(useLocation().search));
    const canReaffirm = useIsAuthorized(UserAction.ctaReaffirmPersonalData);
    const canRecertify = useIsAuthorized(UserAction.ctaRecertify);

    switch (cta?.toUpperCase()) {
        case DriftCase.REAFFIRM:
            if (!canReaffirm) return DriftCase.UNAUTHORIZED;
            return DriftCase.REAFFIRM;
        case DriftCase.RECERTIFY:
            if (!canRecertify) return DriftCase.UNAUTHORIZED;
            return DriftCase.RECERTIFY;
        default:
            return DriftCase.NONE;
    }
};

export default useDriftDetectionCase;
