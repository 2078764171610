import { Box, ExpandableSection, FormField, Grid, Header, Icon, Spinner, Tabs } from "@amzn/awsui-components-react-v3";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
    AnswerActions,
    AnswerWithQuestion,
    DataStoreAnswer,
    DataStoreAnswerWithQuestion,
    DataStoreHookAnswer,
    DataStoreQuestion,
    findDataStoreAccessor,
    useDataStoresPayloads,
} from "src/answers_legacy";
import { useDataStores } from "src/answers_legacy/hooks/dataStores";
import CopyToClipboardButton from "src/components/buttons/CopyToClipboardButton";
import KaleContext from "src/components/KaleContext";
import SchemaTable from "src/components/schema_table/SchemaTable";
import {
    BRAND_NEW_DATA_STORE__HAS_NO_INDEX,
    INITIAL_WIZARD_ACTIVE_STEP_INDEX,
} from "src/components/survey/DataStoreInfo/contants";
import DataStoreTable from "src/components/survey/DataStoreInfo/DataStoreTable";
import { DataStoreWizard } from "src/components/survey/DataStoreInfo/DataStoreWizard";
import { DataStoreInstance } from "src/components/survey/DataStoreInfo/DataStoreWizard/CollectDataStoreInfo";
import { isAndes } from "src/components/survey/DataStoreInfo/DataStoreWizard/DataStoreUtils";
import { useGetAccessorStash } from "src/components/survey/DataStoreInfo/hooks/useGetAccessorStash";
import { KaleApplication } from "src/components/survey/IndexPage";
import { DisplayMessageCb } from "src/components/survey/KaleRoutes";
import { KeyValuePairs } from "src/components/survey/LegalSurveyPage";
import { ApplicationStatus } from "src/components/survey/SurveyFormModel";
import { Decision } from "src/components/TAF/TAFDetails/TAFDecisionTable";
import { BindleResource, HAS_PERSONAL_DATA_QUESTION_SHORT_ID, KaleQuestion } from "src/services/KaleApplicationService";
import { TableRecord } from "src/services/KaleTablesService";
import { copyToClipboard } from "src/util/clipboard";
import styled from "styled-components";
import pluralize from "pluralize";
import TwoStageRender from "src/components/decorators/TwoStageRender";

export const TEST_IDS = {
    EXPANDABLE_SECTION: "datastore-info--expandable-section",
    UUID_COPY_CLIPBOARD_BUTTON: "datastore-info--uuid-copy",
    UUID_TEXT: "datastore-info--uuid-text",
};

interface Props {
    appName: string;
    isReadOnly: boolean;
    isRequired: boolean;
    atLeastOneDataStoreIsRequired: boolean;
    levelOfDetailActiveTab: string;
    toggleLevelOfDetailTab: (levelOfDetailActiveTab: string) => void;
    status: ApplicationStatus;
    dgrResources: BindleResource[];
    questions: KaleQuestion;
    committedDataStores: DataStoreResponse[];
    setNewDataStoreResponsesCb: (response: DataStoreResponse[]) => void;
    displayMessage: DisplayMessageCb;
}

export interface DataStoreResponse {
    id?: number;
    uuid?: string;
    name: string;
    description: string;
    technology: string;
    accountingRetentionDecision: string;
    fraudRetentionDecision: string;
    taxRetentionDecision: string;
    instances: DataStoreInstance[];
    dataStoreAnswers: DataStoreAnswer[];
    tables: TableRecord[];
    decisions?: Decision[];

    // frontend only field, the backend will ignore this
    // this will be used to track data stores that have yet
    // to be persisted into the backend and only exist in the frontend
    unsavedUUID?: string;
}

export interface TransferTablesDSProps {
    sourceDSId: number;
    tableId: number;
    destDSId: number;
}
export type TransferTablesCb = (items: TransferTablesDSProps[]) => void;

const NoPersonalData = styled.div`
    text-align: center;
    margin-bottom: 50px;
`;

const EmptyMessage = styled.span`
    color: var(--awsui-color-text-empty);
    font-weight: bold;
`;

const AttestedMessage = styled.span`
    margin-left: 3px;
`;

const GreyText = styled.div`
    color: grey;
`;

const StyledGrid = styled(Grid)`
    width: 100%;
`;

interface AttestedLabelProps {
    warning?: boolean;
}

const AttestedLabel = styled.div`
    color: ${(props: AttestedLabelProps): string =>
        props.warning ? "var(--awsui-color-text-status-warning)" : "var(--awsui-color-text-link-default)"};
    font-size: 12px;
    font-weight: bold;
    position: relative;
    top: 10px;
`;

export const DataStoreInfo = (props: Props): JSX.Element => {
    const kaleContext = useContext(KaleContext);
    const {
        appName,
        committedDataStores,
        dgrResources,
        displayMessage,
        isReadOnly,
        levelOfDetailActiveTab,
        questions,
        setNewDataStoreResponsesCb,
        status,
        toggleLevelOfDetailTab,
    } = props;

    const [showModal, setShowModal] = useState<boolean>(false);
    const [expandedTables, setExpandedTables] = useState<KeyValuePairs<boolean>>({});
    const [activeDataStoreIndex, setActiveDataStoreIndex] = useState<number>(BRAND_NEW_DATA_STORE__HAS_NO_INDEX);
    const [appNames, setAppNames] = useState<string[]>([]);
    const [accessors, actions] = useDataStores();
    const dataStoresPayload = useDataStoresPayloads();
    const [accessorsStash, setAccessorsStash] = useGetAccessorStash();
    const actionsRef = useRef<AnswerActions<DataStoreQuestion, DataStoreAnswer>>(actions);
    const { removeAnswers, updateAnswers } = actionsRef.current;

    useEffect((): void => {
        kaleContext.service.kaleAppService
            .index()
            .then((indexResponse): void => {
                setAppNames(indexResponse.map((res: KaleApplication): string => res.applicationName));
            })
            .catch((error): void => {
                console.error(error.message);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openWizard = (indexOfDataStoreToEdit?: number): void => {
        // Collapse data elements containers' content because rendering them is expensive
        setExpandedTables({});
        // Make sure its a number.
        const safeIndex = indexOfDataStoreToEdit ?? BRAND_NEW_DATA_STORE__HAS_NO_INDEX;
        setActiveDataStoreIndex(safeIndex);
        setShowModal(true);
    };

    const closeWizard = (): void => {
        setShowModal(false);
        setActiveDataStoreIndex(BRAND_NEW_DATA_STORE__HAS_NO_INDEX);
    };

    const createTableHeaderForDataStores = (response: DataStoreResponse, title: string): JSX.Element => {
        const tableCount = response.tables?.length ?? 0;
        const isAttested = tableCount > 0;
        const result = (
            <React.Fragment>
                {isAttested ? (
                    <React.Fragment>
                        <Icon name="status-info" size="normal" variant="link" />
                        <AttestedMessage>
                            {/* eslint-disable-next-line max-len */}
                            {pluralize("table", tableCount, true)} attested
                        </AttestedMessage>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Icon name="status-warning" size="normal" variant="warning" />
                        <AttestedMessage>Not yet attested</AttestedMessage>
                    </React.Fragment>
                )}
            </React.Fragment>
        );

        return (
            <StyledGrid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                <Box float={"left"}>
                    <Header variant={"h3"}>{title}</Header>
                </Box>
                <Box float={"right"}>
                    <AttestedLabel warning={!isAttested}>{result}</AttestedLabel>
                </Box>
            </StyledGrid>
        );
    };

    const createTablesView = (): JSX.Element[] => {
        const results: JSX.Element[] = [];

        const onTransferTablesCb = (items: TransferTablesDSProps[]): void => {
            const responsesCopy = [...committedDataStores];
            items.forEach((item): void => {
                const sourceDataStore = responsesCopy.find((response): boolean => {
                    return response.id === item.sourceDSId;
                });
                if (sourceDataStore) {
                    const tableIndex = sourceDataStore.tables.findIndex((table): boolean => {
                        return table.id === item.tableId;
                    });
                    if (tableIndex != -1) {
                        const tables = [...sourceDataStore.tables];
                        const table = tables[tableIndex];
                        sourceDataStore.tables = tables.filter((table, index): boolean => {
                            return index !== tableIndex;
                        });
                        const destDataStore = responsesCopy.find((response): boolean => {
                            return response.id === item.destDSId;
                        });
                        destDataStore?.tables.push(table);
                    }
                }
            });
            setNewDataStoreResponsesCb(responsesCopy);
        };

        committedDataStores.forEach((response, index): void => {
            const title = response.name;
            // Hardcoding hasPersonalData "questionId" here as there is no other way to support static data
            // elements in dynamic data stores. This will be removed once data elements is made dynamic.
            const hasPersonalDataAnswer =
                response.dataStoreAnswers.find((answer): boolean => {
                    return answer.questionShortId === HAS_PERSONAL_DATA_QUESTION_SHORT_ID;
                })?.textContent ?? "";
            const expanded = Boolean(expandedTables[title]);

            if (hasPersonalDataAnswer === "Yes" || isAndes(response.technology)) {
                const section = (
                    <ExpandableSection
                        data-testid={`${TEST_IDS.EXPANDABLE_SECTION}--${index}`}
                        key={`${title}-expanded:${expanded}`}
                        variant="container"
                        header={createTableHeaderForDataStores(response, title)}
                        expanded={expanded}
                        onChange={(event): void => {
                            const { expanded } = event.detail;
                            setExpandedTables({
                                ...expandedTables,
                                [title]: expanded,
                            });
                        }}
                    >
                        {expanded && (
                            <TwoStageRender
                                renderInProgress={(): React.ReactNode => (
                                    <Box variant={"div"} textAlign={"center"}>
                                        <Spinner size={"big"} />
                                    </Box>
                                )}
                                renderComplete={(): React.ReactNode => (
                                    <FormField stretch={true}>
                                        <React.Fragment>
                                            {response?.uuid && (
                                                <CopyToClipboardButton
                                                    data-testid={TEST_IDS.UUID_COPY_CLIPBOARD_BUTTON}
                                                    successMessage="Data Store UUID copied"
                                                    onClick={async (): Promise<void> => {
                                                        await copyToClipboard(response.uuid ?? "");
                                                    }}
                                                >
                                                    <GreyText data-testid={TEST_IDS.UUID_TEXT}>
                                                        {response.uuid}
                                                    </GreyText>
                                                </CopyToClipboardButton>
                                            )}
                                            <SchemaTable
                                                dataStore={response}
                                                appName={appName}
                                                appNames={appNames}
                                                status={status}
                                                onChangeCallback={(dataStore: DataStoreResponse): void => {
                                                    const responsesCopy = [...committedDataStores];
                                                    responsesCopy[index] = dataStore;
                                                    setNewDataStoreResponsesCb(responsesCopy);
                                                }}
                                                onTransferTablesCb={onTransferTablesCb}
                                                displayMessage={displayMessage}
                                            />
                                        </React.Fragment>
                                    </FormField>
                                )}
                            />
                        )}
                    </ExpandableSection>
                );
                results.push(section);
            }
        });

        if (!results.length) {
            results.push(
                <NoPersonalData key={"no-data-elements-message"}>
                    <EmptyMessage>No data stores with personal data.</EmptyMessage>
                </NoPersonalData>
            );
        }

        return results;
    };

    const dataStoresWithPersonalDataCount = committedDataStores.filter((response): boolean => {
        // Hardcoding hasPersonalData "questionId" here as there is no other way to support static data
        // elements in dynamic data stores. This will be removed once data elements is made dynamic.
        const hasPersonalDataAnswer =
            response.dataStoreAnswers.find((answer): boolean => {
                return answer.questionShortId === HAS_PERSONAL_DATA_QUESTION_SHORT_ID;
            })?.textContent ?? "";
        return hasPersonalDataAnswer === "Yes";
    }).length;

    return (
        <React.Fragment>
            <DataStoreWizard
                activeStepIndex={INITIAL_WIZARD_ACTIVE_STEP_INDEX}
                showModal={showModal}
                questions={questions.dataStores}
                questionGroupInfoMap={questions.questionGroupInfoMap}
                committedDataStores={committedDataStores}
                activeDataStoreIndex={activeDataStoreIndex}
                dgrResources={dgrResources}
                onSubmit={(newDataStoreResponse: DataStoreResponse): void => {
                    let newResponses: DataStoreResponse[];
                    if (activeDataStoreIndex === BRAND_NEW_DATA_STORE__HAS_NO_INDEX) {
                        // Append newDataStoreResponse
                        newResponses = [...committedDataStores, newDataStoreResponse];
                    } else {
                        // Replace the dataStoreResponse at currentDataStoreIndex
                        newResponses = [
                            ...committedDataStores.slice(0, activeDataStoreIndex),
                            newDataStoreResponse,
                            ...committedDataStores.slice(activeDataStoreIndex + 1),
                        ];
                    }
                    setAccessorsStash(accessors);
                    closeWizard();
                    setNewDataStoreResponsesCb(newResponses);
                }}
                onClose={(response: DataStoreResponse): void => {
                    const targetAccessor = findDataStoreAccessor(accessors, response.id, response.unsavedUUID);
                    const targetStashedAccessor = findDataStoreAccessor(
                        accessorsStash ?? [],
                        response.id,
                        response.unsavedUUID
                    );
                    if (!targetAccessor) {
                        return;
                    }
                    if (activeDataStoreIndex === BRAND_NEW_DATA_STORE__HAS_NO_INDEX) {
                        removeAnswers(
                            targetAccessor.answers.map(
                                (item: DataStoreHookAnswer): AnswerWithQuestion<DataStoreQuestion, DataStoreAnswer> =>
                                    item.answerWithQuestion
                            )
                        );
                    } else {
                        if (targetStashedAccessor) {
                            updateAnswers(
                                targetStashedAccessor.answers.map(
                                    (item): DataStoreAnswerWithQuestion => item.answerWithQuestion
                                )
                            );
                        }
                    }
                    closeWizard();
                }}
            />
            <DataStoreTable
                onAddDataStoreClick={(): void => {
                    openWizard();
                }}
                onEditDataStoreClick={(selectedRowIndex: number): void => {
                    // Edit the selected DataStore
                    openWizard(selectedRowIndex);
                }}
                payload={dataStoresPayload}
                onDeleteDataStoreClick={(selectedRowIndex: number): void => {
                    // Delete the selected DataStore
                    const newResponses = [
                        ...committedDataStores.slice(0, selectedRowIndex),
                        ...committedDataStores.slice(selectedRowIndex + 1),
                    ];
                    removeAnswers(
                        accessors[selectedRowIndex]?.answers.map(
                            (item): AnswerWithQuestion<DataStoreQuestion, DataStoreAnswer> => item.answerWithQuestion
                        )
                    );
                    setNewDataStoreResponsesCb(newResponses);
                }}
                isReadOnly={isReadOnly}
                atLeastOneDataStoreIsRequired={props.atLeastOneDataStoreIsRequired}
                committedDataStores={committedDataStores}
                questions={questions.dataStores}
                accessors={accessorsStash}
                displayMessage={displayMessage}
            />
            <br />
            <Tabs
                activeTabId={levelOfDetailActiveTab}
                tabs={[
                    {
                        label: `Data stores with personal data (${dataStoresWithPersonalDataCount})`,
                        id: "dataStores",
                        content: null,
                    },
                ]}
                onChange={(event): void => {
                    toggleLevelOfDetailTab(event.detail.activeTabId);
                }}
            />
            {levelOfDetailActiveTab === "dataStores" && createTablesView()}
        </React.Fragment>
    );
};
