import React, { useMemo } from "react";
// Polaris Components
import Select, { SelectProps } from "@amzn/awsui-components-react-v3/polaris/select";
import FormField from "@amzn/awsui-components-react-v3/polaris/form-field";
import SpaceBetween from "@amzn/awsui-components-react-v3/polaris/space-between";
import { FetchedBindlesState } from "src/components/NewApplication/useFetchBindlesOnMount";
import ControlBindleFooter from "src/components/NewApplication/input-fields/ControlBindleField/ControlBindleFooter";

export interface ControlBindleFieldProps {
    bindles: FetchedBindlesState["bindles"];
    value: string;
    onChange: (newValue: string) => void;
}

const ControlBindleField = ({ bindles, value, onChange }: ControlBindleFieldProps) => {
    const options = useMemo((): SelectProps.Option[] => {
        return bindles.map(({ bindle }): SelectProps.Option => ({ label: bindle.name, value: bindle.id }));
    }, [bindles]);

    const selectedOption: SelectProps.Option | null =
        options.find((option): boolean => {
            return option.label === value;
        }) ?? null;

    return (
        <FormField
            label={"Access Control Bindle"}
            description={
                <span>
                    Choose the Bindle that will control access to this Kale application. Go to the Bindles{" "}
                    <a href={"https://bindles.amazon.com/"} rel={"noreferrer noopener"} target={"_blank"}>
                        site
                    </a>{" "}
                    to create a new Bindle.
                </span>
            }
            stretch={false}
        >
            <SpaceBetween direction={"vertical"} size={"xxxs"}>
                <Select
                    id={"controlBindleField"}
                    placeholder="Choose an option"
                    options={options}
                    selectedOption={selectedOption}
                    onChange={(e): void => onChange(e.detail.selectedOption.label ?? "")}
                />
                <ControlBindleFooter selectedOption={selectedOption} />
            </SpaceBetween>
        </FormField>
    );
};

export default React.memo(ControlBindleField);
