import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Box, Grid, Icon } from "@amzn/awsui-components-react-v3";
import { HeaderMenuContext } from "src/components/layout/Layout";
import { useKaleDispatch, useKaleSelector } from "src/redux/hooks";
import { selectIsCommentsViewSidebarOpen } from "src/components/CommentsView/state/selectors";
import { commentViewSidebarToggled } from "src/components/CommentsView/state/actions";

const StyledHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 2000;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 10px;
    padding-left: 10px;
`;

const ToggleContainer = styled.span`
    position: absolute;
    right: 0;
    bottom: 1px;
    margin-right: 60px;
    line-height: 1em;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
`;

interface LabelWrapperProps {
    commentsOpen: boolean;
}

const LabelWrapper = styled.span`
    padding-bottom: ${(props: LabelWrapperProps): string => (props.commentsOpen ? "7px" : "10px")};
    border-bottom: ${(props: LabelWrapperProps): string => (props.commentsOpen ? "3px" : "0px")} solid #eb5f07;
    width: 70px;
    display: block;
    cursor: pointer;
`;

const ToggleLabel = styled.span`
    color: #fff;

    &:hover {
        color: #eb5f07;
    }
`;

interface CommentsToggleProps {
    scale: string;
}

export const Avatar = styled.div`
    height: 60px;
    width: 60px;
    background-color: #d5dbdb;
    border-radius: 50%;
    padding-top: 14px;
    margin: 0 auto;
    transform: scale(${(props: CommentsToggleProps): string => props.scale});
`;

const HeaderTitle = styled.h2`
    font-size: 2.75rem !important;
    line-height: 50px !important;
    font-family: Amazon Ember, Arial, sans-serif !important;
    font-weight: 100;
    margin-top: 0;
    margin-bottom: 0.5rem;
`;

const WelcomeHeader = styled.h3`
    color: #ffffff !important;
    margin-top: 1em !important;
`;

export interface HeaderProps {
    headerTitle: string;
    headerHref: string;
    email: string;
}

const Header = React.memo(({ headerHref, headerTitle, email }: HeaderProps): JSX.Element => {
    const { hasCommentsView } = useContext(HeaderMenuContext);
    const dispatch = useKaleDispatch();
    const isCommentsViewSidebarOpen = useKaleSelector(selectIsCommentsViewSidebarOpen);

    return (
        <StyledHeader id={"header"}>
            <Grid
                id={"header-row"}
                gridDefinition={[{ colspan: { default: 4 } }, { colspan: { default: 4 } }, { colspan: { default: 4 } }]}
            >
                <div id={"header-container"}>
                    <Link to={headerHref}>
                        <HeaderTitle>{headerTitle}</HeaderTitle>
                    </Link>
                </div>
                <Box textAlign="center">
                    <WelcomeHeader>Welcome {email}</WelcomeHeader>
                </Box>
                <div>
                    {hasCommentsView ? (
                        <ToggleContainer
                            onClick={(): void => {
                                dispatch(commentViewSidebarToggled());
                            }}
                        >
                            <Avatar scale={"0.7"}>
                                <Icon name="contact" size="big" variant="normal" />
                            </Avatar>
                            <LabelWrapper commentsOpen={isCommentsViewSidebarOpen}>
                                <ToggleLabel>Comments</ToggleLabel>
                            </LabelWrapper>
                        </ToggleContainer>
                    ) : null}
                </div>
            </Grid>
        </StyledHeader>
    );
});

export default Header;
