import { Box, Button, ColumnLayout, Container, Form, Header, SpaceBetween } from "@amzn/awsui-components-react-v3";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { TEST_IDS } from "shared/table-view";
import MemoizedFieldsTable from "src/components/TableDetails/FieldsTable";
import { OnSaveCb } from "src/components/TableDetails/TableDetailsForm/types";
import { useIsFormValid } from "src/components/TableDetails/TableDetailsForm/hooks/useIsFormValid";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
import TableQuestionsPanel from "src/components/TableDetails/QuestionsPanel/QuestionsPanel";
import ToggleVisibilityPanel, { useToggleVisibilityState } from "src/components/TableDetails/ToggleVisibilityPanel";
import { NewField, TableDetails, TableSources } from "src/services/KaleTablesService";
import { AndesMetadata } from "src/components/schema_table/importers/andes_importer/AndesImporter";
import { ProvideMultiPurposeModal } from "src/components/TableDetails/MultiPurposeModal";
import { useCradleMetadataForAndes } from "src/components/TableDetails/TableDetailsForm/hooks";
import { ComplianceTypeVisibility } from "src/components/TableDetails/TableDetailsPage/types";

const FORM_ERROR_MESSAGE = "Some required questions are missing answers";

const {
    TABLE_DETAILS_FORM: { ROOT, FORM_TITLE, SAVE_BUTTON, CANCEL_BUTTON },
} = TEST_IDS.TABLE_DETAILS_PAGE;

type OnShowSidebarCb = (visible: boolean, complianceTypeVisibility: ComplianceTypeVisibility) => void;

export type onReturnToApplicationCb = () => void;
export interface TableDetailsFormProps {
    title: string;
    tableDetails: TableDetails;
    isFetchingPageResources: boolean;
    onSave: OnSaveCb;
    onShowSidebar: OnShowSidebarCb;
    onReturnToApplication: onReturnToApplicationCb;
    hasUnsavedChanges: boolean;
}

const TableDetailsForm = (props: TableDetailsFormProps): JSX.Element => {
    const {
        title,
        tableDetails,
        isFetchingPageResources,
        onReturnToApplication: onReturnToApplicationCb,
        onSave: onSaveCb,
        onShowSidebar: onShowSidebarCb,
    } = props;
    const { tableQuestions, table } = tableDetails;

    const deps = { onSaveCb };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly;

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [toggleVisibilityState, setToggleVisibilityState] = useToggleVisibilityState(tableQuestions);

    const andesCradleMetadata = useCradleMetadataForAndes(table);

    const [isShowingFormError, setIsShowingFormError] = useState<boolean>(false);
    const isFormValid = useIsFormValid();

    useEffect((): void => {
        if (isFormValid) {
            setIsShowingFormError(false);
        }
    }, [isFormValid]);

    const onSave = useCallback(
        async (newFields?: NewField[]): Promise<void> => {
            const { onSaveCb } = dependenciesRef.current;

            if (isFormValid) {
                setIsSaving(true);
                await onSaveCb(newFields);
                setIsSaving(false);
            } else {
                setIsShowingFormError(true);
            }
        },
        [isFormValid]
    );

    const onShowSidebarRef = useRef(onShowSidebarCb);
    onShowSidebarRef.current = onShowSidebarCb;

    const onShowSidebar = useCallback(
        (visible: boolean): void => onShowSidebarRef.current(visible, toggleVisibilityState),
        [toggleVisibilityState]
    );

    const andesData = (isAndesTable: boolean): JSX.Element | undefined => {
        if (isAndesTable) {
            const { provider, schemaVersion } = tableDetails.table.sourceMetadata as AndesMetadata;
            const hootURL =
                `https://hoot.corp.amazon.com/providers/${provider}/tables/` +
                `${tableDetails.table.name}/versions/${schemaVersion}`;
            return (
                <React.Fragment>
                    <ColumnLayout borders="all" columns={3}>
                        <SpaceBetween size="xxxs">
                            <Box margin={{ bottom: "xxxs" }} color="text-label">
                                Provider:
                            </Box>
                            <Box margin={{ bottom: "s" }}>{provider}</Box>
                        </SpaceBetween>
                        <SpaceBetween size="xxxs">
                            <Box margin={{ bottom: "xxxs" }} color="text-label">
                                Version:
                            </Box>
                            <Box margin={{ bottom: "s" }}>{schemaVersion}</Box>
                        </SpaceBetween>
                        <SpaceBetween size="xxxs">
                            <Box margin={{ bottom: "xxxs" }} color="text-label">
                                View In Hoot:
                            </Box>
                            <Box margin={{ bottom: "s" }}>
                                <a href={hootURL} rel={"noopener noreferrer"} target={"_blank"}>
                                    View In Hoot
                                </a>
                            </Box>
                        </SpaceBetween>
                    </ColumnLayout>
                </React.Fragment>
            );
        }
    };

    const actions = (
        <SpaceBetween direction="horizontal" size="xs">
            <Button data-testid={CANCEL_BUTTON} variant="link" onClick={onReturnToApplicationCb}>
                Return to Application
            </Button>
            <Button
                data-testid={SAVE_BUTTON}
                loading={isSaving}
                variant="primary"
                disabled={isReadonly}
                formAction={"none"}
                onClick={(): Promise<void> => onSave()}
            >
                Save Table
            </Button>
        </SpaceBetween>
    );

    const isAndesTable = tableDetails.table.source === TableSources.Andes;

    return (
        <div data-testid={ROOT} style={{ position: "relative" }}>
            <Form actions={actions} errorText={isShowingFormError && FORM_ERROR_MESSAGE}>
                <Container
                    header={
                        <Header variant="h2" actions={actions}>
                            <span data-testid={FORM_TITLE}>{title}</span>
                        </Header>
                    }
                >
                    {andesData(isAndesTable)}
                    <SpaceBetween size={"xxl"}>
                        <TableQuestionsPanel
                            isAndesTable={isAndesTable}
                            complianceTypeVisibility={toggleVisibilityState}
                            andesCradleMetadataInfo={andesCradleMetadata}
                            controlPanel={
                                <ToggleVisibilityPanel
                                    stateMap={toggleVisibilityState}
                                    setStateMap={setToggleVisibilityState}
                                />
                            }
                        >
                            <ProvideMultiPurposeModal>
                                <MemoizedFieldsTable
                                    isSaving={isSaving}
                                    isFetchingPageResources={isFetchingPageResources}
                                    complianceTypeVisibility={toggleVisibilityState}
                                    tableId={table.id}
                                    onSave={onSave}
                                    onShowSidebar={onShowSidebar}
                                />
                            </ProvideMultiPurposeModal>
                        </TableQuestionsPanel>
                    </SpaceBetween>
                </Container>
            </Form>
        </div>
    );
};

export default React.memo(TableDetailsForm);
