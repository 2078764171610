import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@amzn/awsui-components-react-v3/polaris/box";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import Modal from "@amzn/awsui-components-react-v3/polaris/modal";
import RecallImage from "src/images/RecallImage.svg";
import { useSubscribeToRecallMessage } from "src/websocket/message/Recall";
import { ApplicationStatus, selectLegalApprovalStatus, SurveyResponse } from "src/components/survey/SurveyFormModel";
import { useSyncGSMWithServerDataCb } from "src/components/survey/hooks/useSyncGSMWithServerDataCb";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import ReactDOM from "react-dom";
import KaleContext from "src/components/KaleContext";
import { KaleQuestion } from "src/services/KaleApplicationService";
import Loader from "src/components/fields/Loader";
import { TEST_IDS } from "shared/survey";
import { kaleUrls } from "src/util/Urls";

interface Props {
    questions: KaleQuestion;
    application: SurveyResponse;
    setApplication: (value: SurveyResponse) => void;
    displayMessage: DisplayMessageCb;
}

/**
 * Recall Modal blocks the UI when recalling an application is in progress.
 * This information is passed via websocket messages and so any user viewing
 * this application should have same experience. When user refresh the page
 * while recalling we give user's an option to view the application in
 * read-only mode.
 */
export const RecallModal = (props: Props): JSX.Element => {
    const history = useHistory();
    const kaleContext = useContext(KaleContext);
    const { fetchAppWithLatestTaskId } = kaleContext.service.kaleAppService;
    const {
        questions,
        application: { appInfo },
        setApplication,
        displayMessage,
    } = props;
    const {
        applicationName,
        review: { anvilId },
    } = appInfo;

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRecallInProgress, setIsRecallInProgress] = useState<boolean>(false);
    const [completedProcess, setCompletedProcess] = useState<number>(0);
    const [totalProcess, setTotalProcess] = useState<number>(0);

    const syncGSMWithServerData = useSyncGSMWithServerDataCb();
    const { status } = selectLegalApprovalStatus(appInfo);

    const deps = {
        history,
        questions,
        setApplication,
        syncGSMWithServerData,
        displayMessage,
        fetchAppWithLatestTaskId,
    };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect((): void => {
        setIsRecallInProgress(status === ApplicationStatus.recallInProgress);
    }, [status]);

    useSubscribeToRecallMessage(
        useCallback(
            async (message): Promise<void> => {
                if (message.payload.applicationName !== applicationName) {
                    return;
                }
                const {
                    history,
                    questions,
                    setApplication,
                    syncGSMWithServerData,
                    displayMessage,
                    fetchAppWithLatestTaskId,
                } = depsRef.current;

                let recalledApp: SurveyResponse;
                switch (message.payload.status) {
                    case "started":
                        setIsVisible(true);
                        setTotalProcess(message.payload.stepsCount);
                        break;
                    case "in progress":
                        setTotalProcess(message.payload.stepsCount);
                        setCompletedProcess(message.payload.stepsCompleted);
                        break;
                    case "completed":
                        setIsVisible(false);
                        setIsRecallInProgress(false);
                        if (message.payload.error) {
                            displayMessage(
                                MessageType.error,
                                `${message.payload.requestId}: ${message.payload.error.message}`
                            );
                            break;
                        }
                        try {
                            setIsLoading(true);
                            recalledApp = await fetchAppWithLatestTaskId(applicationName, anvilId);
                            // Replace local state with server response and reload the GSM
                            ReactDOM.unstable_batchedUpdates((): void => {
                                setApplication(recalledApp);
                                syncGSMWithServerData(recalledApp, questions);
                            });
                            // Recalling an application will archive the current revision and create new one.
                            // Navigate to the newest revision URL once the recall is complete.
                            history.push(
                                kaleUrls.editKaleRecordUrl(applicationName, String(recalledApp.appInfo.review.id))
                            );
                            displayMessage(MessageType.success, message.payload.response);
                        } catch (e) {
                            displayMessage(MessageType.error, (e as Error).message);
                        } finally {
                            setIsLoading(false);
                        }
                }
            },
            [applicationName, anvilId]
        )
    );

    const onOpenInReadOnly = useCallback((): void => {
        setIsRecallInProgress(false);
    }, []);

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <Modal
                visible={isRecallInProgress || isVisible}
                size={"medium"}
                header={"Recalling Application"}
                footer={
                    isRecallInProgress && (
                        <Box float={"right"}>
                            {" "}
                            <Button
                                data-testid={TEST_IDS.RECALL_IN_PROGRESS.OPEN_READ_ONLY_BUTTON}
                                variant={"primary"}
                                onClick={onOpenInReadOnly}
                            >
                                Open In Read Only
                            </Button>
                        </Box>
                    )
                }
            >
                <Box textAlign="center" color="inherit">
                    {isRecallInProgress && (
                        <Box variant={"p"}>
                            <Box variant="strong">{applicationName}</Box> is locked for application recall by &quot;
                            <Box variant="strong">another user</Box>&quot;. Please wait until the user is finished or
                            open the application in &quot;Read Only&quot;
                        </Box>
                    )}
                    <img id={"recall-app"} src={RecallImage} height={150} alt="Recalling Application" />
                    <Box data-testid={TEST_IDS.RECALL_IN_PROGRESS.MESSAGE} variant={"p"}>
                        {/* eslint-disable-next-line max-len */}
                        <Box variant="strong">{completedProcess}</Box> of <Box variant="strong">{totalProcess}</Box>{" "}
                        processes complete
                    </Box>
                    <Box variant={"p"}>The recall process may take 1-2 minutes, so please be patient.</Box>
                </Box>
            </Modal>
        </React.Fragment>
    );
};
