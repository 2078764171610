import React, { Dispatch, SetStateAction, useCallback, useContext, useState } from "react";
import { Box } from "@amzn/awsui-components-react-v3";
import styled from "styled-components";
import { ConfirmActionModal } from "src/components/ConfirmActions";
import { FileUpload } from "src/components/FileUpload/FileUpload";
import KaleContext from "src/components/KaleContext";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { useMessageBannerActions } from "src/components/MessageBannerActions";
import { MessageType } from "src/components/survey/KaleRoutes";
import { TEST_IDS } from "shared/survey";
import { useAppParamsFromRoute } from "src/components/survey/hooks/useAppParamsFromRoute";
import { kaleUrls } from "src/util/Urls";

const { MODAL_CANCEL_BUTTON, MODAL_CONFIRM_BUTTON, MODAL_ROOT } =
    TEST_IDS.DATA_STORE_INFO.SCHEMA_TABLE.ACTIONS.CSV.IMPORT_TABLES;

const StyledModalButton = styled.span`
    padding-right: 10px;
`;

interface Props {
    currentDS: DataStoreResponse;
    appName: string;
    isModalVisible: boolean;
    setShouldShow: Dispatch<SetStateAction<boolean>>;
}

const ImportTablesCSV = ({ currentDS, appName, isModalVisible, setShouldShow }: Props): JSX.Element => {
    const { uploadTablesCSV } = useContext(KaleContext).service.kaleTablesService;
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isDoneUploading, setIsDoneUploading] = useState<boolean>(false);
    const [file, setFile] = useState<File>();
    const [errMsg, setErrMsg] = useState<string>("");
    const { displayMessage } = useMessageBannerActions();

    const { reviewId } = useAppParamsFromRoute();

    const onImportClick = useCallback((): void => {
        if (file !== undefined) {
            setIsUploading(true);
            setErrMsg("");
            uploadTablesCSV(file, appName, currentDS)
                .then((): void => {
                    const flashBarContent: React.ReactNode = (
                        <div>
                            <Box variant="h3">Table import initiated</Box>Once your import is complete, you will receive
                            an email notification.{" "}
                            <a
                                href={kaleUrls.csvJobsStatusUrl(encodeURIComponent(appName), `${reviewId}`)}
                                rel={"noreferrer noopener"}
                                target={"_blank"}
                                style={{ color: "goldenrod", textDecoration: "underline" }}
                            >
                                {"View current status."}
                            </a>
                        </div>
                    );
                    displayMessage(MessageType.info, flashBarContent);
                })
                .catch((err: Error): void => {
                    setErrMsg(err.message);
                })
                .finally((): void => {
                    setIsDoneUploading(true);
                    setIsUploading(false);
                });
        }
    }, [file, uploadTablesCSV, appName, reviewId, currentDS, displayMessage]);

    const onCloseModal = (): void => {
        setIsUploading(false);
        setFile(undefined);
        setErrMsg("");
        setIsDoneUploading(false);
        setShouldShow(false);
    };

    const renderBodyCB = useCallback((): JSX.Element => {
        return (
            <FileUpload
                onSelectFile={(file): void => {
                    setIsUploading(false);
                    setIsDoneUploading(false);
                    setErrMsg("");
                    setFile(file);
                }}
                fileName={file?.name ?? ""}
                fileType={"text/csv"}
                label={"CSV file"}
                description={`Select a file that contains edited and/or new tables and fields for "${currentDS.name}".`}
                isDoneUploading={isDoneUploading}
                errMsg={errMsg}
                wikiLink={"https://w.amazon.com/bin/view/Kale/TableCSV/"}
            />
        );
    }, [currentDS.name, errMsg, file, isDoneUploading]);

    return (
        <StyledModalButton>
            <ConfirmActionModal
                testIds={{
                    cancelButton: MODAL_CANCEL_BUTTON,
                    confirmButton: MODAL_CONFIRM_BUTTON,
                    modalRoot: MODAL_ROOT,
                }}
                confirmButtonOverrides={{
                    label: "Import",
                }}
                header={`Import Tables for "${currentDS.name}"`}
                onConfirm={onImportClick}
                onCancel={onCloseModal}
                loading={isUploading}
                visible={isModalVisible}
            >
                {renderBodyCB()}
            </ConfirmActionModal>
        </StyledModalButton>
    );
};

export { ImportTablesCSV };
