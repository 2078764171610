import { TokenGetter } from "src/services/CognitoService";
import { KaleWebSocketConfig } from "src/Config";
import { Mutex } from "async-mutex";

// Ref https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent#status_codes
const CLOSE_NORMAL = 1000;
const MAX_RETRY_COUNT = 10;
const PING_MSG = {
    action: "ping",
    payload: "",
};

export type WebSocketHandler = (event: MessageEvent) => Promise<void>;

export class WebSocketService {
    public get counter(): number {
        return this._counter;
    }
    public webSocketConnection: WebSocket | null;

    private readonly accessTokenGetter: TokenGetter;
    private readonly config: KaleWebSocketConfig;
    private pingTimer: number;
    private _counter: number;
    private mutex: Mutex;

    public constructor(config: KaleWebSocketConfig, accessTokenGetter: TokenGetter) {
        this.accessTokenGetter = accessTokenGetter;
        this.config = config;
        this.webSocketConnection = null;
        this.pingTimer = 0;
        this._counter = 0;
        this.mutex = new Mutex();

        this.connect = this.connect.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.ping = this.ping.bind(this);
        this.delay = this.delay.bind(this);
    }

    /**
     * This function connects to websocket for a given channel and segment
     * Web Socket API Gateways idle connection timeout is 10 minutes
     * On successful connection, client pings server every 1 minute to keep the connections alive
     * On error or closed connection client retries connection for a max of 10 times with exponential back off strategy
     * @param channel - Which channel web sockets should connect to (application, user, admin)
     * @param segment - Represents the segment within the channel (application name for application channel)
     * @param handler - Callback handler for all incoming messages
     */
    public connect = async (channel: string, segment: string, handler: WebSocketHandler): Promise<void> => {
        this._counter++;
        if (this._counter > MAX_RETRY_COUNT) {
            this.resetMutex();
            return Promise.reject(new Error("max retry count reached"));
        }
        const token = await this.accessTokenGetter();

        const ws = new WebSocket(
            `${this.config.webSocketEndpoint}?channel=${channel}&segment=${segment}&Authorization=${token}`
        );
        // Close any previous connections
        this.disconnect();

        const tryReconnect = (): Promise<void> => this.reconnect(channel, segment, handler);

        ws.onmessage = handler;
        ws.onclose = tryReconnect;
        ws.onerror = tryReconnect;
        ws.onopen = (): void => {
            this._counter = 0;
            this.webSocketConnection = ws;
            this.resetMutex();

            // Ping the server every 1 minute to keep the connection alive
            // eslint-disable-next-line
            this.pingTimer = setInterval((): void => {
                this.ping();
            }, 60000);
        };

        return Promise.resolve();
    };

    /**
     * This function closes any existing web socket connection
     */
    public disconnect = (): void => {
        if (this.webSocketConnection) {
            this.webSocketConnection.onopen = null;
            this.webSocketConnection.onclose = null;
            this.webSocketConnection.onerror = null;
            this.webSocketConnection.onmessage = null;
            this.webSocketConnection.close(CLOSE_NORMAL);
            this.webSocketConnection = null;
            if (this.pingTimer !== 0) {
                clearTimeout(this.pingTimer);
            }
        }
    };

    /**
     * This functions needs to run mutually exclusive from different websocket "close" or "error" callback
     */
    private reconnect = async (channel: string, segment: string, handler: WebSocketHandler): Promise<void> => {
        await this.mutex.runExclusive(async (): Promise<void> => {
            await this.delay(this._counter);
            // eslint-disable-next-line
            return this.connect(channel, segment, handler);
        });
    };

    /**
     * Send empty payload to server to keep web socket connection alive
     */
    private readonly ping = async (): Promise<void> => {
        this.webSocketConnection?.send(JSON.stringify(PING_MSG));
    };

    /**
     * Exponential backoff times are as follows [10ms, 100ms, 1000ms, 5000ms, 5000ms...]
     */
    private readonly delay = async (retryCount: number): Promise<void> => {
        // eslint-disable-next-line
        return new Promise((resolve): number => setTimeout(resolve, Math.min(5000, 10 ** retryCount)));
    };

    private resetMutex = (): void => {
        this.mutex.cancel();
        this.mutex = new Mutex();
    };
}
