export const TEST_IDS = {
    WIZARD: {
        ROOT: "personal-data-wizard",
        NEXT_BUTTON: "inline-wizard-next-button",
        CONFIRM_AND_SUBMIT_BUTTON: "inline-wizard-submit-button",
        LINEAGE_STEP: {
            LINEAGE_TABLE: "pdw--lineage-table",
        },
        PDC_STEP: {
            PDC_TABLE: {
                ROOT: "pwd--pdc-table-root",
                ENTITY_TYPE: {
                    ROOT: "pwd--pdc-table--entity-type-root",
                    LABEL: "pwd--pdc-table--entity-type--label",
                },
            },
        },
        PERSONAL_DATA_STEP: {
            CONTENT_ROOT: "pdw--personal-data-step--content-root",
            STATEMENT_FORM_FIELD: "pdw--personal-data-step--statement-form-field",
        },
        SUMMARY_STEP: {
            RESULT: "pdw-summary-result",
            PERSONAL_DATA_QUESTION_EDIT_BUTTON: "pdw-summary-pdq-edit-button",
        },
    },
};
