import { createStore } from "redux";
import rootReducer from "./root-reducer";
import { composeWithDevTools } from "redux-devtools-extension";

// Create the Kale redux store
const composedEnhancer = composeWithDevTools();
const store = createStore(rootReducer, composedEnhancer);

export type KaleRootState = ReturnType<typeof store.getState>;
export type KaleDispatch = typeof store.dispatch;

export default store;
