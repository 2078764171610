import { Container, Form, Header } from "@amzn/awsui-components-react-v3";
import { KaleHTMLParser } from "src/components/fields/textbox/TextBoxHelper";
import React from "react";
import { Table } from "@amzn/awsui-components-react-v3/polaris";
import styled from "styled-components";
import { TEST_IDS } from "shared/survey";

const { ROOT, HEADER, CONTENT } =
    TEST_IDS.DATA_STORE_INFO.DATA_STORE_WIZARD.DYNAMIC_QUESTION_STEP.CHILD_DATA_GATING_QUESTION_GROUP;

interface Props {
    groupTitle: string;
    groupDescription: string;
    children: React.ReactNode;
}

const StyledP = styled.p`
    margin-bottom: 1rem !important;
`;

const StyledDiv = styled.div`
    width: 80rem;
`;

const StyledOL = styled.ol`
    li {
        color: black !important;
    }
`;

export const containsPersonalDataShortId = "contains_personal_data";
export const hasChildDataShortId = "has_child_data";
export const hasTeenDataShortId = "has_teen_data";
export const childDataIsProfileAwareShortId = "child_data_is_profile_aware";
export const teenDataUsageShortId = "teen_data_usage";
export const childDataIsSharedShortId = "child_data_is_shared";
export const childDataAccessControlMethodsShortId = "child_data_access_control_methods";
export const childOrTeenGroupId = "child_or_teen";

const ChildDataGatingQuestionGroup = (props: Props): JSX.Element => {
    const { groupTitle, groupDescription, children } = props;

    return (
        <Container
            key={"has_child_data_instruction"}
            data-testid={ROOT}
            header={
                <Header
                    description={
                        "Child or teen data is a sub-set of personal information and includes any data that can be " +
                        "directly or indirectly linked to a child or teen as defined below."
                    }
                >
                    {"What is child or teen data?"}
                </Header>
            }
        >
            <Form
                header={
                    <Header variant={"h3"}>
                        The data store <u>contains child or teen data</u> if <u>any one</u> of the following conditions
                        are met:
                    </Header>
                }
            />
            <StyledOL>
                <li>
                    <StyledP>
                        The data store contains data for customers who are within <u>any one</u> of the{" "}
                        <b>age limits</b>, based on country/countries of operation, noted below:
                    </StyledP>
                    <StyledDiv>
                        <Table
                            columnDefinitions={[
                                {
                                    id: "age",
                                    header: "Child Age Limit",
                                    cell: (item): string => item.age || "-",
                                },
                                {
                                    id: "teenAge",
                                    header: "Teen Age Range",
                                    cell: (item): string => item.teenAge || "-",
                                },
                                {
                                    id: "country",
                                    header: "Country of Operation",
                                    cell: (item): string => item.country || "-",
                                },
                            ]}
                            items={[
                                {
                                    age: "Under 13 years",
                                    teenAge: "13 years and above, but under 18 years",
                                    country: "US, UK, Rest of World (ROW)",
                                },
                                {
                                    age: "Under 14 years",
                                    teenAge: "14 years and above, but under 18 years",
                                    country: "Italy, Spain, China",
                                },
                                {
                                    age: "Under 15 years",
                                    teenAge: "15 years and above, but under 18 years",
                                    country: "Australia, France",
                                },
                                {
                                    age: "Under 16 years",
                                    teenAge: "16 years and above, but under 18 years",
                                    country: "Brazil, Germany, Luxembourg, Netherlands, Poland",
                                },
                                {
                                    age: "Under 18 years",
                                    teenAge: "-NA-",
                                    country: "Turkey, India, Egypt",
                                },
                            ]}
                        />
                    </StyledDiv>
                </li>
                <br />
                <li>
                    If the data store supports or receives data from either (a) a child-directed or teen-directed (b) a
                    profile-aware service that supports child or teen profiles.
                    <ul>
                        <li>
                            Examples of <b>child-directed services</b> are: Amazon Kids and Echo Glow.
                        </li>
                        <li>
                            Examples of a <b>teen-directed service</b> is Amazon Teens (TeenService).
                        </li>
                        <li>
                            Examples of <b>profile-aware services that support child profiles</b> are: Prime Video, Fire
                            TV, and Alexa.
                        </li>
                    </ul>
                </li>
            </StyledOL>
            <br />
            <hr />
            <Form
                header={
                    <Header variant={"h3"} data-testid={HEADER} description={KaleHTMLParser(groupDescription)}>
                        <b data-testid={CONTENT}>{KaleHTMLParser(groupTitle)}</b>
                    </Header>
                }
            />
            {children}
        </Container>
    );
};

export default ChildDataGatingQuestionGroup;
