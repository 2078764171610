import { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
    DataStoresPayload,
    mergeAnswers,
    Question,
    QuestionTag,
    ReviewPayload,
    useDataStoresPayloads,
    useReviewPayload,
} from "src/answers_legacy";
import KaleContext, { KaleContextProps } from "src/components/KaleContext";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import {
    ApplicationStatus,
    selectLegalApprovalStatus,
    setLegalApprovalInfo,
    SurveyResponse,
} from "src/components/survey/SurveyFormModel";
import { KaleQuestion } from "src/services/KaleApplicationService";
import ReactDOM from "react-dom";
import { useSyncGSMWithServerDataCb } from "src/components/survey/hooks/useSyncGSMWithServerDataCb";

export interface SaveOptions {
    status: ApplicationStatus | null;
}

export interface SaveResponse {
    savedApp: SurveyResponse;
    hasCompleteTaskFailed?: boolean;
}

// Save application does not send any notification and does not redirect to home page
export type SaveApplication = (
    application: SurveyResponse,
    status: ApplicationStatus | null
) => Promise<SurveyResponse>;

export type SubmitApplication = (application: SurveyResponse) => Promise<SurveyResponse>;

export type Recall = () => Promise<void>;

export type Reject = (application: SurveyResponse) => Promise<void>;

export type Approve = (appName: string) => Promise<void>;

export type DeleteApplication = (appName: string) => Promise<void>;

export const findDataStoreAnswersFromCorrespondingPayload = (
    dataStore: DataStoreResponse,
    dataStoresPayload: DataStoresPayload[]
): DataStoresPayload | undefined => {
    return dataStoresPayload?.find((dataStorePayload): boolean => {
        const firstAnswerSavedDataStoreId = dataStorePayload?.[0]?.dataStoreId;
        const firstAnswerUnsavedUuid = dataStorePayload?.[0]?.userDataStoreId;
        return (
            (Boolean(dataStore.id) && firstAnswerSavedDataStoreId === dataStore.id) ||
            (Boolean(dataStore.unsavedUUID) && firstAnswerUnsavedUuid === dataStore.unsavedUUID)
        );
    });
};

export const requestSyncPayloadAnswers = async (
    kaleContext: KaleContextProps,
    application: SurveyResponse,
    reviewPayload: ReviewPayload,
    dataStoresPayload: DataStoresPayload[],
    originalDataStores: DataStoreResponse[],
    questions: KaleQuestion
): Promise<SurveyResponse> => {
    const tafQuestions = await kaleContext.service.kaleAppService.fetchQuestions(QuestionTag.tafView);
    return {
        ...application,
        appInfo: {
            ...application.appInfo,
            review: {
                ...application.appInfo.review,
                reviewAnswers: reviewPayload ?? [],
                dataStores: [
                    ...application.appInfo.review.dataStores.map((dataStore): DataStoreResponse => {
                        // eslint-disable-next-line max-len
                        const dataStoreAnswersFromCorrespondingPayload = findDataStoreAnswersFromCorrespondingPayload(
                            dataStore,
                            dataStoresPayload
                        );

                        // Use original response state to persist TAFS survey answers
                        const originalDataStoreAnswers = originalDataStores.find(
                            (originalDataStore): boolean => originalDataStore.id === dataStore.id
                        );

                        const dataStoreAnswers = mergeAnswers(
                            originalDataStoreAnswers?.dataStoreAnswers ?? [],
                            dataStoreAnswersFromCorrespondingPayload ?? [],
                            tafQuestions.dataStores as Question[],
                            questions.dataStores as Question[]
                        );

                        return {
                            ...dataStore,
                            dataStoreAnswers,
                        };
                    }),
                ],
            },
        },
    };
};

interface ApplicationServiceCallbacks {
    submit: SubmitApplication;
    save: SaveApplication;
    recall: Recall;
    reject: Reject;
    approve: Approve;
    deleteApp: DeleteApplication;
}
export const useApplicationServiceCallbacks = (
    displayMessage: DisplayMessageCb,
    application: SurveyResponse,
    setApplication: (value: SurveyResponse) => void,
    questions: KaleQuestion,
    originalDataStores: DataStoreResponse[],
    setIsSavingApplication: (isSavingApplication: boolean) => void
): ApplicationServiceCallbacks => {
    const kaleContext = useContext(KaleContext);
    const { update, approveLegal } = kaleContext.service.kaleAppService;
    const history = useHistory();
    const syncGSMWithServerData = useSyncGSMWithServerDataCb();
    const dataStoresPayload = useDataStoresPayloads();
    const reviewPayload = useReviewPayload();

    const save = async (application: SurveyResponse, status: ApplicationStatus | null): Promise<SurveyResponse> => {
        try {
            // Call the Save API
            const { savedApp } = await saveApp(application, {
                status,
            });

            // Replace local state with server response and reload the GSM
            ReactDOM.unstable_batchedUpdates((): void => {
                setApplication(savedApp);
                syncGSMWithServerData(savedApp, questions);
            });

            return Promise.resolve(savedApp);
        } catch (error) {
            return Promise.reject(new Error((error as Error).message));
        }
    };

    const submit = async (application: SurveyResponse): Promise<SurveyResponse> => {
        const { submit } = kaleContext.service.kaleAppService;

        try {
            const resp = await saveApp(application, {
                status: ApplicationStatus.inProgress,
            });
            setIsSavingApplication(true);
            setApplication(resp.savedApp);
            await submit(application.appInfo.applicationName);

            return resp.savedApp;
        } catch (error) {
            const errMsg = `Failed to Submit App: ${(error as Error).message}`;
            displayMessage(MessageType.error, errMsg);
            return Promise.reject(new Error(errMsg));
        } finally {
            setIsSavingApplication(false);
        }
    };

    const approve = async (appName: string): Promise<void> => {
        return await approveLegal(appName)
            .then((): void => {
                displayMessage(MessageType.success, "Legal application has been approved.");
                history.push("/");
            })
            .catch((error: Error): void => {
                displayMessage(MessageType.error, error.message);
            });
    };

    const saveApp = async (application: SurveyResponse, options: SaveOptions): Promise<SaveResponse> => {
        const { status = null } = options;
        setIsSavingApplication(true);
        let response = await requestSyncPayloadAnswers(
            kaleContext,
            { ...application },
            reviewPayload,
            dataStoresPayload,
            originalDataStores,
            questions
        );
        if (status) {
            response = { ...response, appInfo: setLegalApprovalInfo(response.appInfo, status, undefined, undefined) };
        }

        try {
            const initialSavedApp = await update(response);
            return Promise.resolve({ savedApp: initialSavedApp });
        } catch (error) {
            const errMsg = `FAILED TO SAVE APPLICATION INFORMATION. Please try again. Failed reason: ${
                (error as Error).message
            }`;
            displayMessage(MessageType.error, errMsg);
            return Promise.reject(new Error(errMsg));
        } finally {
            setIsSavingApplication(false);
        }
    };

    const recall = async (): Promise<void> => {
        const { recallInReviewApp, recallApprovedApp } = kaleContext.service.kaleAppService;
        const {
            applicationName,
            review: { anvilId },
        } = application.appInfo;
        const legalApproval = selectLegalApprovalStatus(application.appInfo);

        setIsSavingApplication(true);
        if (legalApproval.status === ApplicationStatus.approved) {
            return recallApprovedApp(applicationName)
                .catch((error: Error): void => {
                    displayMessage(MessageType.error, error.message);
                })
                .finally((): void => {
                    setIsSavingApplication(false);
                });
        }

        return recallInReviewApp(applicationName, anvilId)
            .then((savedApp): void => {
                setApplication(savedApp);
                displayMessage(MessageType.success, "Application has been successfully recalled.");
            })
            .catch((error: Error): void => {
                displayMessage(MessageType.error, error.message);
            })
            .finally((): void => {
                setIsSavingApplication(false);
            });
    };

    const reject = async (application: SurveyResponse): Promise<void> => {
        setIsSavingApplication(true);
        return kaleContext.service.kaleAppService
            .reject(application.appInfo.applicationName)
            .then((): void => {
                displayMessage(
                    MessageType.success,
                    "Application is now back 'In Progress' and the application owner has been notified."
                );
                history.push("/");
            })
            .catch((error: Error): void => {
                displayMessage(MessageType.error, error.message);
                history.push("/");
            });
    };

    const deleteApp = async (appName: string): Promise<void> => {
        return kaleContext.service.kaleAppService
            .deleteApp(appName)
            .then((): void => {
                displayMessage(MessageType.success, "Application was deleted successfully.");
                history.push("/");
            })
            .catch((error: Error): void => {
                displayMessage(MessageType.error, error.message);
            });
    };

    return { save, submit, recall, reject, approve, deleteApp };
};
