import React, { useContext } from "react";
import { SelectProps } from "@amzn/awsui-components-react-v3/polaris";
import styled from "styled-components";
import { Popover } from "@amzn/awsui-components-react-v3";
import KaleContext from "src/components/KaleContext";

const NoBindlesPopover = styled(Popover)`
    display: block;
    margin-top: 10px;
`;

interface ControlBindleFooterProps {
    selectedOption: SelectProps["selectedOption"];
}
const ControlBindleFooter = (props: ControlBindleFooterProps): JSX.Element => {
    const { bindleEndpoint } = useContext(KaleContext).config;
    const { selectedOption } = props;
    const controlBindle = selectedOption?.label;
    const controlBindleLink = `${bindleEndpoint}/software_app/${controlBindle}`;

    return (
        <React.Fragment>
            {controlBindle ? (
                <a rel="noopener noreferrer" target="_blank" href={controlBindleLink}>
                    {`Navigate to ${controlBindle} in Bindles`}
                </a>
            ) : (
                <NoBindlesPopover
                    size="large"
                    position="top"
                    triggerType="text"
                    dismissButton={false}
                    content={
                        <div>
                            <p>If no bindles are present, ensure all of the following are correct.</p>
                            <ul>
                                <li>
                                    You have at least one bindle by visiting{" "}
                                    <a rel="noopener noreferrer" target="_blank" href="https://bindles.amazon.com/">
                                        bindles.amazon.com
                                    </a>
                                    , or
                                </li>
                                <li>You are a member of a team that owns a bindle, and</li>
                                <li>You are using a supported browser (FireFox and Chrome), and</li>
                                <li>Your browser is not blocking third-party cookies and site data.</li>
                            </ul>
                        </div>
                    }
                >
                    No bindles present?
                </NoBindlesPopover>
            )}
        </React.Fragment>
    );
};

export default React.memo(ControlBindleFooter);
