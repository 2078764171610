import TextBox from "src/components/fields/textbox/TextBox";
import Model from "src/components/constants/Constants";
import React from "react";
import { AppInfoResponse } from "src/components/survey/SurveyFormModel";
import { OnChangeCb } from "src/components/survey/ApplicationInfo/ApplicationInfo";

interface LegalPoCProps {
    shouldShowErrors: boolean;
    skipValidation: boolean;
    response: AppInfoResponse;
    onChangeCallback: OnChangeCb;
    onClearDataStore: () => void;
    pdwSkipToReview: boolean;
    pdwSubmitted: boolean;
}

export const LegalPoC = (props: LegalPoCProps): JSX.Element => {
    const onChange = (event: any): void => {
        props.onChangeCallback({
            ...props.response,
            review: {
                ...props.response.review,
                legalPocAlias: event.response,
            },
        });
    };

    return (
        <TextBox
            id={"legalPocAlias"}
            placeholder={Model.getData("legalPocAlias").placeholder}
            isRequired={!props.skipValidation && props.shouldShowErrors}
            expectedAnswer={props.response.review.legalPocAlias}
            onChangeCallback={onChange}
        />
    );
};
