import { attrSelector, compoundSelector, tidSelector } from "../util/selectors";
import { TEST_IDS } from "./test-ids";

const {
    KALE_CHECKBOX_GROUP,
    KALE_CHECKBOX: { ROOT: KALE_CHECKBOX_ROOT, POPOVER, INLINE_CHECKBOX },
    DROPDOWN_WITH_TAGS: { FORMFIELD: DROPDOWN_WITH_TAGS_FORMFIELD, BUTTON: DROPDOWN_WITH_TAGS_BUTTON },
    CONTACT_LIST,
    ANVIL_ID: { LINKED_TESTID, LOADING_TESTID, VALID_TESTID, INPUT_TESTID },
    BINDLE: { CONTROL_LINK, CONTROL_NO_POPOVER, CONTROL_REFRESH_BUTTON, RELATED_NO_POPOVER, RELATED_REFRESH_BUTTON },
    APP_NAME: { COPY_CLIPBOARD_BUTTON, STATIC_TEXT, TEXT_INPUT },
    AUDIT_HISTORY_LINK,
} = TEST_IDS;

export const kaleCheckboxByLabelSelector = (checkboxLabel: string): string => {
    return compoundSelector(tidSelector(KALE_CHECKBOX_ROOT), attrSelector("data-checkbox-label", checkboxLabel));
};

export const SELECTORS = {
    KALE_CHECKBOX_GROUP: tidSelector(KALE_CHECKBOX_GROUP),
    KALE_CHECKBOX: {
        ROOT: tidSelector(KALE_CHECKBOX_ROOT),
        POPOVER: tidSelector(POPOVER),
        INLINE_CHECKBOX: tidSelector(INLINE_CHECKBOX),
        kaleCheckboxRootByLabel: (checkboxLabel: string): string =>
            compoundSelector(tidSelector(KALE_CHECKBOX_ROOT), attrSelector("data-checkbox-label", checkboxLabel)),
        DROPDOWN_WITH_TAGS: {
            FORMFIELD: tidSelector(DROPDOWN_WITH_TAGS_FORMFIELD),
            BUTTON: tidSelector(DROPDOWN_WITH_TAGS_BUTTON),
        },
    },
    CONTACT_LIST: tidSelector(CONTACT_LIST),
    ANVIL_ID: {
        LINKED_TESTID: tidSelector(LINKED_TESTID),
        LOADING_TESTID: tidSelector(LOADING_TESTID),
        VALID_TESTID: tidSelector(VALID_TESTID),
        INPUT_TESTID: tidSelector(INPUT_TESTID),
    },
    BINDLE: {
        CONTROL_LINK: tidSelector(CONTROL_LINK),
        CONTROL_NO_POPOVER: tidSelector(CONTROL_NO_POPOVER),
        CONTROL_REFRESH_BUTTON: tidSelector(CONTROL_REFRESH_BUTTON),
        RELATED_NO_POPOVER: tidSelector(RELATED_NO_POPOVER),
        RELATED_REFRESH_BUTTON: tidSelector(RELATED_REFRESH_BUTTON),
    },
    APP_NAME: {
        COPY_CLIPBOARD_BUTTON: tidSelector(COPY_CLIPBOARD_BUTTON),
        STATIC_TEXT: tidSelector(STATIC_TEXT),
        TEXT_INPUT: tidSelector(TEXT_INPUT),
    },
    AUDIT_HISTORY_LINK: tidSelector(AUDIT_HISTORY_LINK),
};
