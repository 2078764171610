import {
    PropertyFilterProps,
    PropertyFilterProps as PolarisPropertyFilterProps,
    TableProps,
} from "@amzn/awsui-components-react-v3";
import { PropertyFilterQuery } from "@amzn/awsui-collection-hooks/dist/cjs/interfaces";
import React from "react";

export enum DASTableFilterType {
    textFilter = "textFilter",
    propertyFilter = "propertyFilter",
}
interface BaseDASTableFilterProps {
    type: DASTableFilterType;
    placeholder?: string;
}

export interface GetFilteringPropertiesOptions<T> {
    columnDefinitions: DASTableProps<T>["columnDefinitions"];
    rowItems: DASTableProps<T>["rowItems"];
    filterKeys: DASTablePropertyFilterProps<T>["filterKeys"];
}
export type GetFilteringPropertiesResult = PropertyFilterProps.FilteringProperty[];

type GetFilteringPropertiesCb<T> = (options: GetFilteringPropertiesOptions<T>) => GetFilteringPropertiesResult;

/**
 * This allows us to override default DASTable property filtering behavior based on the unique needs of the Table
 * View FieldsTable. Its tightly coupled to implementation details of the DASTable property filtering
 * configuration. If you need to use this to override property filtering behavior for a new Table, first consider
 * cleaning up the prop interface and fixing the abstraction rather than piling on additional tech debt.
 * Refer to the implementation of our useDASTableCollection hook to infer proper use of this type
 */
interface DASTablePropertyFilterOverrides<T> {
    filteringOptions: PolarisPropertyFilterProps.FilteringOption[];
    getFilteringProperties: GetFilteringPropertiesCb<T>;
    filteringFunction: (item: T, query: PropertyFilterQuery) => boolean;
}

/**
 * Use these property filter props to specify a search bar that examines specific properties on the Table items
 */
export interface DASTablePropertyFilterProps<T> extends BaseDASTableFilterProps {
    type: DASTableFilterType.propertyFilter;
    /**
     * These are the columnIds that the property filter should apply its search to. Passing an empty array here means
     * no columns will be searched and the property filter will always return 0 matches.
     */
    filterKeys: string[];
    /**
     * internalOverrides is a hacky and incomplete prop abstraction. It's being used as a quick back door for the Table
     * View's FieldsTable to onboard to using the DASTable component for its table rendering with Polaris, specifically
     * as a mechanism to be able to wrap up the Polaris 3 migration work. Its likely a non-trivial piece of tech debt.
     *
     * This is an optional configuration override, but it is not recommended.
     */
    internalOverrides?: DASTablePropertyFilterOverrides<T>;
}

/**
 * Use these text filter props to specify a search bar that examines every key in the Table items
 */
export interface DASTableTextFilterProps extends BaseDASTableFilterProps {
    type: DASTableFilterType.textFilter;
}

export type DASTableFilterProps<T> = DASTableTextFilterProps | DASTablePropertyFilterProps<T>;

export type DASTableProps<T> = Pick<
    TableProps,
    "onSelectionChange" | "onColumnWidthsChange" | "trackBy" | "stripedRows"
> & {
    "data-testid"?: string;
    cssString?: string;
    columnDefinitions: TableProps.ColumnDefinition<T>[];
    empty?: JSX.Element;
    emptyTableMessage?: JSX.Element;
    emptyTemplateElement?: React.ReactNode;
    emptyTextPrefix?: string;
    /**
     * Adds a filter search bar to the table header when these are provided
     */
    filterProps?: DASTableFilterProps<T>;
    headerReactNode?: React.ReactNode;
    hideContentSelector?: boolean;
    hidePreferences?: boolean;
    id: string;
    initialSortedColumn?: string;
    isLoading: boolean;
    isResizable?: boolean;
    isStickyHeader?: boolean;
    renderActionStripe?: (id: string, hasDelete?: boolean) => JSX.Element;
    rowItems: T[];
    selectionType?: TableProps.SelectionType;
    sortableColumns?: TableProps.SortingColumn<T>[] | null;
    sortingDescending?: boolean;
    tableDescription?: JSX.Element;
    tableName: string;
};
