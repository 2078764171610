/* eslint-disable max-len */
import React from "react";
import { PollingErrorCode } from "src/components/TableDetails/TableDetailsForm/poll-for-cradle-meta-data";
import Error, { ErrorText } from "src/util/Error";
import { Icon } from "@amzn/awsui-components-react-v3";
import { AndesPolicyTypes } from "src/components/TableDetails/TableDetailsForm/hooks/useCradleMetadataForAndes/constants";
/* eslint-enable max-len */

export const CRADLE_ERROR_MESSAGE_PREFIX = "Cradle account and profile creation failed unexpectedly.";

const makeCradleCreationFailedMessage = (policyType: string, href: string): React.ReactNode => (
    <span>
        <Icon name="status-warning" size="normal" variant="warning" />{" "}
        <ErrorText>
            {policyType} {CRADLE_ERROR_MESSAGE_PREFIX} Use{" "}
        </ErrorText>{" "}
        <a href={href} rel={"noreferrer noopener"} target={"_blank"}>
            this link
        </a>{" "}
        <ErrorText>to cut us a ticket</ErrorText>
    </span>
);

// URLs to BDT team SIM templates for DSAR and OD3 creation issues
export const DSAR_TICKET_URL = "https://sim.amazon.com/issues/create?template=e665da41-2de8-4fda-9f1b-4d4404d5c836";
export const OD3_TICKET_URL = "https://sim.amazon.com/issues/create?template=be0e316d-eaab-46d2-b858-9d1ff5fa8ee2";

export const decorateErrorMessage = (e: any): React.ReactNode => {
    const message = e?.message ?? (typeof e === "string" ? e : "Error");
    switch (message) {
        case PollingErrorCode.DSAR_CREATION_FAILED: {
            return makeCradleCreationFailedMessage(AndesPolicyTypes.DSAR, DSAR_TICKET_URL);
        }
        case PollingErrorCode.OD3_CREATION_FAILED: {
            return makeCradleCreationFailedMessage(AndesPolicyTypes.OD3, OD3_TICKET_URL);
        }
        default: {
            return <Error text={message} />;
        }
    }
};
